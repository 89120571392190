import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Layout from "../../../Components/layouts";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import aiImg from '../../../Assets/images/ai.png';
import projectsImg from '../../../Assets/images/projects.png';
import Typography from '@material-ui/core/Typography';
import { Button, LinearProgress } from '@material-ui/core';
import { AssesmentsSummary } from '../../../utils/constants'
import axios from 'axios';
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import '../../../Sys/config.js';

let skillsAndTransformation = new SkillsAndTransformation()

const ApiUrl = global.platformURI;

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#EC7A12"
    }
}))(LinearProgress);

const BorderLinearProgressComplete = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#06AD17"
    }
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%'
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    cardTitle: {
        color: "#808080"
    },
    cardDesc: {
        color: "#808080",
    },
    cardSubTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        color: "#3D7DDD",
    }
}));

const calculatePercentage = (val, total) => {
    let percentage = (val / total) * 100
    return Math.floor(percentage)
}

const Assessments = () => {

    const classes = useStyles();
    const [dradesc, setDradesc] = useState();
    const [gcindex, setGcindex] = useState();
    const [gc, setGc] = useState(null);
    const [tm, setTm] = useState(null);

    React.useEffect(() => {
        loadDescription();
        getRegisterdAssesment();
    }, []);

    const getRegisterdAssesment = async () => {
        let regApp = await skillsAndTransformation.getRegisteredassessments();
        if (regApp && regApp.status && regApp.data.data) {
            setTm(regApp.data.data?.tm || null);
            setGc(regApp.data.data?.gc || null);
        }
    }

    const newLaunchAssement = () => {
        axios.get(ApiUrl + `main/rdaurl`).then(res => {
            window.open(res.data.data.rdaurl);
        });
    };

    const loadDescription = () => {
        axios.get(ApiUrl + `main/description`).then(res => {
            setDradesc(res.data.data.dra);
            setGcindex(res.data.data.gcindex);
        });
    };

    return (
        <Layout>
            <main>
                <h2>My Assessments</h2>
                <Typography variant="subtitle1">
                    {AssesmentsSummary}
                </Typography>

                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid key={dradesc} item xs={6}>
                        <Card className={classes.root}>
                            <CardMedia
                                className={classes.cover}
                                image={aiImg}
                                title={dradesc}
                            />
                            <CardContent className={classes.content}>
                                <Typography className={classes.cardTitle}>
                                    {dradesc}
                                </Typography>
                                <Typography variant="h1" color="primary">
                                    Digital Readiness Assessment
                                </Typography>
                                <Typography variant="h1" color="primary">
                                    0
                                </Typography>
                                {calculatePercentage(0, 0) !== 100 ? <BorderLinearProgress variant="determinate" style={{ width: '80%' }} value={0} /> : <BorderLinearProgressComplete style={{ width: '80%' }} variant="determinate" value={0} />}
                                <Typography style={{ fontSize: 13, color: "#808080" }} color="primary">
                                    {0 + "% " + "completed"}
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginTop: 10 }}>
                                    <Button
                                        onClick={newLaunchAssement}
                                        style={{ fontSize: 12, borderRadius: 5, padding: '12px 55px' }} variant="outlined"
                                        disabled={!tm?.exists}
                                        color="primary">
                                        Launch
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid key={gcindex} item xs={6}>
                        <Card className={classes.root}>
                            <CardMedia
                                className={classes.cover}
                                image={projectsImg}
                                title={gcindex}
                            />
                            <CardContent className={classes.content}>
                                <Typography className={classes.cardTitle}>
                                    {gcindex}
                                </Typography>
                                <Typography variant="h1" color="primary">
                                    Game Changer Index Assessment
                                </Typography>
                                <Typography variant="h1" color="primary">
                                    0
                                </Typography>
                                {calculatePercentage(0, 0) !== 100 ? <BorderLinearProgress variant="determinate" style={{ width: '80%' }} value={0} /> : <BorderLinearProgressComplete style={{ width: '80%' }} variant="determinate" value={0} />}
                                <Typography style={{ fontSize: 13, color: "#808080" }} color="primary">
                                    {0 + "% " + "completed"}
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginTop: 10 }}>
                                    {gc?.exists && gc?.state === 9 ? (
                                        <Button
                                            onClick={newLaunchAssement}
                                            style={{ fontSize: 12, borderRadius: 5, padding: '12px 55px' }} variant="outlined"
                                            color="primary">
                                            Download Report
                                        </Button>
                                    ) :
                                        <Button
                                            onClick={newLaunchAssement}
                                            style={{ fontSize: 12, borderRadius: 5, padding: '12px 55px' }} variant="outlined"
                                            disabled={!gc?.exists}
                                            color="primary">
                                            Launch
                                        </Button>}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </main>
        </Layout>
    );
}

export default Assessments;