import { makeStyles } from '@material-ui/core/styles';
import variables from '../Sys/variable.scss';

export const commonStyles = {
  contentWrapper: {
    background: variables.contentColor,
    boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
    borderRadius: 5,
    padding: 20
  }
};

export default makeStyles((theme) => commonStyles);
