import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import Typography from '@material-ui/core/Typography';
import GenericTable from '../../Components/GenericTable';
// import { Paths } from '../../Routes/routePaths'
import { Pagination } from '@material-ui/lab';
import SearchBoxInput from 'Components/SearchBoxInput';
import { useHistory } from 'react-router';
import commonStyles from 'theme/commonStyles';
import { CoursesServices } from 'Services/CoursesServices';
import moment from 'moment';
import ConfirmationModal from 'Components/ConfirmationModal';
import { notify } from 'utils';
import { ProjectServices } from 'Services/ProjectServices';
import { set } from 'lodash';

const coursesServices = new CoursesServices();
const projectServices = new ProjectServices();

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    cardTitle: {
        color: "#808080"
    },
    cardSubTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        color: "#3D7DDD",
    },
    pageTitle: {
        marginBottom: 32,
    },
    searchBox: {
        border: "1px solid #808080",
        borderRadius: 25,
        padding: '7px 15px'
    }
}));

const columns = [
    { label: "Requested Course", key: 'course_name' },
    { label: "FirstName", key: 'firstName' },
    { label: "Surname", key: 'lastName' },
    { label: "Username", key: 'userName' },
    { label: "Date", key: 'created_at' },
    { label: "Email", key: 'email' },
    { label: "Action", key: "action", actions: ["approve", 'reject'] }
];

const CourseRequests = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();

    const [filterState, setFilterState] = React.useState({ page: 1, search: "" });
    const [deploymentList, setDeploymentList] = React.useState([]);
    const [pagination, setPagination] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [openApproveConfirmModal, setOpenApproveConfirmModal] = React.useState(false);
    const [actionType, setActionType] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        getAllCourseRequests(filterState);
    }, []);

    const getAllCourseRequests = async (queryData) => {
        let query = `search=${queryData.search}&page=${queryData.page}`;
        const requestList = await coursesServices.getCourseRequests(`main/courserequest/?${query}`);
        if (requestList && requestList.results) {
            setDeploymentList(requestList.results.map((request) => {
                return {
                    ...request,
                    course_name: request.course ? request.course.name : '',
                    firstName: request.user ? request.user.firstName : '',
                    lastName: request.user ? request.user.lastName : '',
                    userName: request.user ? request.user.userName : '',
                    created_at: moment(new Date(request.created_at)).format("DD-MM-YYYY"),
                    email: request.user ? request.user.email : '',
                };
            }));
            setPagination(requestList.pagination);
        }
        setLoading(false);
    };

    const handlePageChange = (evt, page) => {
        setFilterState({ ...filterState, page });
        return getAllCourseRequests({ ...filterState, page });
    };

    const handleSearch = e => {
        e.preventDefault();
        setLoading(true);
        return getAllCourseRequests({ ...filterState, page: 1 });
    };

    const handleItemClick = (row, action) => {
        if (!row) return null;
        let act = {
            approve: 'approved',
            reject: 'declined'
        };
        setSelectedItem(row);
        setActionType(act[action]);
        setOpenApproveConfirmModal(true);
    }

    const allocateUsers = async (data, id) => {
        return await projectServices.addAllocateUser(data, id);
    }

    const handleChangeRequestStatus = async () => {
        if(selectedItem){
            const updateRes = await coursesServices.updateCourseRequestStatus(selectedItem.uuid, {
                status: actionType
            });
            if(updateRes?.status){
                notify('success', `Course request successfully ${actionType}.`);
                setOpenApproveConfirmModal(false);
                setSelectedItem(null);
                if(actionType === 'approved'){
                    if(!selectedItem?.user?.project_id){
                        notify('error',"Project not found for this User!");
                        return null;
                    }
                    const data = {
                        course_id: selectedItem?.course?.course_id,
                        user: [selectedItem?.user?.uuid]
                    };
                    const courses = {
                        courses: [data]
                    };
                    allocateUsers(courses, selectedItem?.user.project_id).then(() => {
                            notify('success', "Course Allocated")
                        },
                        error => {
                            notify('error', 'Something went wrong');
                            console.log('error.response.status', error);
                        }
                    );
                }
                getAllCourseRequests({...filterState, page: 1});
                setActionType(null);
            }else{
                setActionType(null);
                setSelectedItem(null);
                setOpenApproveConfirmModal(false);
                notify("error", "This Course is not Assigned to your Organisation. Please request the course from Sales and Superadmin");
            }
        }
        return null;
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Typography variant="h4" className={classes.pageTitle}>Course Request</Typography>
                    {/* <Typography variant="body1" style={{fontSize: 12}} className={classes.pageTitle}><span style={{color: "#06AD17", fontWeight: 'bold'}}>52</span> New Course Request</Typography> */}
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <SearchBoxInput 
                                value={filterState.search} 
                                handleChange={(evt) => {
                                    setFilterState({ ...filterState, search: evt.target.value });
                                    return getAllCourseRequests({search: evt.target.value, page: 1});
                                }}
                                handleSearch={handleSearch} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 20 }}>
                        <GenericTable
                            columns={columns}
                            loading={loading}
                            handleItemClick={handleItemClick}
                            list={deploymentList}
                        />
                    </Grid>
                    <Grid container style={{ marginTop: 22 }} >
                        {pagination &&
                            <Pagination
                                onChange={handlePageChange}
                                style={{ margin: "0 auto" }}
                                count={parseInt(Math.ceil(pagination.total_entries / pagination.end_index))}
                                variant="outlined"
                                shape="rounded"
                                siblingCount={0}
                            />}
                    </Grid>
                </div>
                <ConfirmationModal 
                open={openApproveConfirmModal}
                handleConfirm={handleChangeRequestStatus}
                title={'Course Request Confirmation'}
                size={'sm'}
                handleClose={() => {
                    setActionType(null);
                    setOpenApproveConfirmModal(false);
                    setSelectedItem(null);
                }}>
                    <Grid container style={{marginTop: 10, marginBottom: 10}}>
                        <Grid xs={12} item>
                            <Typography variant="h4">Are you sure? You want to {actionType === 'approved' ? 'approve' : 'decline'} this course request.</Typography>
                        </Grid>
                    </Grid>
                </ConfirmationModal>
            </main>
        </Layout>
    );
}

export default CourseRequests;