import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { notify } from '../../utils';
import Input from '@material-ui/core/Input';
import { REQUIRED_ERROR } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UserServices } from '../../Services/UserServices';
import { CustomerServices } from '../../Services/CustomerServices';
import userDefaultImage from '../../Assets/images/user-default-image.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router';
import SendTestEmailModal from 'Components/SendTestEmailModal';
import commonStyles from 'theme/commonStyles';
import variables from '../../Sys/variable.scss';

const userServices = new UserServices();
const customerServices = new CustomerServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    },
    imgIcon: {
        width: 125,
        height:110,
        borderRadius: '50%',
    },
    input: {
        display: 'none'
    },
    icon: {
        position: 'relative',
        background: '#090909',
        borderRadius: '50%',
        textAlign: 'center'
    },
    iconStyle: {
        borderRadius: '60%',
        color: '#000',
        background: '#fff',
        border: '1px solid #808080'
    },
    iconbtn: {
        bottom: '-17px',
        left: '51px',
        position: 'absolute'
    }
}));

const initFormState = {
    firstName: '',
    lastName: '',
    country: '',
    email: '',
    memberSince: '',
    profile_image: '',
    email_host: '',
    port: '22',
    from: '',
    email_host_user: '',
    email_host_password: '',
    email_subject_prifix: '',
    recipients: ''
};

const Profile = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    
    const history = useHistory();
    const [companyName, setCompanyName] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [countryName, setCountryName] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSMTP, setShowSMTP] = useState(false);
    const [formState, setFormState] = useState({ ...initFormState });
    const [partner, setPartner] = useState(false);
    const [sendTestMailOpen, setSendTestMailOpen] = useState(false);
    const [sendTestEmailLoading, setSendTestEmailLoading] = useState(false);


    useEffect(() => {
        getUserProfile();
    }, []);

    async function getUserProfile() {
        const user = await userServices.getDetailMyProfile();
        if (user && user.data && user.data.length) {
            const userData = user.data[0];
            setCountryName(userData.country);
            setFormState({
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                member_since: userData.member_since,
                profile_image: userData.profile_image,
                country: userData.country,
                email_host: userData.email_host,
                email_host_password: userData.email_host_password,
                email_host_user: userData.email_host_user,
                email_subject_prifix: userData.email_subject_prifix,
                smtp: userData.smtp,
                strategy_partner: userData.strategy_partner,
                port: "22",
                secure_connection: false,
                use_authentication: false
            });
            setPartner(userData.strategy_partner)
            if (userData.smtp) {
                setShowSMTP(true);
            }
            const customer = user.data[0].customers && user.data[0].customers[0] ? user.data[0].customers[0].company_name : '';
            setCompanyName(customer);
        }
        const countryList = await customerServices.getCounties();
        setCountryList(countryList?.country_list || []);
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const formValidationCheck = () => {
        if (!formState.firstName || !formState.lastName || !formState.email || !formState.country) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        return handleFormSubmit(formState);
    }

    const handleFormSubmit = async (formState) => {
        setLoading(true)
        let result = await userServices.updateProfile(formState)
        if (result && result.status) {
            setLoading(false);
            notify("success", result.message);
            history.push('/dashboard');
        } else {
            notify("error", result && result.message ? result.message : 'Something Went Wrong')
            setLoading(false)
        }
    }

    const handleUploadImage = (e) => {
        let file = e.target.files[0];
        if (!file) return;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFormState({ ...formState, profile_image: reader.result })
        };
    }

    const handleChange = (value, field) => {
        setCountryName(value)
        setFormState({ ...formState, [field]: value });
    }

    const handleChangeCheck = () => {
        setShowSMTP(!showSMTP)
    }

    const handleChangeCheckItem = (name) => {
        if (name === "secure_connection") {
            setFormState({ ...formState, secure_connection: !formState.secure_connection })
        } else {
            setFormState({ ...formState, use_authentication: !formState.use_authentication })
        }
    }

    const editTheme = () => {
        const compId = localStorage.getItem('company_id');
        history.push(`/themeeditor/customer/${compId}`);
    }

    const handleSendEmailSubmit = async (recipients) => {
        setSendTestEmailLoading(true);
        let sendEmailData = { ...formState };

        let data = {
            email_host: sendEmailData.email_host,
            email_subject_prifix: sendEmailData.email_subject_prifix,
            email_host_user: sendEmailData.email_host_user,
            email_host_password: sendEmailData.email_host_password,
            recipients: recipients
        };
        const emailRes = await customerServices.sendTestEmail(data);
        if (emailRes && emailRes.status) {
            notify('success', emailRes?.message || "Email send successfully");
        } else {
            notify("error", (emailRes && emailRes.message) ? emailRes.message : 'Something Went Wrong');
        }
        setSendTestEmailLoading(false);
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Typography
                        variant="subtitle2"
                        className={classes.heading}
                        color="textPrimary">
                        Profile
                    </Typography>
                    <Grid container spacing={8} style={{marginTop: 0}}>
                        <Grid item xs={2}>
                            <div className={classes.icon}>
                                <img 
                                width='130px' 
                                className={classes.imgIcon} 
                                src={formState.profile_image ? formState.profile_image : userDefaultImage} alt='User Image' />
                                <div className={classes.iconbtn}>
                                    <input accept="image/*" className={classes.input} onChange={handleUploadImage} id="icon-button-file" type="file" />
                                    <label htmlFor="icon-button-file">
                                        <IconButton 
                                        className={classes.iconStyle} 
                                        color="primary" 
                                        aria-label="upload picture" 
                                        component="span">
                                            <CameraAltOutlinedIcon />
                                        </IconButton>
                                    </label>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                First Name
                            </Typography>
                            <Input
                                disableUnderline
                                value={formState.firstName}
                                type={'text'}
                                name="firstName"
                                className={classes.inputStyle}
                                placeholder={"First Name"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Last Name
                            </Typography>
                            <Input disableUnderline value={formState.lastName}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Last Name"}
                                style={{ width: '100%' }}
                                name="lastName"
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Member Since
                            </Typography>
                            <Input disableUnderline value={formState.member_since}
                                type={'text'}
                                placeholder={"Member Since"}
                                className={classes.inputStyle}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="member_since"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Email
                            </Typography>
                            <Input disableUnderline value={formState.email}
                                type={'text'}
                                disabled={true}
                                className={classes.inputStyle}
                                placeholder={"Email"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="email"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Company Name
                            </Typography>
                            <Input
                                disableUnderline
                                value={companyName}
                                type={'text'}
                                disabled={true}
                                className={classes.inputStyle}
                                placeholder={"Company Name"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="customer"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Country List
                            </Typography>
                            <Autocomplete
                                id="combo-box-demo4"
                                value={formState.country}
                                options={countryList ? countryList : []}
                                getOptionLabel={option => option}
                                onChange={(event, newValue) => {
                                    handleChange(newValue, 'country');
                                }}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        color="secondary"
                                        placeholder="Select Country"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    {partner && (<React.Fragment>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={showSMTP ? true : false} onChange={() => handleChangeCheck('smtp')} name="smtp" />}
                                        label="SMTP Settings"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {showSMTP && (
                            <React.Fragment>
                                <Typography variant='h4' style={{ textAlign: 'center' }}>
                                    SMTP Details
                                </Typography>
                                <Grid container spacing={8}>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                            SMTP Server
                                        </Typography>
                                        <Input disableUnderline value={formState.email_host}
                                            type={'text'}
                                            className={classes.inputStyle}
                                            placeholder={"SMTP Server"}
                                            style={{ width: '100%' }}
                                            name="email_host"
                                            color={'secondary'}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                            Port
                                        </Typography>
                                        <Input
                                            disableUnderline
                                            value={formState.port}
                                            type={'text'}
                                            className={classes.inputStyle}
                                            placeholder={"Port"}
                                            style={{ width: '100%' }}
                                            name="smtp"
                                            color={'secondary'}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={8}>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                            Email Account
                                        </Typography>
                                        <Input
                                            disableUnderline
                                            value={formState.from}
                                            type={'text'}
                                            className={classes.inputStyle}
                                            placeholder={"Email Account"}
                                            style={{ width: '100%' }}
                                            name="from"
                                            color={'secondary'}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                            Mail Subject
                                        </Typography>
                                        <Input
                                            disableUnderline
                                            value={formState.email_subject_prifix}
                                            type={'text'}
                                            className={classes.inputStyle}
                                            placeholder={"Mail Subject"}
                                            style={{ width: '100%' }}
                                            name="email_subject_prifix"
                                            color={'secondary'}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FormGroup style={{ margin: "10px 0" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={formState.secure_connection ? true : false} onChange={() => handleChangeCheckItem('secure_connection')} name="secure_connection" />}
                                                label="Use Secure Connection"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={formState.use_authentication ? true : false} onChange={() => handleChangeCheckItem('use_authentication')} name="use_authentication" />}
                                                label="Use Authentication"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                            Login
                                        </Typography>
                                        <Input
                                            disableUnderline
                                            value={formState.email_host_user}
                                            type={'text'}
                                            className={classes.inputStyle}
                                            placeholder={"Login"}
                                            style={{ width: '100%' }}
                                            name="email_host_user"
                                            color={'secondary'}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                            Password
                                        </Typography>
                                        <Input disableUnderline value={formState.email_host_password}
                                            type={'password'}
                                            className={classes.inputStyle}
                                            placeholder={"Password"}
                                            style={{ width: '100%' }}
                                            name="email_host_password"
                                            color={'secondary'}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <Button
                                            type={"button"}
                                            onClick={evt => setSendTestMailOpen(true)}
                                            variant="contained"
                                            color="primary"
                                            style={{ borderRadius: '5px' }}
                                        >
                                            Send Test Email
                                        </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                    )}
                    {partner && (<Grid container spacing={5}>
                        <Grid item xs={4}>
                            <Button style={{marginLeft: 0}} className={classes.btn} variant="outlined" onClick={editTheme} color="primary">Edit Theme</Button>
                        </Grid>
                    </Grid>
                    )}
                    <div className={classes.placeBtn}>
                        <Button onClick={() => {
                            history.push('/dashboard');
                        }} color="primary" className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            className={classes.btn}
                            onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={20} color="secondary" /> : 'Update'}
                        </Button>
                    </div>
                </div>
                <SendTestEmailModal
                    open={sendTestMailOpen}
                    handleClose={evt => setSendTestMailOpen(false)}
                    handleSubmit={handleSendEmailSubmit}
                    loading={sendTestEmailLoading}
                />
            </main>
        </Layout>
    );
}

export default Profile;