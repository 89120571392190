import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const LineChartDuo = ({ data, firstKey, secondKey }) => {

  return (
    <div style={{ width: '95%', height: 500, position: "relative", marginTop: 30 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={400}
          height={500}
          data={data}
          margin={{
            top: 25,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={firstKey} stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey={secondKey} stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LineChartDuo
