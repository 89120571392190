import React, { useState } from 'react';
import {
    FormControl,
    Button,
    Dialog,
    DialogTitle,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup
} from '@material-ui/core';
import PropTypes from 'prop-types';

const FilterModal = (props) => {
    let { onClose, selectedValue, open, allColumns } = props;
    const [active, setactive] = useState([]);
    const handleClose = () => {
      selectedValue = active;
      onClose(selectedValue);
    };
  
    const handleRadioChange = event => {
      setactive(event.target.value);
    };
  
    return (
      <Dialog aria-labelledby="filter" onClose={handleClose} open={open}>
        <DialogTitle id="filterreport">Filter</DialogTitle>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={active}
            onChange={handleRadioChange}>
            <FormControlLabel value="true" control={<Radio />} label="Active" />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
          <hr></hr>
          <DialogTitle id="filterreport">Hide and Show Columns</DialogTitle>
          <FormGroup>
            {allColumns.map(column => (
              <div key={column.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                    //   {...column.getToggleHiddenProps()}
                      color="primary"
                    />
                  }
                  label={column.Header}
                />
              </div>
            ))}
          </FormGroup>
          <Button
            onClick={() => handleClose()}
            type="submit"
            variant="contained"
            color="primary">
            Apply Filter
          </Button>
        </FormControl>
      </Dialog>
    );
}

FilterModal.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
    allColumns: PropTypes.string.isRequired
};

export default FilterModal;