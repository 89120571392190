import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ProjectServices } from '../../../../Services/ProjectServices';
import { useStyle as onboardingStyle  } from '../../../CustomerOnBoarding';
import variables from '../../../../Sys/variable.scss';

const projectServices = new ProjectServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        background: '#3D7DDD',
        fontSize: '17px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#3d7ddd99"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0'
    },
    btnPwd: {
        background: '#3D7DDD',
        color: 'white',
        borderRadius: '25px',
        padding: '18px',
        marginTop: '-3px',
        marginLeft: '-42px',
        "&:hover": {
            background: "#3D7DDD"
        }
    },
    divOuter: {
        border: '1px solid #838383',
        borderRadius: 10
    },
    color: {
        color: "#373737"
    }
}));

const ProjectSummary = ({
    handleEditTab,
    handleBack,
    handleNext
}) => {

    const classes = useStyles();
    const onBorningClasses = onboardingStyle();

    const [loading, setLoading] = React.useState(false);
    const [projectSummary, setProjectSummary] = React.useState(null);

    React.useEffect(() => {
        getProjectSummary();
    }, []);

    async function getProjectSummary(){
        setLoading(true);
        const projSum = await projectServices.getProjectSummary(localStorage.getItem('project_id'));
        if(projSum && projSum.data){
            localStorage.setItem('summaryload', true);
            setProjectSummary(projSum.data);
        }
        setLoading(false);
    }

    return (
        <>
            <Grid container style={{ marginBottom: '15px', marginTop: '15px' }}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Project Name *
                    </Typography>
                    <Input 
                        disableUnderline                        
                        readOnly
                        value={projectSummary ? projectSummary.project_name : ''}
                        type={'text'}
                        name="projectName"
                        className={classes.inputStyle}
                        placeholder={"Project Name"}
                        style={{ maxWidth: 220 }}
                        color={'secondary'}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Company Name *
                    </Typography>
                    <Input 
                        disableUnderline
                        value={projectSummary ? projectSummary.company_name : ''}
                        readOnly
                        type={'text'}
                        className={classes.inputStyle}
                        placeholder={"Company Name"}
                        style={{ maxWidth: 220 }}
                        name="companyName"
                        color={'secondary'}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Customer Admin *
                    </Typography>
                    <Input 
                        readOnly
                        disableUnderline                        
                        value={projectSummary ? projectSummary.customer_admin : ''}
                        type={'text'}
                        placeholder={"Customer Admin"}
                        className={classes.inputStyle}
                        style={{ maxWidth: 220 }}
                        color={'secondary'}
                        name="customerAdmin"
                    />
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: '15px', marginTop: '15px' }}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Project Admin
                    </Typography>
                    <Input 
                        disableUnderline                        
                        readOnly
                        type={'text'}
                        className={classes.inputStyle}
                        placeholder={"Project Admin"}
                        value={projectSummary ? projectSummary.project_admin : ''}
                        style={{ maxWidth: 220 }}
                        color={'secondary'}
                        name="projectAdmin"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Status
                    </Typography>
                    <Input 
                        disableUnderline
                        readOnly
                        type={'text'}
                        className={classes.inputStyle}
                        placeholder={"Status"}
                        style={{ maxWidth: 220 }}
                        color={'secondary'}
                        value={projectSummary ? projectSummary.status : ''}
                        name="status"
                    />
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: '15px', marginTop: '35px' }}>
                <Grid item xs={12}>
                    <div className={classes.divOuter}>
                        <Typography className={classes.color} style={{ padding: 10, borderBottom: '1px solid #838383' }}>User</Typography>
                        <Typography className={classes.color} style={{ padding: 10 }}>Total : {projectSummary ? projectSummary.total_users_count : 0}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ padding: '15px 0 10px 10px', display: 'flex' }}>
                                <Typography className={classes.color} style={{ marginRight: 30 }}>Customers Admin : {projectSummary ? projectSummary.company_admins_count : 0}</Typography>
                                <Typography className={classes.color} style={{ marginRight: 30 }}>Project Admin : {projectSummary ? projectSummary.project_admins_count : 0}</Typography>
                                <Typography className={classes.color} style={{ marginRight: 30 }}>User : {projectSummary ? projectSummary.remaining_users : 0}</Typography>
                            </div>
                            <Button onClick={(evt) => handleEditTab('user')} variant="outlined" style={{ color: "#3D7DDD", borderColor: "#3D7DDD", borderRadius: 5, margin: '0 10px 10px 0' }}>Edit User</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: '15px', marginTop: '35px' }}>
                <Grid item xs={12}>
                    <div className={classes.divOuter}>
                        <Typography className={classes.color} style={{ padding: 10, borderBottom: '1px solid #838383' }}>Licences</Typography>
                        <Typography className={classes.color} style={{ padding: 10 }}>Total Licence Allocated : {projectSummary ? projectSummary.total_linceses : 0}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ padding: '15px 0 10px 10px', display: 'flex' }}>
                                <Typography className={classes.color} style={{ marginRight: 30 }}>Assessments : {projectSummary ? projectSummary.assesment_licenses_count : 0}</Typography>
                                <Typography className={classes.color} style={{ marginRight: 30 }}>Courses : {projectSummary ? projectSummary.course_linceses_count : 0}</Typography>
                                <Typography className={classes.color} style={{ marginRight: 30 }}>Apprenticeship : {projectSummary ? projectSummary.apprenticeship_lincenses_count : 0}</Typography>
                                <Typography className={classes.color} style={{ marginRight: 30 }}>Labs : {projectSummary ? projectSummary.lab_licenses_count : 0}</Typography>
                            </div>
                            <Button onClick={handleBack} variant="outlined" style={{ color: "#3D7DDD", borderColor: "#3D7DDD", borderRadius: 5, margin: '0 10px 10px 0' }}>Edit Licence Allocation</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className={onBorningClasses.buttons}>
                <div className={onBorningClasses.wrapper}>
                    <Button
                        type={"button"}
                        onClick={(evt) => handleEditTab('project')}
                        variant="contained"
                        style={{minWidth: 160, padding: '16px 0px'}}
                        color="primary"
                        className={onBorningClasses.button}
                    >
                        Create Project
                    </Button>
                </div>
            </div>
            <div className={onBorningClasses.buttons}>
                <div className={onBorningClasses.wrapper}>
                    <Button
                        type={"submit"}
                        onClick={handleNext}
                        style={{minWidth: 160, padding: '16px 0px'}}
                        variant="contained"
                        color="primary"
                        className={onBorningClasses.button}
                    >
                        Finish Project
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProjectSummary;
