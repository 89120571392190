import axios from 'axios';
import "../Sys/config.js";

var apiUrl = global.platformURI;

export class CoursesServices {
    constructor() {
        axios.interceptors.request.use(function (config) {
            let token = localStorage.getItem('ssoToken');
            config.baseURL = apiUrl;
            config.headers.Authorization = `Token ${token}`;
            return config;
        }, error => {
            console.log('error.response.status', error);
            return error;
        });
    }

    getAllCourses(url) {
        return axios.get(url)
            .then(res => res.data);
    }

    addCourses(payload, uuid) {
        if (uuid) {
            return axios.put(`main/coursedetail/${uuid}`, payload).then(res => res.data);
        }
        return axios.post(apiUrl + "main/coursecreate", payload)
            .then(res => res.data)
    }

    getCourseDetail(id) {
        return axios.get(apiUrl + `main/coursedetail/${id}`)
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    getSessionLinkToCourse(course, user = null) {
        return axios.get(`${apiUrl}main/gotocourse/${course}`)
            .then(res => res.data)
    }

    getLabLaunchURL(payload) {
        return axios.post(`${apiUrl}main/launch_labs`, payload)
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    getCourseRequests(url) {
        return axios.get(url)
            .then(res => res.data)
    }

    updateCourseRequestStatus(id, payload) {
        return axios.patch(`main/courserequest/${id}/`, payload)
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }
    getEcommerceCourses(query = '') {
        return axios.get(`project/coursefor_ecommerce?${query}`)
            .then(res => res.data)
    }
}
