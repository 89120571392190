import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Box, IconButton, Input, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';
import './event.css'
import { CalendarServices } from '../../../Services/CalendarServices';
import { RepeatMode, DaysList, USER_ROLES } from '../../../utils/constants'
import variables from '../../../Sys/variable.scss';
import OutlinedSelect from 'Components/OutlinedSelect';
import { ProjectServices } from '../../../Services/ProjectServices';

const projectServices = new ProjectServices();
const calendarServices = new CalendarServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        width: '100%',
        borderColor: '#838383',
        borderRadius: '25px',
    },
    inputStyles: {
        width: '60px',
        minWidth: '60px',
        padding: '9px 5px',
        textAlign: "center",
        border: '1px solid #ebebeb',
    },
}));

export const calculateDuration = (start_date, end_date) => {
    let startDate = new Date(start_date);
    let endDate = new Date(end_date);
    let seconds = Math.floor((endDate - startDate) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return `${hours} Hours ${minutes} Minutes`;
}

const initFormState = { from: null, to: null, duration: "", repeat_mode: "", repeatAfter: "", neverEnds: false, startDate: null, endDate: null, occurence: "", repeatOn: '', title: '', note: '', eventType: "", url: "", course: {}, courseActivity: "",  typeMode: [], type: '' };

const typeList = ['Event', 'Meeting', 'Public Holiday']
const eventTypeList = ['Assessment', 'Course', 'Apprenticeship']
const courseActivityList = ['Self Pased Learning', 'Virtual Class Project', 'Work Readiness Examination']

export default function SingleEventModal({ open, handleClose, handleSaveSingleEvent, id }) {
    const classes = useStyles();

    const userRole = localStorage.getItem("role");
    
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState('');
    const [formState, setFormState] = React.useState({ ...initFormState });
    const [projectList, setProjectList] = useState([]);
    const [assessmentList, setAssessmentList] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [apprenticeshipList, setApprenticeshipList] = useState([]);
    const [editEventDetails, setEditEventDetails] = useState(null);
    
    React.useEffect(() => {
        getLicensesList();
        setLoading(true);
        if (id) {
            getEventDetails(id);
        }
    }, [id]);

    React.useEffect(() => {
        if(editEventDetails){
            if(editEventDetails?.sub_type === "Course"){
                if(coursesList.length){
                    let selectedCourse = coursesList.find(crs => editEventDetails.event_course_id === crs.course_id);
                    console.log("editEventDetails ======= : ", editEventDetails, assessmentList, apprenticeshipList);
                    setFormState({...formState, course: selectedCourse });
                }
            }else if(editEventDetails?.sub_type === "Assessment"){
                if(assessmentList.length){
                    let selectedAsses = coursesList.find(crs => editEventDetails.event_assessment_id === crs.assessment_id);
                    setFormState({...formState, course: selectedAsses });
                }
            }else{
                if(apprenticeshipList.length){
                    let selectedApprs = apprenticeshipList.find(crs => editEventDetails.event_apprenticeship_id === crs.apprenticeship_id);
                    setFormState({...formState, course: selectedApprs });
                }
            }
        }
    }, [coursesList, editEventDetails]);

    const getLicensesList = async () => {
        if(userRole !== USER_ROLES.USER){
            const projectList = await projectServices.getAllLicenseListProject(`project/licenselist`);
            if (projectList && projectList.data) {
                const allProject = projectList && projectList.data.length > 0 ? projectList.data.map(item => {
                    return {
                        name: item.project_name,
                        id: item.project_uuid
                    }
                }) : [];
                setProjectList(allProject);
            }
        }
    };

    const getAssessmentsList = async (id) => {
        const payload = {
            project_id: id
        }
        const licenseDetails = await projectServices.getLicenseinformation(payload);
        if (licenseDetails.status && licenseDetails.data) {
            const data = licenseDetails.data
            setAssessmentList(data.assessments);
            setApprenticeshipList(data.apprenticeships);
            setCoursesList(data.courses);
        }
    }

    const getEventDetails = async (id) => {
        const eventDetails = await calendarServices.getEvent(id);
        if (eventDetails.status) {
            let data = eventDetails.data[0]
            setEditEventDetails(data);
            if (data.start_date && data.end_date) {
                setValue('On');
            }
            getAssessmentsList(data.event_project_id);
            let selectedProj = [];
            if(userRole === USER_ROLES.USER){
                let projectDetails = await projectServices.getProjectDetail(data.event_project_id);
                if(projectDetails?.data){
                    selectedProj = [{
                        name: projectDetails.data.project_name,
                        id: projectDetails.data.project_uuid
                    }];
                }
            }else{
                selectedProj = projectList.filter((itm) => itm.id === data.event_project_id);
            }
            let courseActivityType = "";
            if(data.main_type === "Event"){
                switch ( data?.sub_type ) {
                    case 'Course':
                        courseActivityType = data.course_activity
                        break;
                    case 'Assessment':
                        courseActivityType = data.assessment_activity
                        break;
                    case 'Apprenticeship':
                        courseActivityType = data.apprenticeship_activity
                        break;
                    default:
                        break;
                }
            }
            const durationIs = calculateDuration(data.start_date + ' ' + data.start_time, data.end_date + ' ' + data.end_time);
            let startTime = new Date(data.start_date + ' ' + data.start_time);
            let endTime = new Date(data.end_date + ' ' + data.end_time);
            let selectedFormState = { ...formState, startDate: new Date(data.start_date), endDate: new Date(data.end_date), from: startTime, to: endTime, repeat_mode: data.repeat_mode, duration: durationIs, expiry_date: new Date(data.expiry_date), title: data.name, note: data.note, type: data.main_type, url: data.url, project: (selectedProj.length) ? {id: selectedProj[0].id, name: selectedProj[0].name } : {}, eventType: data?.sub_type, courseActivity: courseActivityType};
            setFormState({...selectedFormState});
        }
    }

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    const handleChange = (value, name) => {
        if (name === 'typeMode') {
            setFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.typeMode.push(value.target.value);
                } else {
                    let index = newState.typeMode.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.typeMode.splice(index, 1);
                    }
                }
                return newState;
            });
        } else if (name === 'neverEnds') {
            setFormState({ ...formState, [name]: true });
        } else if (name === 'project') {
            if (value === null) {
                setFormState({ ...formState, [name]: {}, eventType: "" });
            } else {
                getAssessmentsList(value.id)
                setFormState({ ...formState, [name]: value });
            }
        } else if (name === "eventType") {
            setFormState({ ...formState, courseActivity: "", course: {}, [name]: value });
        } else {
            setFormState({ ...formState, [name]: value });
        }
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    let listTitle = formState.eventType === 'Assessment' ? 'Assessment' : formState.eventType === 'Apprenticeship' ? 'Apprenticeship' : 'Course';
    let dynamicList = formState.eventType === 'Assessment' ? assessmentList : formState.eventType === 'Apprenticeship' ? apprenticeshipList : coursesList;

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="id">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Typography
                                variant="h1"
                                color="primary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Event Details
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                From *
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    variant="inline"
                                    placeholder={"HH:MM"}
                                    className={"timePickerStyle"}
                                    inputProps={{ style:{padding: 6} }}
                                    value={formState.from}
                                    onChange={date => setFormState({ ...formState, from: date })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                To *
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    disableUnderline
                                    variant="inline"
                                    value={formState.to}
                                    className={"timePickerStyle"}
                                    placeholder={"HH:MM"}
                                    inputProps={{ style:{padding: 6} }}
                                    onChange={date => setFormState({ ...formState, to: date })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Title *
                            </Typography>
                            <Input
                                disableUnderline
                                value={formState.title}
                                type={'text'}
                                placeholder={"Title"}
                                color={'secondary'}
                                className={classes.inputStyle}
                                name="title"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Repeat Mode *
                            </Typography>
                            <OutlinedSelect
                                options={RepeatMode}
                                name="repeat_mode"
                                val={formState.repeat_mode}
                                placeholder='Repeat Mode'
                                variant="standard"
                                handleChange={(evt) => handleInputChange(evt)}
                                styleOverrides={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: 10, marginTop: 15, color: variables.labelcolor }}>
                                Repeat On
                            </Typography>
                            <RadioGroup aria-label="quiz" name="quiz" value={day} onChange={handleDayRadioChange}>
                                <Grid item xs={12}>
                                    {DaysList.map((item, index) => (
                                        <Radio
                                            checked={item === formState.repeatOn}
                                            key={index}
                                            classes={{ root: classes.root }}
                                            style={{
                                                marginRight: 10,
                                                borderRadius: 25,
                                                backgroundColor: item === formState.repeatOn ? '#3D7DDD' : ''
                                            }}
                                            onChange={() => setFormState({ ...formState, repeatOn: item })}
                                            value={item}
                                            name="site-select"
                                            checkedIcon={
                                                <div className={classes.icon}>
                                                    <Typography
                                                        variant={'subtitle1'}
                                                        style={{ color: 'white', fontSize: 13 }}>
                                                        {item}
                                                    </Typography>
                                                </div>
                                            }
                                            icon={
                                                <div className={classes.icon}>
                                                    <Typography variant={'subtitle1'} style={{ fontSize: 13 }} color={'textPrimary'}>
                                                        {item}
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    ))}
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginTop: 10, color: '#333333', fontWeight: 600 }}>
                                Ends
                            </Typography>
                            <Grid item xs={12}>
                                <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                                    <Grid item xs={12}>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                value="Never"
                                                control={<Radio style={{ border: 0 }} />}
                                                labelPlacement="end"
                                                label="Never"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex' }}>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                style={{ marginBottom: 10 }}
                                                value="On"
                                                control={<Radio style={{ border: 0 }} />}
                                                labelPlacement="end"
                                                label="On"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    clearable
                                                    disabled={value !== 'On' ? true : false}
                                                    format="dd/MM/yyyy"
                                                    className={"timePickerStyle"}
                                                    inputProps={{ style:{ padding: 6 } }}
                                                    value={formState.startDate}
                                                    onChange={date => setFormState({ ...formState, startDate: date })}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                                style={{ padding: '12px 14px' }}>
                                                To
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    clearable
                                                    disabled={value !== 'On' ? true : false}
                                                    format="dd/MM/yyyy"
                                                    className={"timePickerStyle"}
                                                    inputProps={{ style:{ padding: 6 } }}
                                                    value={formState.expiry_date}
                                                    onChange={date => setFormState({ ...formState, endDate: date })}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    {userRole === USER_ROLES.CUSTOMER_ADMIN &&
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Project *
                            </Typography>
                            <Autocomplete
                                id="combo-box-demo4"
                                value={formState.project}
                                name="project"
                                options={projectList ? projectList : []}
                                getOptionLabel={option => option.name}
                                onChange={(event, newValue) => {
                                    console.log("newValue: ", newValue);
                                    handleChange(newValue, 'project');
                                }}
                                style={{ width: "100%" }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        color="secondary"
                                        placeholder="Select Project"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Type *
                            </Typography>
                            <OutlinedSelect
                                options={typeList}
                                name="type"
                                val={formState.type}
                                placeholder='Type'
                                variant="standard"
                                handleChange={(evt) => handleInputChange(evt)}
                                styleOverrides={{ width: '100%', marginTop: 0 }}
                            />
                        </Grid>
                    </Grid>
                    {formState.type && formState.type === 'Event' ? (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                        Event Type *
                                    </Typography>
                                    <OutlinedSelect
                                        options={eventTypeList}
                                        name="eventType"
                                        val={formState.eventType}
                                        placeholder='Event Type'
                                        variant="standard"
                                        handleChange={(evt) => handleInputChange(evt)}
                                        styleOverrides={{ width: '100%', marginTop: 0 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                        {listTitle} *
                                    </Typography>
                                    <Autocomplete
                                        id="combo-box-demo4"
                                        value={formState.course}
                                        name="course"
                                        disabled={!formState.project.name}
                                        options={dynamicList ? dynamicList : []}
                                        getOptionLabel={option => {
                                            if (formState.eventType === 'Assessment') {
                                                return option.assessment_id__name
                                            } else if (formState.eventType === 'Apprenticeship') {
                                                return option.apprenticeship_id__name
                                            } else {
                                                return option.course_id__name
                                            }
                                        }}
                                        onChange={(event, newValue) => {
                                            handleChange(newValue, 'course');
                                        }}
                                        style={{ width: "100%" }}
                                        renderInput={params => (
                                            <div ref={params.InputProps.ref}>
                                                <input
                                                    placeholder={`select ${listTitle}`}
                                                    style={{
                                                        width: '100%',
                                                        padding: '16px 24px',
                                                        border: '1px solid #ebebeb',
                                                        borderRadius: '25px',
                                                    }}
                                                    type="text"
                                                    {...params.inputProps}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                        {listTitle} Activity *
                                    </Typography>
                                    <OutlinedSelect
                                        options={courseActivityList}
                                        name="courseActivity"
                                        val={formState.courseActivity}
                                        placeholder='Activity'
                                        variant="standard"
                                        handleChange={(evt) => handleChange(evt.target.value, 'courseActivity')}
                                        styleOverrides={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    {(formState.type === 'Meeting' || formState.type === 'Public Holiday' || formState.courseActivity === 'Virtual Class Project') ? (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                    URL *
                                </Typography>
                                <Input
                                    disableUnderline
                                    value={formState.url}
                                    type={'text'}
                                    placeholder={"Meeting URL"}
                                    color={'secondary'}
                                    className={classes.inputStyle}
                                    name="url"
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Comments
                            </Typography>
                            <Input
                                disableUnderline
                                value={formState.note}
                                multiline
                                rows={3}
                                type={'text'}
                                placeholder={"Add note here..."}
                                color={'secondary'}
                                className={classes.inputStyle}
                                name="note"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} color="primary">
                        Cancel 
                    </Button>
                    <Button onClick={(e) => handleSaveSingleEvent({...formState})} variant="outlined" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}