import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../../../Components/layouts";
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import { Badge, Button, Grid, Input, LinearProgress, Typography } from '@material-ui/core';
import MenuList from "@material-ui/core/MenuList";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from "@material-ui/core/MenuItem";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { notify } from 'utils';
import ConfirmationModal from 'Components/ConfirmationModal';
import { UserServices } from '../../../../Services/UserServices';
import { useLocation } from 'react-router';
import { CourseContext } from 'Context/CourseProvider';

const skillsAndTransformation = new SkillsAndTransformation();
const userServices = new UserServices();

const useStyles = makeStyles((theme) => ({
    activeMenu: {
        background: "#3D7DDD",
        color: "#fff",
    },
    sidebarMenu: {
        background: "#FAFCFF",
        border: '1px solid #DEDEDE',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5,
        height: '100vh',
        overflow: "scroll"
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    title: {
        fontSize: 21,
        fontWeight: 700,
    },
    desc: {
        margin: '20px 0'
    },
    lockIcon: {
        fontSize: 38,
        color: "#EC7A12"
    },
    unLockIcon: {
        fontSize: 38,
        color: "#EC7A12"
    },
    mainContent: {
        height: '100vh',
        overflow: "scroll"
    },
    courseActionBtn: {
        fontSize: 14, 
        borderRadius: 5, 
        minWidth: 165,
        padding: '12px 40px', 
        color: "#fff"
    },
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    countIcon: {
        position: 'absolute', 
        right: 20,
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            backgroundColor: 'gray',
            color: '#FFF'
        }
    }
}));

const CourseItem = ({ course, handleCourseAction }) => {
    const classes = useStyles();
    return (
        <Grid item xs={6}>
            <Card className={classes.root} style={{ minHeight: 194 }}>
                <CardContent className={classes.content}>
                    <div style={{height: 120, overflow: 'auto'}}>
                        <Typography className={classes.title} color="primary">
                            {course.name}
                        </Typography>
                        <Typography variant="subtitle2" className={classes.desc} color="textSecodary">
                            {course.description ? course.description : "Description not available"}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", marginTop: 10 }}>
                        <Button 
                        className={classes.courseActionBtn}
                        onClick={() => handleCourseAction(course)} 
                        style={{backgroundColor: course.locked ? "#EC7A12" : "#06AD17" }} variant="outlined" >
                            {course.locked ? "Request" : "Launch"}
                        </Button>
                        {course.locked ? <LockIcon style={{ fontSize: '30px', marginTop: 4, color: "#C5C7CD" }} /> : <LockOpenIcon style={{ fontSize: '30px', marginTop: 4, color: "#06AD17" }} />}
                    </div>
                </CardContent>
            </Card>
        </Grid >
    );
}

const CourseCatalogue = () => {
    
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [courseCatalogueList, setCourseCatalogueList] = useState([]);
    const [activeCatalogue, setActiveCatalogue] = useState("");
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [requestLoading, setRequestLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [comment, setComment] = useState("");
    const location = useLocation();
    const { courseCatalogues } = useContext(CourseContext); 

    useEffect(() => {
        getUserDetails();
    }, [location]);

    React.useEffect(() => {
        let courseList = courseCatalogues;
        const query = new URLSearchParams(location.search);
        const category = query.get('category') || null;
        if (courseList?.status) {
            setLoading(false);
            getCourseCatalogue(category);
        };
    }, [courseCatalogues, location]);

    async function getUserDetails(){
        const user = await userServices.getDetailMyProfile();
        if(user && user.data && user.data.length){
            setUser(user.data[0]);
        }
    }

    const getCourseCatalogue = async (category) => {
        let courseList = courseCatalogues;
        if (courseList.status) {
            setLoading(false);
            setCourseCatalogueList(courseList.data);
            let selected = courseList.data[0].category;
            if(category){
                const filterd = courseList.data.filter(itm => itm.category === category);
                if(filterd.length){
                    selected = filterd[0].category;
                }
            }
            setActiveCatalogue(selected);
        }
    }

    const handleCLickMenuItem = (item) => {
        setActiveCatalogue(item.category);
    }

    const handleCourseAction = (course) => {
        if(course.locked){
            setSelectedCourse(course);
            setOpenConfirmModal(true);
        }else{
            if (course.link) {
                window.open(course.link, "_blank")
            } else {
                notify('error', "Requested link not available")
            }
        }
    }

    const handleRequestSubmit = async () => {
        if(selectedCourse && user){
            setRequestLoading(true);
            const payload = {
                course: selectedCourse.uuid,
                user: user.uuid,
                status: 'pending',
                comment
            };
            try{
                await skillsAndTransformation.sendCourseRequest(payload);
                setRequestLoading(false);
                setOpenConfirmModal(false);
                setSelectedCourse(null);
                setComment("");
                notify('success', "Request submited successfully");
            }catch(e){
                setRequestLoading(false);
                setOpenConfirmModal(false);
                setSelectedCourse(null);
                    console.log("Error: ", e.response);
                if(e.response && e.response.data){
                    notify('error', e.response.data.message);
                    return null;
                }
                notify('error', "Something went wrong");
            }
        }
    }

    return (
        <Layout>
            {loading ? <LinearProgress size={40} color="primary" /> : (
                <Grid container spacing={2}>
                    <Grid item xs={3} className={classes.sidebarMenu}>
                        <h2>Course Category</h2>
                        <MenuList id="simple-menu" open={true}>
                            {(courseCatalogueList && courseCatalogueList.length > 0) ? courseCatalogueList.map(item => (
                                <MenuItem 
                                onClick={() => handleCLickMenuItem(item)} 
                                id={item.category === activeCatalogue ? 'selectedCategory' : ''}
                                style={{ background: item.category === activeCatalogue && "#3D7DDD", color: item.category === activeCatalogue && "#fff" }} title={item.category}>{item.category && item.category.substring(0, 25)} <Badge max={999} className={classes.countIcon} badgeContent={item.courses ? item.courses.length : 0} /></MenuItem>
                            )) : null}
                        </MenuList>
                    </Grid>
                    <Grid item xs={9} className={classes.mainContent}>
                        <Grid item container spacing={2}>
                            {(courseCatalogueList && courseCatalogueList.length > 0) ? courseCatalogueList.map(item => {
                                if (item.category === activeCatalogue) {
                                    if (item.courses.length > 0) {
                                        return item.courses.map(course => (<CourseItem key={course.name} handleCourseAction={handleCourseAction} course={course} />))
                                    }
                                }
                            }) : null}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <ConfirmationModal 
            open={openConfirmModal}
            handleConfirm={handleRequestSubmit}
            title={<b>{`Request for ${selectedCourse && selectedCourse.name} Course`}</b>}
            loading={requestLoading}
            size={'sm'}
            handleClose={() => {
                setSelectedCourse(null);
                setOpenConfirmModal(false);
            }}>
                <Grid container style={{marginTop: 10, marginBottom: 10}}>
                    <Grid xs={12} item>
                        <Input 
                            disableUnderline 
                            value={comment}
                            type={'text'}
                            name="comment"
                            multiline
                            rows={4}
                            className={classes.inputStyle}
                            placeholder={"Comment"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={evt => setComment(evt.target.value)}
                        />
                    </Grid>
                </Grid>
            </ConfirmationModal>
        </Layout >
    );
}

export default CourseCatalogue;