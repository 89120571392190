import * as React from 'react';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Button, Typography } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { USER_ROLES } from 'utils/constants';

const CalendarSidebar = ({ changeDate, eventList, date, handleAddEvent }) => {

    const role = localStorage.getItem('role');
    const ChangeColorFunction = () => {
        var ColorCode = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        return ColorCode
    }

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={(date) => changeDate(date)}
                />
            </MuiPickersUtilsProvider>
            {eventList && eventList.length > 0 ? (
                <>
                    <Typography variant="h4">Filter Events</Typography>
                    <List>
                        {eventList.map(event => (
                            <ListItem key={event.uuid}>
                                <ListItemIcon style={{ color: `${ChangeColorFunction()}` }}>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary={event.name} />
                            </ListItem>
                        ))}
                    </List>
                </>
            ) : null}
            { (role === USER_ROLES.CUSTOMER_ADMIN || role === USER_ROLES.PROJECT_ADMIN) && <Button variant="outlined" onClick={handleAddEvent} color="primary" style={{ marginTop: 20, marginLeft: 10, borderRadius: 5, padding: '10px 20px' }}>
                <AddBoxOutlinedIcon style={{ marginRight: 10 }} />Add Event
            </Button>}
        </>
    );
}

export default CalendarSidebar;