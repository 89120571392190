import React from 'react';
import { Grid, Input, Typography, Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedSelect from '../../../Components/OutlinedSelect';
import { useStyles } from '../addUsers';
import variables from '../../../Sys/variable.scss';
import AutocompleteCs from 'Components/Autocomplete';
import { USER_ROLES } from 'utils/constants';

export default function CreateUserForm({ 
    formState, 
    handleInputChange, 
    handleChangeCustomer, 
    companyList, 
    customers,
    roleList,
    userDetails,
    userNameDisable,
    emailDisable,
    projectList,
    isOnbaording
}) {
    const classes = useStyles();
    
    return (
        <div>
            <Grid container spacing={8}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        First Name *
                    </Typography>
                    <Input
                        disableUnderline
                        value={formState.firstName}
                        type={'text'}
                        name="firstName"
                        className={classes.inputStyle}
                        placeholder={"First Name"}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Surname *
                    </Typography>
                    <Input 
                        disableUnderline 
                        value={formState.lastName}
                        type={'text'}
                        className={classes.inputStyle}
                        placeholder={"Last Name"}
                        style={{ width: '100%' }}
                        name="lastName"
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Username *
                    </Typography>
                    <Input 
                        disableUnderline 
                        value={formState.userName}
                        type={'text'}
                        placeholder={"Username"}
                        className={classes.inputStyle}
                        style={{ width: '100%' }}
                        disabled={userNameDisable ? true : false}
                        color={'secondary'}
                        name="userName"
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Email *
                    </Typography>
                    <Input
                        disableUnderline
                        value={formState.email}
                        type={'text'}
                        className={classes.inputStyle}
                        placeholder={"Email"}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        name="email"
                        disabled={emailDisable ? true : false}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Company Name *
                    </Typography>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        value={customers}
                        name="customers"
                        options={companyList}
                        getOptionLabel={option => option.name}
                        onChange={(event, newValue) => {
                            handleChangeCustomer(newValue, 'customers');
                        }}
                        limitTags={2}
                        style={{ width: '100%' }}
                        renderTags={(value, getTagProps) => value.map((option, index) => (
                            <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                        ))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select Company"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true
                                }}
                            />
                        )}
                    />
                </Grid>
                {roleList && roleList.length &&
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            Role *
                        </Typography>
                        <OutlinedSelect
                            val={formState.role}
                            name="role"
                            placeholder='Select Role'
                            variant="standard"
                            handleChange={handleInputChange}
                            styleOverrides={{ width: '100%', marginTop: 0 }}
                            options={roleList}
                        />
                    </Grid>
                }
                {!(roleList && roleList.length) &&
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            Location *
                        </Typography>
                        <Input 
                            disableUnderline 
                            value={formState.location}
                            type={'text'}
                            placeholder={"Location"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            className={classes.inputStyle}
                            name="location"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
            </Grid>
            <Grid container spacing={8}>
                <>
                    {roleList && roleList.length && ['superadmin', 'customeradmin', 'projectadmin'].includes(formState.role) &&
                        ['superadmin', 'customeradmin'].includes(userDetails?.role) && <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Secondary Role *
                            </Typography>
                            <OutlinedSelect
                                val={formState.sub_role}
                                name="sub_role"
                                placeholder='Select Role'
                                variant="standard"
                                handleChange={(evt) => handleInputChange(evt)}
                                styleOverrides={{ width: '100%', marginTop: 0 }}
                                options={[{ id: 'gcologist', name: 'GCologist' }]}
                            />
                        </Grid>}
                </>
                {roleList && roleList.length &&
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            Location *
                        </Typography>
                        <Input 
                            disableUnderline 
                            value={formState.location}
                            type={'text'}
                            placeholder={"Location"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            className={classes.inputStyle}
                            name="location"
                            onChange={handleInputChange}
                        />
                    </Grid>
                }
                {(userDetails?.role === USER_ROLES.SUPER_ADMIN || userDetails?.role === USER_ROLES.CUSTOMER_ADMIN) && projectList && !isOnbaording &&
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            Project *
                        </Typography>
                        <AutocompleteCs
                            styleOverrides={{ width: "100%" }}
                            inputStyle={{ border: '1px solid rgba(34,36,38,.15)', borderRadius: '25px' }}
                            handleChange={(evt, newVal) => handleChangeCustomer(newVal, 'project')}
                            val={formState.project}
                            options={projectList || []}
                            loading={false}
                            placeholder={"Project"}
                            size="small"
                            multiple={false}
                            onInputChange={evt => { }}
                        />
                    </Grid>
                }
            </Grid>
        </div>
    );
}