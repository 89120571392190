import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { checkValidEmail, notify } from '../../utils';
import { ERROR_MESSAGE } from '../../utils/constants';
import { CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { authService } from 'Services/AuthService';

const useStyles = makeStyles(theme => ({
    loginBox: {
        marginTop: "5em",
        textAlign: "center",
        background: "#fff",
        padding: 54,
        minHeight: 595,
        borderRadius: 5
    },
    formBox: {
        width: "100%",
        marginTop: 30,
        textAlign: "left"
    },
    formLabel: {
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "26px",
        letterSpacing: "1.3px",
        textTransform: "uppercase",
        color: "#b1b1b1"
    },
    customTextField: {
        paddingTop: 15,
        paddingBottom: 15 
    },
    input: {
        border: "1px solid #F0F1F7",
        borderRadius: 5,
        backgroundColor: "#FAFCFF",
        padding: 12
    },
    loginBtn: {
        padding: 18,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 5
    },
    eyeIconBtn: {
        position: "absolute",
        right: 10,
        top: 22,
        width: 25,
        height: 25,
        borderRadius: 15
    }
}));

const ForgotPassword = () => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        const formData = {
            username: data.get('email'),
        };
        if (!formData.username) {
            notify("error", ERROR_MESSAGE.REQUIRED_LOGIN);
            return null;
        }
        if (!checkValidEmail(formData.username)) {
            notify("error", ERROR_MESSAGE.VALID_EMAIL);
            return null;
        }
        const res = await authService.forgotPassword(formData.username);
        console.log("resL ", res);
        if (res && res.status) {
            notify("success", res.message || "Email send successfully!");
        } else {
            notify("error", res.message || "Something went wrong");
        }
        setLoading(false);
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box className={classes.loginBox}>
                <Typography style={{ lineHeight: 2.5 }} variant="h1">
                    Forgot Password?
                </Typography>
                <Typography variant="subtitle1">
                    An email will be sent to you with your new password.
                </Typography>
                <Box component="form" onSubmit={handleSubmit} className={classes.formBox} >
                    <FormLabel className={classes.formLabel}>Email</FormLabel>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="email"
                        placeholder="Email"
                        name="email"
                        inputProps={{ className: classes.input }}
                        className={classes.customTextField}
                        style={{ marginBottom: 24 }}
                        autoFocus
                    />
                    <Grid container style={{ marginTop: 28 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.loginBtn}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={20} /> : "Continue"}
                        </Button>
                        <Grid item xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                            {/* <Typography component="span" variant="subtitle1">Don't have an account? </Typography> */}
                            <Link href="/login" variant="body2">Sign In</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default withRouter(ForgotPassword);