import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { notify } from '../../utils';
import Input from '@material-ui/core/Input';
import { REQUIRED_ERROR } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paths } from '../../Routes/routePaths'
import { useHistory, useParams } from "react-router-dom";
import { CoursesServices } from '../../Services/CoursesServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedSelect from 'Components/OutlinedSelect';
import variables from '../../Sys/variable.scss';
import commonStyles from 'theme/commonStyles';

const coursesServices = new CoursesServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    }
}));

const courseTypes = ['DEV', 'CUS']

const initFormState = { name: "", course_id: "", link: "", provider: "", description: "", course_type: "" };

const AddCourses = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();

    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [formState, setFormState] = React.useState({ ...initFormState });

    React.useEffect(() => {
        if (id) {
            getCourseDetail()
        }
    }, [])

    const getCourseDetail = async () => {
        if (id) {
            let data = await coursesServices.getCourseDetail(id)
            if (data && data.data[0]) {
                let formData = data.data[0]
                setUpdate(true)
                setFormState({
                    name: formData.name, course_id: formData.course_id,
                    link: formData.link, provider: formData.provider, description: formData.description,
                    course_type: formData.course_type
                });
            }
        }
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const handleChange = (value, name) => {
        setFormState({ ...formState, [name]: value });
    }

    const formValidationCheck = () => {
        if (!formState.name || !formState.provider) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        return handleFormSubmit();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const payload = {...formState};
        const result = update ? await coursesServices.addCourses(payload, id) : await coursesServices.addCourses(payload)
        if (result && result.status) {
            notify("success", result.message)
            history.push(Paths.Courses);
            setLoading(false);
        } else {
            setLoading(false);
            notify("error", (result && result.message) ? result.message : 'Something Went Wrong');
        }
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <div className={classes.pageHeader}>
                        <Typography
                            variant="subtitle2"
                            className={classes.heading}
                            color="textPrimary">
                            {update ? "Update Course" : 'Create Course'}
                        </Typography>
                    </div>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Name *
                            </Typography>
                            <Input disableUnderline value={formState.name}
                                type={'text'}
                                name="name"
                                className={classes.inputStyle}
                                placeholder={"Name"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Course ID
                            </Typography>
                            <Input disableUnderline value={formState.course_id}
                                type={'text'}
                                disabled={true}
                                className={classes.inputStyle}
                                placeholder={formState.course_id !== "" ? formState.course_id : "********"}
                                style={{ width: '100%' }}
                                name="course_id"
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Link
                            </Typography>
                            <Input disableUnderline value={formState.link}
                                type={'text'}
                                placeholder={"Link"}
                                className={classes.inputStyle}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="link"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Provider *
                            </Typography>
                            <Input disableUnderline value={formState.provider}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Provider"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="provider"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Course Type
                            </Typography>
                            <OutlinedSelect
                                val={formState.course_type}
                                name="course_type"
                                placeholder='Select Course Type'
                                variant="standard"
                                handleChange={(evt) => handleChange(evt.target.value, 'course_type')}
                                styleOverrides={{ width: '100%', marginTop: 0 }}
                                options={courseTypes}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Description
                            </Typography>
                            <Input disableUnderline value={formState.description}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Description"}
                                style={{ width: '100%' }}
                                multiline={true}
                                rows={3}
                                color={'secondary'}
                                name="description"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.placeBtn}>
                        <Button 
                        onClick={() => history.push(Paths.Courses)} 
                        color="primary" 
                        className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button 
                        color="primary" 
                        variant="outlined"
                        className={classes.btn}
                        onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={15} color="secondary" /> : update ? 'Update' : 'Save'}
                        </Button>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default AddCourses;