import Color from 'color';
import { isObject, isString, isArray, isFunction } from './index';
import { fromJS } from './index';
import dateformat from 'dateformat';
import {
  startOfWeek,
  startOfDay,
  addHours,
  addWeeks,
  setHours
} from 'date-fns';
export const rangeWeek = (day, format='yyyy-mm-dd') => {
  const sow = startOfWeek(day, {weekStartsOn: 0})
  return [sow, addWeeks(sow,1)].map(v => dateformat(v, format))
}
// import { window } from 'globals/index';
export function template(strings, ...keys) {
  return (function(...values) {
    let dict = values[values.length - 1] || {};
    let result = [strings[0]];
    keys.forEach(function(key, i) {
      let value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  });
}
export const tmpl = (str, regex) => data =>
str.replace(regex, (match, code) => data[code] || '');
export const applyAsync = (acc, val) => acc.then(val);
export const composeAsync = (...funcs) => x =>
  funcs.reduce(applyAsync, Promise.resolve(x));
export const INTERPOLATE01 = /{([\s\S]+?)}/g,
  INTERPOLATE02 = /<%=\s+([\s\S]+?)\s%>/g,
  forIn = (obj, fn, thisArg) => {
    for (var key in obj) {
      if (fn.call(thisArg, obj[key], key, obj) === false) {
        break;
      }
    }
  },
  paramRegex = {
    required: /\{(\$*[A-z0-9]+)\}/gi,
    optional: /\{\/(\$*[A-z0-9]+)\}/gi
  }
export const timeText = ({ x, y, time_slot }) => {
  const hr = 6 + y / 2.0,
    mod = hr % 1 > 0;
  return !time_slot || isNaN(time_slot)
    ? `${hr < 10 ? '0' : ''}${hr}:${mod ? '30' : '00'}`
    : dateformat(time_slot, 'HH:MM');
};
export const URL = window.URL || window.webkitURL,
  loadBlob = blob => URL.createObjectURL(blob);

export const fn_color = (fn, col, v) =>
    Color[fn].apply(Color(col, 'rgb'), v ? [v] : []),
  alpha = (col, v = 0.95) => Color(col, 'rgb').alpha(v).string(),
  darken = (col, v = 0.95) => Color(col, 'rgb').darken(v).string(),
  lighten = (col, v = 0.95) => Color(col, 'rgb').lighten(v).string(),
  lightness = (col, v = 0.95) => Color(col, 'rgb').lightness(v).string(),
  blacken = (col, v = 0.95) => Color(col, 'rgb').blacken(v).string(),
  whiten = (col, v = 0.95) => Color(col, 'rgb').whiten(v).string(),
  invert = col => Color(col, 'rgb').negate().string(),
  extractTheme = (theme, defaults = {}) => {
    const themeBasis = {
        ...defaults,
        ...(theme && theme.colors),
        ...(theme && theme.global)
      },
      keys = Object.keys(defaults);
    let tmp =
      keys.length > 0
        ? keys.reduce(
            (a, k) => ({
              ...a,
              [k]: themeBasis[k]
            }),
            {}
          )
        : themeBasis;
    return {
      ...tmp,
      fn: {
        fn_color,
        alpha,
        darken,
        lighten,
        blacken,
        whiten,
        invert,
        lightness
      }
    };
  };
//eslint-disable-next-line no-useless-escape
const tmplRegex = /\{%([\S_\.]+?)%\}/gi;
const tget = state => (key, _def = {}) => fromJS(state).getIn(key.split('.'));
export const templ = str => state => {
  if (!state) {
    return str;
  }
  const src = tget(state);
  return str.replace(tmplRegex, (_match, code) => src(code) || '');
};
export const prettyPrint = (...args) =>
  console.log(JSON.stringify(args, null, 2));
export const mapTemplate = (state, thisArg, key, obj) => {
  if (isObject(obj)) {
    Object.entries(obj).forEach(([k, nobj]) =>
      mapTemplate(state, obj, k, nobj)
    );
  } else {
    Object.defineProperty(thisArg, key, {
      enumerable: true,
      configurable: true,
      get: () =>
        isString(obj) && tmplRegex.test(obj) ? templ(obj)(state) : obj,
      set: v => (thisArg[key] = v)
    });
  }
};
export const isN = v => (isNaN(v) ? false : !Number.isFinite(v));
export const isFunc = (reducer, type) => isFunction(type ? reducer[type] : reducer);
export const isDate = d =>
  isN(d)
    ? true
    : isString(d)
    ? isN(Date.parse(d))
    : isObject(d) && isFunc(d.toISOString);
export const isNullOrUndefined = v => v === null || v === undefined;
export const ensureNumber = val => (isNaN(Number(val)) ? 0 : Number(val));
export const rangeInc = (init = 0, length = 0, inc = 1) =>
  Array(length)
    .fill(0)
    .reduce((a, c, i) => [...a, i * inc + init], []);
export const units = ['px', 'em', '%', 'ch', 'vw', 'vh', 'rem'],
  unitMatch = new RegExp(`([\\d.\\-+]+)\\s*(${units.join('|')})$`, 'gi');
export const measure = s => {
  s = s.toString();
  let iter = s.matchAll(unitMatch);
  var { value } = iter.next();
  if (value !== undefined) {
    let [scalar, unit] = value.slice(1);
    return [ensureNumber(scalar), unit];
  }

  let r = /([\D]+)$/g;
  let m = (s.match(r) || [' ']).pop();
  let scalar = s.replace(m, '');
  return [ensureNumber(scalar), m];
};
export const mixShadows = ({
  umbra = 'var(--umbra, rgba(0,0,0,0.3))',
  penumbra = 'var(--penumbra, rgba(0,0,0,0.14))',
  ambient = 'var(--ambient, rgba(0,0,0,0.12))'
}) => [
  'none', // umbra: rgba(0,0,0, 0.2), penumbra: rgba(0,0,0, 0.14), ambient: rgba(0,0,0, 0.12)
  `0px 2px 1px -1px ${umbra},0px 1px 1px 0px ${penumbra},0px 1px 3px 0px ${ambient}`,
  `0px 3px 1px -2px ${umbra},0px 2px 2px 0px ${penumbra},0px 1px 5px 0px ${ambient}`,
  `0px 3px 3px -2px ${umbra},0px 3px 4px 0px ${penumbra},0px 1px 8px 0px ${ambient}`,
  `0px 2px 4px -1px ${umbra},0px 4px 5px 0px ${penumbra},0px 1px 10px 0px ${ambient}`,
  `0px 3px 5px -1px ${umbra},0px 5px 8px 0px ${penumbra},0px 1px 14px 0px ${ambient}`,
  `0px 3px 5px -1px ${umbra},0px 6px 10px 0px ${penumbra},0px 1px 18px 0px ${ambient}`,
  `0px 4px 5px -2px ${umbra},0px 7px 10px 1px ${penumbra},0px 2px 16px 1px ${ambient}`,
  `0px 5px 5px -3px ${umbra},0px 8px 10px 1px ${penumbra},0px 3px 14px 2px ${ambient}`,
  `0px 5px 6px -3px ${umbra},0px 9px 12px 1px ${penumbra},0px 3px 16px 2px ${ambient}`,
  `0px 6px 6px -3px ${umbra},0px 10px 14px 1px ${penumbra},0px 4px 18px 3px ${ambient}`,
  `0px 6px 7px -4px ${umbra},0px 11px 15px 1px ${penumbra},0px 4px 20px 3px ${ambient}`,
  `0px 7px 8px -4px ${umbra},0px 12px 17px 2px ${penumbra},0px 5px 22px 4px ${ambient}`,
  `0px 7px 8px -4px ${umbra},0px 13px 19px 2px ${penumbra},0px 5px 24px 4px ${ambient}`,
  `0px 7px 9px -4px ${umbra},0px 14px 21px 2px ${penumbra},0px 5px 26px 4px ${ambient}`,
  `0px 8px 9px -5px ${umbra},0px 15px 22px 2px ${penumbra},0px 6px 28px 5px ${ambient}`,
  `0px 8px 10px -5px ${umbra},0px 16px 24px 2px ${penumbra},0px 6px 30px 5px ${ambient}`,
  `0px 8px 11px -5px ${umbra},0px 17px 26px 2px ${penumbra},0px 6px 32px 5px ${ambient}`,
  `0px 9px 11px -5px ${umbra},0px 18px 28px 2px ${penumbra},0px 7px 34px 6px ${ambient}`,
  `0px 9px 12px -6px ${umbra},0px 19px 29px 2px ${penumbra},0px 7px 36px 6px ${ambient}`,
  `0px 10px 13px -6px ${umbra},0px 20px 31px 3px ${penumbra},0px 8px 38px 7px ${ambient}`,
  `0px 10px 13px -6px ${umbra},0px 21px 33px 3px ${penumbra},0px 8px 40px 7px ${ambient}`,
  `0px 10px 14px -6px ${umbra},0px 22px 35px 3px ${penumbra},0px 8px 42px 7px ${ambient}`,
  `0px 11px 14px -7px ${umbra},0px 23px 36px 3px ${penumbra},0px 9px 44px 8px ${ambient}`,
  `0px 11px 15px -7px ${umbra},0px 24px 38px 3px ${penumbra},0px 9px 46px 8px ${ambient}`
];
const defTheme = theme => (basis = null) =>
  basis ? JSON.parse(templ(theme)(basis)) : theme;

export class ThemeWrapper {
  constructor(input) {
    const { theme, basis } = input;
    // Object.defineProperty()
    this.theme = defTheme(JSON.stringify(theme));
    this.basis = basis;
  }
  uiTheme = type => {
    return fromJS(this.theme(this.basis)).merge({
      type,
      shadows: mixShadows(this.basis.shadowColor)
    });
  };
}
export const uiTheme = (type, { basis, theme }) => {
  // console.log(basis, type);
  let th = defTheme(JSON.stringify(theme));
  return fromJS(th(basis)).merge({
    type,
    shadows: mixShadows(basis.shadowColor)
  });
};
export const mapOptions = key => ({
  key,
  text: `${key}`,
  value: `${key}`
});
export const uniqueArray = keys =>
  Object.keys(keys.reduce((a, c) => ({ ...a, [c]: '' }), {}));
export const fromJSI = v => fromJS(v, { prototype: ThemeWrapper.prototype });
export const noop = v => v,
  compose = (...funcs) =>
    funcs.length > 1
      ? funcs.reduce((a, b) => (...args) => a(b(...args)))
      : funcs.length === 1
      ? funcs[0]
      : noop,
  pipe = (...funcs) =>
    funcs.length > 1
      ? funcs.reduceRight((a, b) => (...args) => a(b(...args)))
      : funcs.length === 1
      ? funcs[0]
      : noop,
  ensureArray = k => (isArray(k) ? k : [k]),
  getMsgID = (prefix = 'MSG', base = 36, sub = 3, cnj = '_') =>
    [prefix, Math.random().toString(base).substring(sub)].join(cnj),
  ensureDate = d =>
    isDate(d) && !isObject(d) ? new Date(d) : isDate(d) ? d : new Date(),
  date_toJson = d => ensureDate(d).toISOString().replace('T', ' '),
  json_to_timenumber = time_slot =>
    isString(time_slot) ? Number(Date.parse(time_slot)) : Number(time_slot),
  array_fill = l =>
    Array(l)
      .fill(0)
      .map((_c, i) => i),
  messageFromError = ({
    isFetch = true,
    withData = false,
    defaultMessage = 'Unknown Error has occurred. Please contact Administrator.'
  }) =>
    isFetch
      ? error => {
          const message =
            error.message ||
            (error.data && error.data.message) ||
            defaultMessage;
          return withData ? { data: error.data || {}, message } : message;
        }
      : error => {
          const data = error.response && error.response.data;
          const message = (data && data.message) || defaultMessage;
          return withData
            ? { data: (data && data.data) || {}, message }
            : message;
        };
export let extendStatics = function (d, b) {
  extendStatics =
    Object.setPrototypeOf ||
    ({ __proto__: [] } instanceof Array &&
      function (d, b) {
        d.__proto__ = b;
      }) ||
    function (d, b) {
      for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
  return extendStatics(d, b);
};
export const debounce = (func, wait, immediate) => {
  var timeout;
  return (...args) => {
    const context = this,
      later = () => {
        timeout = null;
        return !immediate && func.apply(context, args);
      },
      callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
export const keyWeek = key => ({ appointment, ...rest }) => ({
  selected: true,
  isSelected: true,
  isAvailable: isNullOrUndefined(appointment),
  weekKey: Number.isNaN(rest[key]) ?'' :dateformat(rest[key], 'yyyyW'),
  ...rest,
  appointment,
  [key]: json_to_timenumber(rest[key])
  // isThisWeek: (ref_key) =>
});
export const makeDate = d => (isDate(d) ? d : !!d && new Date(d));
export const dow = (d, sow = 0) => {
    let v = (d.getDay() % 7) - sow;
    return v < 0 ? 7 + sow + v : v;
  },
  duration = (e, s) => {
    const _duration = isNullOrUndefined(s) ? e : e - s;
    // const t = {
    //   min: 60
    // }
    const Dur = {
      get weeks() {
        return _duration / (7 * 24 * 3600000);
      },
      get days() {
        return _duration / (24 * 3600000);
      },
      get hours() {
        return _duration / 3600000;
      },
      get minutes() {
        return _duration / 60000;
      },
      get seconds() {
        return _duration / 1000;
      },
      get millis() {
        return _duration;
      }
    };
    return Dur;
  };
export const gridArea = ({ start_date, end_date }) => {
  const s = new Date(start_date);
  const e = new Date(end_date);
  const hh = 1800000;
  const dif = (e - s) / hh,
    gs = ((s - setHours(startOfDay(s),6)) / hh)+1;
    const ge = gs + dif

  return { gridColumn: dow(s) + 1, gridRow: `${gs}/${ge}` };
};

export const mapDate = keys => data =>
  keys.reduce((acc, key) => ({ ...acc, [key]: makeDate(acc[key]) }), data);
export const aptsToProps = ({status, rating, ...props}) => {
  const e = new Date() - addHours(new Date(props.end_date), 2)
  
  return {
    derived: keyWeek('start_date')({
      start_date: startOfWeek(new Date(props.start_date), {weekStartsOn: 0}),
      appointment: props.uuid
    }),
    ...props,
    status: '01'.includes(status) && e > 0 ? rating !== '0' ? '5' : '4': status,
    allowRating: (('01'.includes(status) && e > 0 ) || status === '4' ) && rating === '0',
    rating
  };
};
// const fetchErrMsg=messageFromError({}),  axiosErrMsg=messageFromError({isFetch:false});
export const generateSelection = ({ rows = 24, cols = 7 }) => {
    return array_fill(rows).reduce(
      (acc, _d, y) => [
        ...acc,
        ...array_fill(cols).map((_h, x) => ({
          x,
          y,
          id: `${x}-${y}`,
          uuid: null,
          appointment: null,
          selected: false,
          isSelecting: false,
          isSelected: false
        }))
      ],
      []
    );
  },
  weekKey = ({ time_slot, appointment, ...rest }) => ({
    selected: true,
    isSelected: true,
    isAvailable: isNullOrUndefined(appointment),
    appointment,
    weekKey: dateformat(time_slot, 'yyyyW'),
    time_slot: json_to_timenumber(time_slot),
    ...rest
  });
// const timeText = ({ x, y, time_slot }) => {
//   const hr = 6 + y / 2.0,
//     mod = hr % 1 > 0;
//   return !time_slot || isNaN(time_slot)
//     ? `${hr < 10 ? '0' : ''}${hr}:${mod ? '30' : '00'}`
//     : dateformat(time_slot, 'HH:MM');
// };
export const lazyProxy = props =>
new Proxy(props, {
  defineProperty: function(target, name, propertyDescriptor) {
    return Object.defineProperty(target, name, {
      enumerable: true,
      configurable: true,
      get: () => target[name] || '',
      set: value => (target[name] = value)
    });
  }
}),

chunk = (array, chunkSize = 2) =>
Math.ceil(array.length / chunkSize).map((v, i) =>
  array.slice(i, i + chunkSize)
),

filter = (obj, callback) => {
if (Array.isArray(obj)) {
  return obj.filter(callback);
}
return Object.entries(obj)
  .filter(([name, value]) => callback(value, name, obj))
  .reduce(
    (acc, [name, value]) => ({
      ...acc,
      [name]: value
    }),
    {}
  );
},
isInteger = v => Number.isInteger(v),
// parseInt = (v, r = 10) => parseInt(v, r),
longer = (a, b) => (a.length > b.length ? a : b),
zipObject = (keys, values) =>
keys.reduce(
  (a, k, i) => ({
    ...a,
    ...{
      [k]: values[i]
    }
  }),
  {}
),
zip = (targ, src, d = 0) =>
longer(targ, src).reduce(
  (a, c, i) => [...a, targ[i] || d, src[i] || d],
  []
);
