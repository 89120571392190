import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress } from '@material-ui/core';

function ConfirmationModal(props) {

  const { size, title, handleClose, open, handleConfirm, loading, children, ...other } = props;
  
  return (
    <Dialog
      maxWidth={size || "xs"}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      fullWidth
      style={{borderRadius: 10}}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Button style={{ borderRadius: 8, marginRight: 10, minWidth: 100 }} variant="outlined" onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button disabled={loading} style={{ borderRadius: 8, minWidth: 100 }} onClick={handleConfirm} variant="outlined" color="primary">
          {loading ? <CircularProgress color="inherit" size={15} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ConfirmationModal;
