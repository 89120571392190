import React from 'react';
import { Typography, Input } from '@material-ui/core/';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import variables from '../../Sys/variable.scss';

const useStyles = makeStyles(() => ({
    labelStyle: { 
        marginBottom: '20px', 
        marginTop: '15px',
        color: variables.labelcolor
    },
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
}));

const OutelinedInput = ({
  value,
  type,
  name,
  label,
  handleChange,
  placeholder,
  styleOverrides,
  multiline = false,
  disabled = false,
  endAdornment = null
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
        <Typography
            variant="subtitle2"
            color="textPrimary"
            className={classes.labelStyle}>
            {label}
        </Typography>
        <Input
            disableUnderline
            value={value}
            type={type || 'text'}
            rows={multiline ? 4 : null}
            multiline={multiline}
            disabled={disabled}
            inputProps={{disabled: disabled}}
            name={name}
            className={classes.inputStyle}
            placeholder={placeholder}
            style={styleOverrides || { width: '100%' }}
            color={'secondary'}
            onChange={handleChange}
            endAdornment={endAdornment || null}
        />
    </React.Fragment>
  );
};

OutelinedInput.prototypes = {
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  styleOverrides: PropTypes.object,
  disabled: PropTypes.bool,
};

export default OutelinedInput;