let user = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
let token = user ? user.token : "";
let SSOToken = user ? user['IDP:SSOToken'] : "";

export const initialState = {
  userDetails: '' || user,
  token: '' || token,
  SSOToken: "" || SSOToken,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...state,
        loading: true
      };
    case "LOGIN_SUCCESS": {
      return {
        ...state,
        userDetails: action.payload,
        token: action.payload.token,
        SSOToken: action.payload["IDP:SSOToken"],
        loading: false
      };
    }
    case "LOGOUT":
      return {
        ...state,
        SSOToken: "",
        userDetails: "",
        loading: false,
        token: ""
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        SSOToken: "",
        userDetails: "",
        token: "",
        loading: false,
        errorMessage: action.error
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};