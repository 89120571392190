import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { checkValidEmail, notify } from '../../utils';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paths } from '../../Routes/routePaths'
import { UserServices } from '../../Services/UserServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserBulkUpload from './components/userBulkUpload';
import { ProjectServices } from '../../Services/ProjectServices';
import { useAuthDispatch, useAuthState } from '../../Context';
import { USER_ROLES, REQUIRED_ERROR } from '../../utils/constants';
import CreateUserForm from './components/CreateUserForm';
import { useHistory } from 'react-router';
import variables from '../../Sys/variable.scss';
import commonStyles from 'theme/commonStyles';

const userServices = new UserServices();
const projectService = new ProjectServices();

export const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    }
}));

const initFormState = { 
    firstName: '', 
    lastName: '', 
    userName: '', 
    email: '', 
    location: '', 
    role: '', 
    sub_role: '', 
    project: null 
};

const AddUsers = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    
    const dispatch = useAuthDispatch();
    const { userDetails } = useAuthState(dispatch);
    const history = useHistory();

    const [loading, setLoading] = React.useState(false);
    const [openBulkUploadDialog, setOpenBulkUploadDialog] = React.useState(false);
    const [companyList, setCompanyList] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [formState, setFormState] = React.useState({ ...initFormState });
    const [roleList, setRoleList] = React.useState([]);
    const [projectList, setProjectList] = React.useState([])

    React.useEffect(() => {
        getCustomerList();
    }, []);

    const getCustomerList = async () => {
        const cusResult = await projectService.getCustomerList();
        if (cusResult && cusResult.data) {
            setCompanyList(cusResult.data);
        }
    }

    const getProjectList = async (csId) => {
        const projectRes = await projectService.allProjectsByCustomer(csId);
        if (projectRes?.status) {
            const allProject = projectRes?.results?.map(item => {
                return {
                    name: item.project_name,
                    id: item.project_uuid
                }
            }) || [];
            setProjectList(allProject);
        }
    };

    React.useEffect(() => {
        const { role } = userDetails;
        let roles = [];
        if (role === USER_ROLES.SUPER_ADMIN) {
            roles = [{
                id: 'customeradmin',
                name: 'Customer Admin'
            },
            {
                id: 'csmadmin',
                name: 'CSM'
            }];
        }
        if (['customeradmin', 'superadmin'].includes(role)) {
            roles.push({
                id: 'projectadmin',
                name: 'Project Admin'
            });
            roles.push({ id: 'gcologist', name: 'GCologist' });
        }
        roles.push({
            id: 'user',
            name: 'User'
        });
        setRoleList(roles);
    }, [userDetails]);

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const formValidationCheck = () => {
        if (!formState.firstName || !formState.lastName || !formState.userName || !formState.email || !formState.location || customers.length === 0) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        if (!checkValidEmail(formState.email)) {
            notify("error", "Please enter valid Email ID");
            return null;
        }
        return handleFormSubmit();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        let customersIds = customers.map(cs => cs.uuid);
        const payload = { ...formState, is_active: true, customers: customersIds, project_id: formState.project ? formState.project.id : '' };
        if(userDetails?.role === USER_ROLES.PROJECT_ADMIN || userDetails?.role === USER_ROLES.CSM_ADMIN){
            let userProfile = await userServices.getDetailMyProfile();
            if(userProfile?.data?.length){
                let userData = userProfile?.data[0];
                let selectedProj = projectList.find(itm => itm.id === userData.project_id);
                payload.project = selectedProj || null;
                payload.project_id = selectedProj ? userData.project_id : null;
            }
        }
        const result = await userServices.addUser(payload);
        if (result && result.status) {
            notify("success", result.message)
            setLoading(false);
            history.push(Paths.Users);
        } else {
            setLoading(false);
            notify("error", (result && result.message) ? result.message : 'Something Went Wrong');
        }
    }

    const handleChangeCustomer = (value, type) => {
        if(type === 'project'){
            setFormState({...formState, project: value});
        }else{
            setCustomers(value);
            if(value && value[0]){
                return getProjectList(value[0]?.uuid);
            }
            setProjectList([]);
        }
    }

    const handleBulkUploadDialog = () => {
        setOpenBulkUploadDialog(true)
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <div className={classes.pageHeader}>
                        <Typography
                            variant="subtitle2"
                            className={classes.heading}
                            color="textPrimary">
                            Create User
                        </Typography>
                        <Button color="primary" variant="outlined" className={classes.btn} onClick={handleBulkUploadDialog}>
                            Bulk upload
                        </Button>
                    </div>
                    <CreateUserForm 
                        projectList={projectList}
                        formState={formState} 
                        handleChangeCustomer={handleChangeCustomer} 
                        handleInputChange={handleInputChange} 
                        companyList={companyList}
                        roleList={roleList}
                        userDetails={userDetails}
                        customers={customers}
                    />
                    <div className={classes.placeBtn}>
                        <Button onClick={() => history.push(Paths.Users)} color="primary" className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className={classes.btn}
                            variant="outlined"
                            disabled={
                                !formState.firstName ||
                                !formState.lastName ||
                                !formState.userName ||
                                !formState.email ||
                                !formState.role ||
                                !formState.location
                            }
                            onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={15} color="secondary" /> : 'Save'}
                        </Button>
                    </div>
                    <UserBulkUpload
                        open={openBulkUploadDialog}
                        handleClose={() => setOpenBulkUploadDialog(false)}
                    />
                </div>
            </main>
        </Layout>
    );
}

export default AddUsers;