import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from "../../../Components/layouts";
import aiImg from '../../../Assets/images/ai.png';
import usersImg from '../../../Assets/images/users.png';
import compProjImg from '../../../Assets/images/complete-projects.png';
import BarChartWrapper from '../../../Components/Charts/BarChartWrapper';
import ChartContainer from '../../../Components/Charts/ChartContainer';
import { useAuthDispatch, useAuthState } from '../../../Context';
import { USER_ROLES } from '../../../utils/constants';
import DynamicCard from '../components/DynamicCard'
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress } from '@material-ui/core';
import { BorderLinearProgress, BorderLinearProgressComplete } from 'Pages/UserAdmin/components/DynamicCard';
import { makeStyles } from '@material-ui/core/styles';
import { calculatePercentage } from '../SkillTransformation/RegisteredCourses';
import { CoursesServices } from 'Services/CoursesServices';
import { handleSSOAuth } from 'Services/AuthService';
import ApprenticeshipImg from 'Assets/images/virtual-global-apprenticeship.png';
// import UpcommingUIBanner from 'Components/UpcommingUIBanner';

const skillsAndTransformation = new SkillsAndTransformation();
const courseServices = new CoursesServices();

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%'
    },
    cardTitle: {
        color: "#808080"
    },
    cardDesc: {
        color: "#808080",
    },
}));

const UserDashBoard = () => {
    
    const classes = useStyles();
    const dispatch = useAuthDispatch();
    const { userDetails } = useAuthState(dispatch);
    const [registerCourseList, setRegisterCourseList] = React.useState([]);
    const [userCourseGraph, setUserCourseGraph] = React.useState([]);
    const [courseLoading, setCourseLoading] = React.useState(false);
    const [banerOpen, setBanerOpen] = React.useState(false);

    React.useEffect(() => {
        // const query = new URLSearchParams(window.location.search);
        // const token = query.get('action');
        // if(token === 'under-maintenance'){
        //     setBanerOpen(true);
        // }
        setCourseLoading(true);
        getAllRegisterCourse();
        getCorseCompletionGraph();
    }, []);
    
    const getCorseCompletionGraph = async () => {
        const graphData = await skillsAndTransformation.getCourseCompletionGraph()
        if (graphData && graphData.results) {
            setUserCourseGraph(graphData.results.map(itm => {
                return {
                    name: itm.course_name,
                    count: itm.course_completion,
                }
            }));
        }
    }

    const getAllRegisterCourse = async () => {
        const courseList = await skillsAndTransformation.getRegisterCourse();
        if (courseList.status) {
            const courseArr = [];
            courseList?.data && courseList.data?.courses && courseList.data?.courses.forEach((cat) => {
                cat?.courses && cat.data.forEach((crs) => {
                    let { course } = crs;
                    let obj = {
                        title: course?.category || '',
                        subTitle: course?.course_name || '',
                        desc: course?.description || '',
                        image: aiImg,
                        total: 100,
                        value: course?.course_completion || '',
                        width: 12,
                        page: 'registeredCourse',
                        progress: true,
                        provider: course?.provider || '',
                        ...course
                    }
                    courseArr.push(obj);
                });
            });
            setRegisterCourseList(courseArr);
        }
        setCourseLoading(false);
    }

    const userCardData = [
        {
            title: 'Skills Progress',
            subTitle: 'Skills Transformation',
            image: usersImg,
            desc: "Usage",
            total: 100,
            value: 0,
            width: 6,
            page: 'dashboard',
            progress: true,
            link: "/skill-transformation/registered-courses",
            internalPage: true
        },
        {
            title: 'Assessments Progress',
            subTitle: 'Assessments',
            image: compProjImg,
            desc: "Usage",
            total: 100,
            value: 0,
            width: 6,
            page: 'dashboard',
            progress: true,
            link: "/assessments",
            internalPage: true
        },
        {
            title: 'Virtual Global Apprenticeship Progress',
            subTitle: 'Virtual Global Apprenticeship',
            image: ApprenticeshipImg,
            desc: "Usage",
            total: 100,
            value: 0,
            width: 12,
            page: 'dashboard',
            progress: true,
            link: "/skill-transformation/virtual-global-apprenticeship",
            internalPage: true
        }
    ];

    const handleClickRegCourseURL = (itm) => {
        const { link, token, course_id_talent_lms, course_id } = itm;
        if (course_id_talent_lms) {
            try {
                courseServices.getSessionLinkToCourse(course_id)
                    .then(({ goto_url }) => {
                        window.open(goto_url);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } catch (error) {
                // Send push notification
                console.error(error);
            }
        } else {
            console.log(link);
            handleSSOAuth(link, 'simplilearn');
        }
    };

    return (
        <Layout>
            <main>
                <Grid container spacing={2}>
                    {userCardData.map((item) => (
                        <DynamicCard key={item.title} item={item} />
                    ))}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ChartContainer
                            styleOveride={{ background: "#FFFFFF", borderRadius: 12, marginTop: 32, padding: 10 }}
                            title={userDetails.role === USER_ROLES.SUPER_ADMIN ? "Check Customer Growth" : "Check User Growth"}
                        >
                            <BarChartWrapper data={userCourseGraph} />
                        </ChartContainer>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: 32}}>
                {courseLoading ? <div style={{marginTop: 20, marginBottom: 10, textAlign: 'center', width: '100%'}}>
                        <CircularProgress />
                    </div> : registerCourseList.map(item => (
                        <Grid key={item.title} item xs={item.width}>
                            <Card className={classes.root} sx={{ display: "flex" }}>
                                <CardMedia
                                    className={classes.cover}
                                    image={item.image}
                                    title={item.title}
                                />
                                <CardContent className={classes.content} style={{ display: 'flex' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={9}>
                                            <Typography className={classes.cardTitle}>
                                                {item.title}
                                            </Typography>
                                            <Typography variant="h1" color="primary">
                                                {item.subTitle}
                                            </Typography>
                                            <Typography className={classes.cardDesc} title={item.desc}>
                                                {item.desc.length > 200 ? item.desc.substring(0,160) + '...' : item.desc}
                                            </Typography>
                                            <Typography variant="h1" color="primary">
                                                {item.totalCount}
                                            </Typography>

                                            {item.progress ? calculatePercentage(item.value, item.total) !== 100 ? <BorderLinearProgress variant="determinate" value={calculatePercentage(item.value, item.total)} /> :
                                                <BorderLinearProgressComplete variant="determinate" value={calculatePercentage(item.value, item.total)} /> : null}
                                                <Typography style={{ fontSize: 13, color: "#808080" }} color="primary">
                                                    {calculatePercentage(item.value, item.total) + "% " + "completed"}
                                                </Typography>
                                        </Grid>
                                        <Grid item xs={3} style={{ margin: 'auto' }}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleClickRegCourseURL(item)}
                                                style={{ width: '100%', padding: '15px 45px', borderRadius: 10 }}>
                                                {item.value > '0' ? (
                                                    "Continue learning"
                                                ) : (
                                                    "LAUNCH"
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
                </Grid>
                {/* <UpcommingUIBanner open={banerOpen} handleClose={evt => setBanerOpen(false)} /> */}
            </main>
        </Layout>
    );
}

export default UserDashBoard;