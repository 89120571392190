import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListItemText, Typography } from '@material-ui/core';
import variables from '../../../Sys/variable.scss';

const useStyles = makeStyles(() => ({
    menuItemText: {
        color: "#fff",
        '&.MuiTypography-body1':{
            color: "#fff"
        }
    },
    darkThemeColor: {
        color: variables.darkThemeText,
        '&.MuiTypography-body1':{
            color: variables.darkThemeText
        }
    }
}));

const MenuItems = ({ submenus, handleItemClick, location }) => {
    const classes = useStyles();
    return (
        <ul className="dropdown">
            {submenus.map((submenu, index) => {
                const isSelected = location.pathname.startsWith(submenu.active) || false;
                return (
                    <ListItem onClick={() => handleItemClick(submenu.active)} style={{ marginBottom: 10, ...(isSelected && {backgroundColor: variables.sidebarActive}) }}
                        selected={isSelected} button key={index} >
                        <ListItemIcon className={isSelected ? classes.menuItemText : classes.darkThemeColor }>{submenu.icon}</ListItemIcon>
                        <ListItemText primary={<Typography className={isSelected ? classes.menuItemText : classes.darkThemeColor }>{submenu.title}</Typography>} />
                    </ListItem>
                );
            })}
        </ul>
    );
}

export default MenuItems;