import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Input } from '@material-ui/core';
import { UserServices } from '../../../Services/UserServices';
import { CircularProgress } from '@material-ui/core';
import { notify } from '../../../utils';

const userServices = new UserServices();

export default function UserBulkUpload({ open, handleClose, uuid }) {

    const [isLoading, setIsLoading] = React.useState(false);

    const handleClickLink = async (evt) => {
        evt.preventDefault();
        setIsLoading(true);
        await userServices.getBulkTemplate(uuid).then(
            data => {
                console.log("data.data.url: ", data.data.url);
                const link = document.createElement('a');
                link.href = data.data.url;
                link.setAttribute('download', 'file.xls');
                document.body.appendChild(link);
                link.click();
                setIsLoading(false)
                notify('success', 'Sample Template downloaded successfully')
            },
            error => {
                setIsLoading(false)
                notify('error', 'Something went wrong')
                console.log('error.response.status', error);
            }
        );
    }

    const bulkUpload = async (data) => {
        setIsLoading(true)
        const param = {
            data: data
        };
        try {
            await userServices.bulkUpload(param, uuid).then(
                data => {
                    var failedUser = data.data.Failed;
                    if (failedUser.length > 0) {
                        setIsLoading(false)
                        notify('error', `Failed to upload ${data.data.Failed} Users`)
                    } else {
                        setIsLoading(false)
                        notify('success', data.message)
                    }
                    setIsLoading(false)
                    handleClose();
                },
                error => {
                    setIsLoading(false)
                    notify('error', 'something went wrong')
                    console.log('error.response.status', error);
                }
            );
        } catch (e) {
            setIsLoading(false);
            notify('error', 'Something went wrong!');
            handleClose();
        }
    };

    const handleUploadTemplate = event => {
        event.preventDefault();
        var file = event.target.files[0];
        var fileName = file.name.split('.');
        var eLearning = fileName[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = e => {
            var temp;
            if (e.target.result) {
                temp = e.target.result.split(',');
            }
            bulkUpload(temp[1]);
        };
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="id" style={{ textAlign: "center" }}>
                    <h2>Bulk Upload User</h2>
                </DialogTitle>
                <DialogContent>
                    <Grid container style={{ paddingBottom: 25 }}>
                        {isLoading ? <CircularProgress color='primary' size={20} /> : (
                            <>
                                <div style={{ padding: 10 }}>
                                    <a href="#" onClick={evt => handleClickLink(evt)} style={{ fontSize: 18, textDecoration: 'none' }}>
                                        <span>Click here for download Templete</span>
                                    </a>
                                </div>
                                <div style={{ padding: 10 }}>
                                    <Input
                                        disableUnderline
                                        id="file-input"
                                        type="file"
                                        name="BulkTemplate"
                                        accept=".xlsx"
                                        onChange={e => handleUploadTemplate(e)}
                                    />
                                </div>
                            </>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </div >
    );
}