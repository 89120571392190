import axios from 'axios';
import "../Sys/config.js";

var apiUrl = global.platformURI;


export class ReportServices {
    constructor() {
        axios.interceptors.request.use(function (config) {
            let token = localStorage.getItem('ssoToken');
            config.headers.Authorization = `Token ${token}`;
            config.baseURL = apiUrl;
            return config;
        }, error => {
            console.log('error.response.status', error);
            return error;
        });
    }

    getCompanyList(url) {
        return axios.get(url)
            .then(res => res.data);
    }
    getAllProjectReport(url) {
        return axios.get(url)
            .then(res => res.data);
    }
    getAllProjectReportUser(url) {
        return axios.get(url)
            .then(res => res.data);
    }
    getAllCompanyAdminReport(url) {
        return axios.get(url)
            .then(res => res.data);
    }
    getSelectedUserDetail(url, payload) {
        return axios.post(url, payload)
            .then(res => res.data);
    }
    getProjectListForUserDetail(id) {
        return axios.get(`project/userviewprojects/${id}`)
            .then(res => res.data);
    }
    getProjectReportData(url, payload) {
        return axios.post(url, payload)
            .then(res => res.data);
    }
    getDownloadCsvReportData(url, payload) {
        return axios.post(url, payload)
            .then(res => res.data);
    }
    getDrarePortaData(url, payload) {
        return axios.put(url, payload)
            .then(res => res.data);
    }
    getDraUserData(url, payload) {
        return axios.post(url, payload)
            .then(res => res.data);
    }

    getUserOverviewCount() {
        return axios.get('main/userdashboard')
        .then(res => res.data);
    }
}
