import qs from 'qs';
import {date_toJson} from './general';

export const makeReplacer =(include=false, exclude=[]) =>(name, val)=>{
  // convert RegExp to string
  if(Array.isArray(exclude)){
    if (exclude.includes(name)){
      return undefined
    }
  }
  if(include){
    if(Array.isArray(include)){
      if (include.includes(name)){
        return undefined
      }
    }
  }
    if ( val && val.constructor === RegExp ) {
        return val.toString();
    } else if (val && ( name.endsWith('_date') || val instanceof Date) ) { //   this && this[name] instanceof Date ||
        return date_toJson(new Date(val)); // remove from result
    } else {
        return val; // return as is
    }
  
};
const defaultReplacer = makeReplacer()

export const SafeJSON = {
    parse: (v, reviver = null) => {
      try {
        return JSON.parse(v, reviver);
      } catch (error) {
        return v;
      }
    },
    stringify: (v, replacer = defaultReplacer, ...args) => {
      try {
        return JSON.stringify(v, replacer, ...args);
      } catch (error) {
        return v;
      }
    }
  },
  qsDefaultOpts = {
    serializeDate: d => date_toJson(new Date(d)),
    sort: (a, b) => {
      return a - b;
    },
    skipNulls: true,
    arrayFormat: 'brackets'
  },
  SafeQS = {
    stringify: (v, options = {}) => {
      try {
        return qs.stringify(v, {...qsDefaultOpts, ...options});
      } catch (error) {
        return v;
      }
    },
    parse: (v,  options = {}) => {
      try {
        return qs.stringify(v, options);
      } catch (error) {
        return v;
      }
    }
  };