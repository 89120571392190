import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomerServices } from '../../../Services/CustomerServices';
import PhoneInputField from '../../../Components/PhoneInputField';
import Layout from "Components/layouts";
import { TextField, Button, CircularProgress } from '@material-ui/core';
import OutlinedSelect from 'Components/OutlinedSelect';
import { REQUIRED_ERROR } from 'utils/constants';
import { checkValidEmail, checkValidURL, notify } from 'utils';
import { Paths } from 'Routes/routePaths';
import { timezone } from 'Assets/timeZone';
import { useHistory } from 'react-router';
import variables from '../../../Sys/variable.scss';
import commonStyles from 'theme/commonStyles';
import SendTestEmailModal from 'Components/SendTestEmailModal';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const customerServices = new CustomerServices();

export const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    },
    btnPwd: {
        background: '#3D7DDD',
        color: 'white',
        borderRadius: '25px',
        padding: '18px',
        marginTop: '-3px',
        marginLeft: '-42px',
        "&:hover": {
            background: "#3D7DDD"
        }
    },
}));

export const accountList = [
    { id: 'enterprise_customer', name: 'Enterprise Customer' },
    { id: "strategy_partner", name: 'Strategy Partner' },
    { name: 'Reseller Partner', id: 'reseller_partner' }
];

export const initCustomerFormState = {
    name: '',
    address: '',
    country: '',
    email: '',
    domain: '',
    partner: '',
    branch: '',
    contact_no: '',
    logo: '',
    link: '',
    timezone: '',
    api_key: ''
};

const Customer = () => {

    const classes = useStyles();
    const history = useHistory();
    const commonClasses = commonStyles();

    const [countryList, setCountryList] = React.useState([])
    const [formState, setFormState] = React.useState({ ...initCustomerFormState });
    const [showSMTP, setShowSMTP] = React.useState(false);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [sendTestMailOpen, setSendTestMailOpen] = React.useState(false);
    const [sendTestEmailLoading, setSendTestEmailLoading] = React.useState(false);

    React.useEffect(() => {
        getCountryList();
    }, []);

    async function getCountryList() {
        const countryList = await customerServices.getCounties()
        setCountryList(countryList.country_list);
    }

    const handleInputChange = (e, field) => {
        if (field) {
            setFormState({ ...formState, [field]: e });
        } else {
            const { name, value } = e.target;
            setFormState({ ...formState, [name]: value });
        }
    }

    const handleChangeSMTP = (evt) => {
        setShowSMTP(evt.target.checked);
    }

    const formValidationCheck = (evt) => {
        if (!formState.name || !formState.email || !formState.address || !formState.country || !formState.partner || !formState.logo) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        if (!checkValidEmail(formState.email)) {
            notify("error", "Please enter valid Email ID");
            return null;
        }
        return handleFormSubmit(evt);
    }

    const handleFormSubmit = async (evt) => {
        setSubmitLoading(true);
        let payload = { ...formState };
        payload.timezone = '';
        payload.domain = (payload.partner === 'reseller_partner') ? formState.domain + '.partner.deviare.co.za' : formState.domain + '.platform.digitalskillsonline.net';
        payload.timezone = '';
        payload.link = '';
        payload.api_key = '';
        try {
            const result = await customerServices.addCustomer(payload);
            if (result && result.status) {
                notify("success", result.message);
                history.push(Paths.Customers);
            } else {
                setSubmitLoading(false);
                notify("error", (result && result.message) ? result.message : 'Something Went Wrong');
            }
        } catch (e) {
            setSubmitLoading(false);
            notify("error", (e && e.message) ? e.message : 'Something Went Wrong');
        }
    }

    const handleUploadImage = (e) => {
        let file = e.target.files[0];
        if (!file) return;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            handleInputChange(reader.result, 'logo');
        };
    };

    const handleChangeCheckItem = (name) => {
        handleInputChange(!formState[name], name);
    }

    const handleSendEmailSubmit = async (recipients) => {
        setSendTestEmailLoading(true);
        let sendEmailData = { ...formState };

        let data = {
            email_host: sendEmailData.email_host,
            email_subject_prifix: sendEmailData.email_subject_prifix,
            email_host_user: sendEmailData.email_host_user,
            email_host_password: sendEmailData.email_host_password,
            recipients: recipients
        };
        const emailRes = await customerServices.sendTestEmail(data);
        console.log("emailRes : ", emailRes);

        if (emailRes && emailRes.status) {
            notify('success', emailRes?.message || "Email send successfully");
        } else {
            notify("error", (emailRes && emailRes.message) ? emailRes.message : 'Something Went Wrong');
        }
        setSendTestEmailLoading(false);
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <div className={classes.pageHeader}>
                        <Typography
                            variant="subtitle2"
                            className={classes.heading}
                            color="textPrimary">
                            Create Customer
                        </Typography>
                    </div>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Company Name *
                            </Typography>
                            <Input disableUnderline value={formState.name}
                                type={'text'}
                                name="name"
                                className={classes.inputStyle}
                                placeholder={"Company Name"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Email *
                            </Typography>
                            <Input disableUnderline value={formState.email}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Email"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="email"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Address *
                            </Typography>
                            <Input disableUnderline value={formState.address}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Address"}
                                style={{ width: '100%' }}
                                name="address"
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Country of Registration
                            </Typography>
                            <Autocomplete
                                id="combo-box-demo4"
                                value={formState.country}
                                options={countryList ? countryList : []}
                                getOptionLabel={option => option}
                                onChange={(event, newValue) => handleInputChange(newValue, 'country')}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        color="secondary"
                                        placeholder="Select Country"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Account Type *
                            </Typography>
                            <OutlinedSelect
                                val={formState.partner}
                                name="partner"
                                placeholder='Accout Type'
                                variant="standard"
                                handleChange={(evt) => handleInputChange(evt)}
                                styleOverrides={{ width: '100%', marginTop: 0 }}
                                options={accountList}
                            />
                        </Grid>
                    </Grid>
                    {formState.partner && formState.partner !== 'enterprise_customer' &&
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    Branch on LMS *
                                </Typography>
                                <Input disableUnderline value={formState.branch}
                                    type={'text'}
                                    name="branch"
                                    className={classes.inputStyle}
                                    placeholder={"Branch on LMS"}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    Link *
                                </Typography>
                                <Input disableUnderline value={formState.link}
                                    type={'text'}
                                    name="link"
                                    className={classes.inputStyle}
                                    placeholder={"Link"}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    API Key *
                                </Typography>
                                <Input disableUnderline value={formState.api_key}
                                    type={'text'}
                                    name="api_key"
                                    className={classes.inputStyle}
                                    placeholder={"API Key"}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    TimeZone
                                </Typography>
                                <OutlinedSelect
                                    val={formState.timezone}
                                    name="timezone"
                                    placeholder='TimeZone'
                                    variant="standard"
                                    menuProps={{ style: { height: 450 } }}
                                    handleChange={(evt) => handleInputChange(evt)}
                                    styleOverrides={{ width: '100%', marginTop: 0 }}
                                    options={timezone}
                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Phone *
                            </Typography>
                            <PhoneInputField
                                handleChange={(newValue) => handleInputChange(newValue, 'contact_no')}
                                value={formState.contact_no}
                            />
                        </Grid>
                        {formState.partner && formState.partner !== 'enterprise_customer' &&
                            <Grid item xs={4}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    Domain *
                                </Typography>
                                <Input
                                    disableUnderline
                                    value={formState.domain}
                                    type={'text'}
                                    name="domain"
                                    className={classes.inputStyle}
                                    placeholder={"Domain"}
                                    style={{ width: '100%' }}
                                    color={'secondary'}
                                    onChange={handleInputChange}
                                />
                                {formState.partner === 'strategy_partner' &&
                                    <div style={{ marginTop: 5, width: '100%', textAlign: 'right' }}>
                                        <label>
                                            .platform.digitalskillsonline.net
                                        </label>
                                    </div>
                                }
                                {formState.partner === 'reseller_partner' &&
                                    <div style={{ marginTop: 5, width: '100%', textAlign: 'right' }}>
                                        <label>
                                            .partner.deviare.co.za
                                        </label>
                                    </div>
                                }
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Upload Logo *
                            </Typography>
                            <Input
                                disableUnderline
                                className={classes.inputStyle}
                                color={'secondary'}
                                id="file-input"
                                type="file"
                                name="BulkTemplate"
                                onChange={handleUploadImage}
                                inputProps={{ accept: "image/jpeg,image/jpg,image/png" }}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                    {formState.partner === 'strategy_partner' &&
                        <React.Fragment>
                            <Grid container spacing={1} style={{ marginTop: 20 }}>
                                <Grid item xs={2}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={handleChangeSMTP}
                                                checked={showSMTP}
                                                name="smtp" />
                                            }
                                            label="SMTP Settings"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            {showSMTP && (
                                <React.Fragment>
                                    <Grid container spacing={8}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                                SMTP Server
                                            </Typography>
                                            <Input disableUnderline value={formState.email_host}
                                                type={'text'}
                                                className={classes.inputStyle}
                                                placeholder={"SMTP Server"}
                                                style={{ width: '100%' }}
                                                name="email_host"
                                                color={'secondary'}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                                Port
                                            </Typography>
                                            <Input
                                                disableUnderline
                                                value={formState.port}
                                                type={'text'}
                                                className={classes.inputStyle}
                                                placeholder={"Port"}
                                                style={{ width: '100%' }}
                                                name="port"
                                                color={'secondary'}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                                Email Account
                                            </Typography>
                                            <Input
                                                disableUnderline
                                                value={formState.from}
                                                type={'text'}
                                                className={classes.inputStyle}
                                                placeholder={"Email Account"}
                                                style={{ width: '100%' }}
                                                name="from"
                                                color={'secondary'}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                                Mail Subject
                                            </Typography>
                                            <Input
                                                disableUnderline
                                                value={formState.email_subject_prifix}
                                                type={'text'}
                                                className={classes.inputStyle}
                                                placeholder={"Mail Subject"}
                                                style={{ width: '100%' }}
                                                name="email_subject_prifix"
                                                color={'secondary'}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormGroup style={{ margin: "10px 0" }}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={formState.secure_connection ? true : false} onChange={() => handleChangeCheckItem('secure_connection')} name="secure_connection" />}
                                                    label="Use Secure Connection"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={formState.use_authentication ? true : false} onChange={() => handleChangeCheckItem('use_authentication')} name="use_authentication" />}
                                                    label="Use Authentication"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8}>
                                        <Grid item xs={4}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                                Login
                                            </Typography>
                                            <Input
                                                disableUnderline
                                                value={formState.email_host_user}
                                                type={'text'}
                                                className={classes.inputStyle}
                                                placeholder={"Login"}
                                                style={{ width: '100%' }}
                                                name="email_host_user"
                                                color={'secondary'}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                                Password
                                            </Typography>
                                            <Input disableUnderline value={formState.email_host_password}
                                                type={'password'}
                                                className={classes.inputStyle}
                                                placeholder={"Password"}
                                                style={{ width: '100%' }}
                                                name="email_host_password"
                                                color={'secondary'}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={4}>
                                            <Button
                                                type={"button"}
                                                onClick={evt => setSendTestMailOpen(true)}
                                                variant="contained"
                                                color="primary"
                                                style={{ borderRadius: '5px' }}
                                            >
                                                Send Test Email
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    }
                    {formState.logo &&
                        <Grid container spacing={8}>
                            <Grid item xs={3}>
                                <img src={formState.logo} style={{ 
            marginLeft: 10, 
            width: 'auto', 
            height: '50px',
            maxWidth: '50px',
            objectFit: 'contain'
          }} />
                            </Grid>
                        </Grid>
                    }
                    <div className={classes.placeBtn}>
                        <Button
                            onClick={() => history.push(Paths.Customers)}
                            variant="contained"
                            color="primary"
                            className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button
                            type={"button"}
                            onClick={formValidationCheck}
                            variant="contained"
                            color="primary"
                            disabled={submitLoading}
                            className={classes.btn}
                        >
                            {submitLoading ? (
                                <CircularProgress
                                    size={18}
                                />
                            ) : 'Submit'}
                        </Button>
                    </div>
                </div>
                <SendTestEmailModal
                    open={sendTestMailOpen}
                    handleClose={evt => setSendTestMailOpen(false)}
                    handleSubmit={handleSendEmailSubmit}
                    loading={sendTestEmailLoading}
                />
            </main>
        </Layout>
    );
};

export default Customer;