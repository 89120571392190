import React, { useState, useEffect } from 'react';
import GenericTable from '../../../../Components/GenericTable';
import { Button, Grid } from '@material-ui/core/';
import { notify } from 'utils';
import { ProjectServices } from '../../../../Services/ProjectServices';
import { useParams } from 'react-router';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import CreateProjectForm from '../CreateProjectForm';
import { initAssignLicenseFields } from '../AssignLicence';
import { UserServices } from 'Services/UserServices';

const columns = [
    { label: "Project Name", key: 'project_name' },
    { label: "Company", key: 'company_name' },
    { label: "Admins", key: "admin_count" },
    { label: "Date", key: 'startDate' },
    { label: "Users", key: 'total_users' },
    { label: "Courses", key: "course_count" },
    { label: "Assessments", key: "assessment_count" },
    { label: "Experiments", key: "apprenticeship_count" },
    { label: "Status", key: "status" },
    { label: "Action", key: "action", actions: ["edit"] }
];
// const customerServices = new CustomerServices();
const projectServices = new ProjectServices();
const userService = new UserServices();
const initFormState = {
    status: 'Open',
    project_name: '',
    company_id: '',
    company_admin_id: '',
    project_admin: '',
    description: ''
};

const Project = ({
    activeStep,
    handleBack,
    handleNext
}) => {

    const { id } = useParams();

    const [projects, setProjectList] = useState([]);
    const [assessmentList, setAssessmentList] = useState([]);
    const [apprenticeshipList, setApprenticeshipList] = useState([]);
    const [labsList, setLabsList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState({ ...initFormState });
    const [submitLoading, setSubmitLoading] = useState(false);
    const [openCreateForm, setOpenCreateForm] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [projectAdminList, setProjectAdminList] = useState([]);
    const [customerAdminList, setCustomerAdminList] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [assignLicenseForm, setAssignLicenseForm] = useState({ ...initAssignLicenseFields });
    const [projectDetails, setProjectDetails] = useState(null);
    const [editProjectId, setEditProjectId] = useState(null);
    const [removeCourses, setRemoveCourses] = useState([]);
    const [removeApprenticeships, setRemoveApprenticeships] = useState([]);
    const [removeAssessments, setRemoveAssessments] = useState([]);
    const [removeLabs, setRemoveLabs] = useState([]);
    const [existingLicence, setExistingLicence] = useState({courses: [], assessments: [], apprenticeships: []});

    let comp_ids = localStorage.getItem('customerUUID');

    useEffect(() => {
        setLoading(true);
        getProjectList();
        getCustomerList();
        getCustomerAdminList();
        getAllProjectList(id || comp_ids);
        getProjectDetails();
        getProjectAdminUser(id || comp_ids);
    }, []);

    const getCustomerAdminList = async () => {
        let csId = id || comp_ids;
        const body = {
            customer: csId
        };
        const customerARes = await projectServices.getCustomerAdminList(body);
        if (customerARes && customerARes.data) {
            setCustomerAdminList((customerARes.data.length) ? customerARes.data.map(itm => {
                return {uuid: itm.uuid, name: itm.userName}
            }) : []);
        }
    }

    const getCustomerList = async () => {
        const customerARes = await projectServices.getCustomerList();
        if (customerARes && customerARes.data) {
            let csId = id || comp_ids;
            setCustomerList((customerARes.data.length) ? customerARes.data.filter(cs => (cs.uuid === csId)) : []);
        }
    }

    const getAllProjectList = async (id) => {
        const projectsRes = await projectServices.allProjectsByCustomer(id);
        if (projectsRes && projectsRes.results) {
            setProjectList(projectsRes.results);
            setEditProjectId(null);
            localStorage.setItem('project_edit', false);
        }
    }

    const getProjectList = async () => {
        if (localStorage.getItem('assign_lecense') === 'true') {
            getProjectDetails();
            localStorage.setItem('assign_lecense', false);
        }
        const project_edit = localStorage.getItem('project_edit');
        if (project_edit === 'true') {
            let csId = localStorage.getItem('customerUUID');
            const projectsRes = projectServices.allProjectsByCustomer(csId);
            if (projectsRes && projectsRes.data) {
                setProjectList(projectsRes.data);
                setEditProjectId(null);
                localStorage.setItem('project_edit', false);
            }
        }
        setLoading(false);
    }

    async function getProjectAdminUser(compId){
        const userResult = await userService.getProjectAdminUsers(compId);
        setProjectAdminList((userResult?.data && userResult?.data?.map(itm => ({name: itm.userName, id: itm.uuid }))) || []);
    }

    async function getProjectDetails(){
        let csFId = id || localStorage.getItem('cstmsrSaveFlow');
        const assignLicenses = await projectServices.getProjectDetails(csFId);
        if (assignLicenses && assignLicenses.data) {
            const { assessment, course, apprenticeship, lab } = assignLicenses.data;
            setAssessmentList(assessment || []);
            setCourseList(course || []);
            setApprenticeshipList(apprenticeship || []);
            setLabsList(lab || []);
            setProjectDetails(assignLicenses.data);
            let labs = [...initAssignLicenseFields.labs];
            let courseIds = [];
            const courseList = course.length && course.map(itm =>  {
                courseIds.push(itm.uuid);
                return ({
                    course_id: itm.uuid,
                    user_count: 0,
                    assigned_count: itm?.count || 0,
                    maxCount: itm?.total_count ? parseInt(itm?.total_count) : 0,
                    total_count: itm?.total_count ? parseInt(itm?.total_count) : 0,
                    count: 0 
                });
            });
            // if(!lab?.length){
                const xpertSkillsLabs = await projectServices.getLabsByCourse({ course_ids: courseIds });
                if(xpertSkillsLabs?.status){
                    const selectedLabs = (xpertSkillsLabs?.data.length) ? xpertSkillsLabs?.data.map(itm => {
                        return {
                            lab_id: itm.uuid,
                            name: itm.name,
                            count: 0,
                            course_id: itm?.course_id || '',
                            total_count: 0
                        };
                    }) : [...initAssignLicenseFields.labs];
                    labs = selectedLabs;
                }
            // }
            setAssignLicenseForm({
                assessments: (assessment && assessment.length) ? assessment.map(itm => ({
                    assessment_id: itm.uuid,
                    user_count: 0,
                    assigned_count: itm?.count || 0,
                    maxCount: itm?.total_count ? parseInt(itm?.total_count) : 0,
                    total_count: itm?.total_count ? parseInt(itm?.total_count) : 0,
                    count: 0
                })) : initAssignLicenseFields.assessments,
                courses: (courseList.length) ? courseList : initAssignLicenseFields.courses,
                apprenticeships: (apprenticeship && apprenticeship.length) ? apprenticeship.map(itm => ({
                    apprenticeship_id: itm.uuid, 
                    user_count: 0,
                    assigned_count: itm?.count || 0,
                    maxCount: itm?.total_count ? parseInt(itm?.total_count) : 0,
                    total_count: itm?.total_count ? parseInt(itm?.total_count) : 0,
                    count: 0
                })) : initAssignLicenseFields.apprenticeships,
                labs: labs
            });
            setExistingLicence({ 
                courses: courseIds,
                assessments: (assessment?.length) ? assessment.map(itm => itm.uuid) : [], 
                apprenticeships: (apprenticeship?.length) ? apprenticeship.map(itm => itm.uuid): [] 
            });
        }
    }

    const handleItemClick = async (row) => {
        if (!row) return null;
        
        setEditProjectId(row?.project_uuid);
        setProjectId(row?.project_uuid);
        setRemoveLabs([]);
        const projRes = await projectServices.getProjectDetail(row?.project_uuid);
        if(projRes && projRes.status){
            let responsData = projRes?.data || {};
            const { assessment, course, apprenticeship, lab: labs } = responsData;
            const projectCourses = [];
            const courseIds = [];
            course?.length && course.forEach(crsItem => {
                const itm = {...crsItem};
                const assignCourse = assignLicenseForm.courses.find(crs => crs.course_id === itm.uuid);
                if(assignCourse){
                    if(itm.provider === 'Xpert Skills'){
                        courseIds.push(itm.uuid);
                    }
                    let isUserCountNotValid = (assignCourse.assigned_count > parseInt(assignCourse.total_count));
                    let remainCount = parseInt(assignCourse.total_count) - assignCourse.assigned_count;
                    let total_count = itm?.total_count || 0;
                    if(total_count > parseInt(assignCourse.total_count)){
                        total_count = (itm?.user_count) ? itm?.user_count : 0;
                        if(total_count > 0){
                            total_count = (assignCourse.assigned_count <= itm?.total_count) ? parseInt(assignCourse.total_count) : total_count;
                        }
                    }
                    if(total_count){
                        remainCount = remainCount+parseInt(total_count);
                    }
                    if(isUserCountNotValid) {
                        remainCount = parseInt(assignCourse.total_count);
                    }
                    projectCourses.push({
                        course_id: itm.uuid,
                        user_count: itm?.user_count || 0,
                        count: total_count || 0,
                        total_count,
                        maxCount: remainCount || assignCourse?.total_count
                    });
                }
            });
            const projectLabs = [];
            const xpertSkillsLabs = await projectServices.getLabsByCourse({ course_ids: courseIds });
            (xpertSkillsLabs?.data) && xpertSkillsLabs.data?.forEach((lb) => {
                const itm = {...lb};
                const assignCourseLabs = projectCourses.find(crs => crs.course_id === itm.course_id);
                const assignLabs = labs?.find(itm_lab => itm_lab.uuid === itm.uuid);
                if(assignCourseLabs && assignLabs){   
                    projectLabs.push({
                        lab_id: itm.uuid,
                        user_count: assignLabs?.user_count || 0,
                        count: assignCourseLabs?.total_count || 0,
                        name: itm.name,
                        course_id: itm?.course_id || '',
                        total_count: assignLabs?.total_count || 0,
                        maxCount: assignCourseLabs?.remainCount || 0
                    });
                }
            });
            const projectAssessment = [];
            (assessment && assessment.length) && assessment.map(ass => {
                const itm = {...ass};
                const assignAssesment = assignLicenseForm.assessments.find(ass => ass.assessment_id === itm.uuid);
                if(assignAssesment){
                    let isUserCountNotValid = (assignAssesment.assigned_count > parseInt(assignAssesment.total_count));
                    let remainCount = parseInt(assignAssesment.total_count) - assignAssesment.assigned_count;
                    let total_count = itm?.total_count || 0;
                    if(total_count > parseInt(assignAssesment.total_count)){
                        total_count = (itm?.user_count) ? itm?.user_count : 0;
                        if(total_count > 0){
                            total_count = (assignAssesment.assigned_count <= itm?.total_count) ? parseInt(assignAssesment.total_count) : total_count;
                        }
                    }
                    if(total_count){
                        remainCount = remainCount+parseInt(total_count);
                    }
                    if(isUserCountNotValid) remainCount = parseInt(assignAssesment.total_count);

                    projectAssessment.push({
                        assessment_id: itm.uuid, 
                        user_count: itm?.user_count || 0,
                        count: total_count,
                        total_count,
                        maxCount: remainCount || assignAssesment?.total_count
                    })
                }
            });
            const projectApprenticeship = [];
            (apprenticeship && apprenticeship.length) && apprenticeship.map((appr) => {
                const itm = {...appr};
                const assignAppr = assignLicenseForm.apprenticeships.find(crs => crs.apprenticeship_id === itm.uuid);
                if(assignAppr){
                    let isUserCountNotValid = (assignAppr.assigned_count > parseInt(assignAppr.total_count));
                    let remainCount = parseInt(assignAppr.total_count) - assignAppr.assigned_count;
                    let total_count = itm?.total_count || 0;
                    if(total_count > parseInt(assignAppr.total_count)){
                        total_count = (itm?.user_count) ? itm?.user_count : 0;
                        if(total_count > 0){
                            total_count = (assignAppr.assigned_count <= itm?.total_count) ? parseInt(assignAppr.total_count) : total_count;
                        }
                    }
                    if(total_count){
                        remainCount = remainCount+parseInt(total_count);
                    }
                    if(isUserCountNotValid) remainCount = parseInt(assignAppr.total_count);
                    projectApprenticeship.push({
                        apprenticeship_id: itm.uuid, 
                        user_count: itm?.user_count || 0, 
                        count: total_count,
                        total_count,
                        maxCount: remainCount || assignAppr?.total_count
                    });
                }
            });
            setAssignLicenseForm({
                assessments: (projectAssessment?.length) ? [...projectAssessment] : [...initAssignLicenseFields.assessments],
                courses: (projectCourses?.length) ? [...projectCourses] : [...initAssignLicenseFields.courses],
                apprenticeships: (projectApprenticeship?.length) ? [...projectApprenticeship] : [...initAssignLicenseFields.apprenticeships],
                labs: (projectLabs?.length) ? [...projectLabs] : [...initAssignLicenseFields.labs],
            });
            setFormState({
                status: 'Open',
                project_name: responsData?.project_name,
                company_id: responsData?.company_id,
                company_admin_id: responsData?.company_admin_id,
                project_admin: responsData?.project_admin,
                description: responsData?.description
            });
            setOpenCreateForm(true);
        }
    }

    const handleCreateNew = () => {
        setRemoveLabs([]);
        setOpenCreateForm(true);
    }

    const formValidationCheck = (evt) => {
        if (!formState.project_name || !formState.company_id || !formState.company_admin_id) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        let isValidCourseCount = [];
        let isValidAsseCount = [];
        let isValidApprCount = [];
        assignLicenseForm.courses?.map((itm) => {
            if( itm?.course_id && !parseInt(itm.count)){
                isValidCourseCount.push(itm);
            }
        });
        assignLicenseForm.assessments?.map(itm => {
            if( itm?.assessment_id && !parseInt(itm.count)){
                isValidAsseCount.push(itm);
            }
        });
        assignLicenseForm.apprenticeships?.map(itm => {
            if( itm?.apprenticeship_id && !parseInt(itm.count)){
                isValidApprCount.push(itm);
            }
        });
        if(isValidAsseCount.length){
            notify("error", `You can't Assign Assessment with 0 Count!`);
            return null;
        }
        if(isValidCourseCount.length){
            let courses = isValidCourseCount.map(cs => cs?.course_id?.name || '');
            notify("error", `You can't Assign ${courses.join(", ")} Course with 0 Count!`);
            return null;
        }
        if(isValidApprCount.length){
            let appris = isValidApprCount.map(cs => cs?.apprenticeship_id?.name || '');
            notify("error", `You can't Assign ${appris.join(", ")} Apprenticeship with 0 Count!`);
            return null;
        }
        return handleFormSubmit(evt);
    }

    const handleFormSubmit = async (evt) => {
        setSubmitLoading(true);
        const assignLabs = []; 
        assignLicenseForm?.labs?.length && assignLicenseForm.labs.map((itm) => {
            let course = assignLicenseForm.courses.find(crs => crs?.course_id === itm.course_id);
            if(course){
                assignLabs.push({
                    ...itm,
                    count: course?.count ? parseInt(course?.count) : 0,
                    total_count: course?.count ? parseInt(course?.count) : 0
                });
            }
        });
        const payload = {
            ...formState,
            course: assignLicenseForm.courses, 
            assessment: assignLicenseForm.assessments, 
            apprenticeship: assignLicenseForm.apprenticeships,
            lab: assignLabs,
            remove_assesments: removeAssessments,
            remove_courses: removeCourses,
            remove_labs: removeLabs,
            remove_apprenticeships: removeApprenticeships
        };
        const createRes = await projectServices.addProject(payload, editProjectId);
        if(createRes && createRes.status && !createRes?.message?.includes("You don't have availabe license")){
            localStorage.setItem('project_id', createRes.data.uuid ? createRes.data.uuid : '');
            localStorage.setItem('refreshlic', true);
            getAllProjectList(id || comp_ids);
            handleNext(evt);
            notify("success", createRes.message);
            setSubmitLoading(false);
            setEditProjectId(null);
            setOpenCreateForm(false);
        }else {
            setSubmitLoading(false);
            notify("error", (createRes && createRes.message) ? createRes.message : 'Something Went Wrong');
            return null;
        }
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const handleChangeAsl = (e, idx, type) => {
        let { name, value } = e.target;
        if(type === 'courses' && name === 'course_id'){
            autoSelectLabsByCourse(value, idx, 'add');
        }else{
            if(name === "count"){
                value = value ? parseInt(value) : 0;
            }
            setAssignLicenseForm((prevState) => {
                let newState = { ...prevState };
                let prvData = newState[type];
                prvData[idx] = { ...prvData[idx], [name]: value };
                newState[type] = prvData;
                return newState;
            });
        }
    }

    const handleRemoveAssignLicence = (type, idx) => {
        let checkExist = assignLicenseForm?.[type]?.[idx];
        let checkKey = (type === 'courses') ? 'course_id' : (type === 'apprenticeships') ? 'apprenticeship_id' : (type === 'assessments') ? 'assessment_id' : '';
        if(checkKey && checkExist[checkKey] && existingLicence[type].includes(checkExist[checkKey])){
            if(type === 'courses'){
                setRemoveCourses([...removeCourses, checkExist?.course_id]);
            }else if(type === 'apprenticeships'){
                setRemoveApprenticeships([...removeApprenticeships, checkExist?.apprenticeship_id]);
            }else if(type === 'assessments'){
                setRemoveAssessments([...removeAssessments, checkExist?.assessment_id]);
            }
        }
        if(type === 'courses'){
            autoSelectLabsByCourse(null, idx, 'remove');
        }else{
            setAssignLicenseForm((prevState) => {
                let newState = { ...prevState };
                let prvData = [...newState[type]];
                prvData.splice(idx, 1);
                newState[type] = (prvData?.length) ? prvData : [...initAssignLicenseFields[type]];
                return newState;
            });
        }
    }

    const handleAddAssignLicence = (type) => {
        setAssignLicenseForm((prevState) => {
            let newState = { ...prevState };
            let prvData = [...newState[type]];
            prvData.push({ ...initAssignLicenseFields[type][0] });
            newState[type] = prvData;
            return newState;
        });
    }

    const autoSelectLabsByCourse = async (course, indx, action = 'add') => {
        let removeCourse = (!course && assignLicenseForm.courses.length) ? assignLicenseForm.courses[indx] : null;
        const courseIds = (removeCourse && removeCourse?.course_id) ? [removeCourse?.course_id] : [course];
        let selectedLabs = (assignLicenseForm.labs.length && assignLicenseForm.labs[0].lab_id) ? [...assignLicenseForm.labs] : [];
        let selectedCourse = assignLicenseForm.courses.find(cr => cr.course_id === course);
        const courseLabs = await projectServices.getLabsByCourse({ course_ids: courseIds });
        let removeLabsIds = [...removeLabs];
        const removeLabsIndex = [];
        if(courseLabs?.status){
            courseLabs?.data.forEach(itm => {
                if(removeCourse &&  action !== 'add'){
                    let removeIdx = assignLicenseForm.labs.findIndex(lab => lab.lab_id === itm.uuid);
                    removeLabsIndex.push(removeIdx);
                    removeLabsIds.push(itm.uuid);
                }else{
                    let exist = selectedLabs.find((lb) => lb.lab_id === itm.uuid);
                    if(!exist){
                        selectedLabs.push({
                            lab_id: itm.uuid,
                            name: itm.name,
                            count: selectedCourse?.count || 0,
                            total_count: selectedCourse?.total_count || 0,
                            course_id: itm?.course_id || ''
                        });
                    }
                }
            });
        }
        if(removeCourse && removeLabsIndex.length){
            const indexSet = new Set(removeLabsIndex);
            selectedLabs = selectedLabs.filter((value, i) => !indexSet.has(i));
            setRemoveLabs(removeLabsIds);
        }
        let assignCourse = courseList.find(cr => cr.uuid === course);
        setAssignLicenseForm((prevState) => {
            let newState = {...prevState};
            let prvData = [...newState.courses];
            if(action === 'add'){
                prvData[indx] = { ...prvData[indx], maxCount: assignCourse?.total_count, course_id: course };
            }else{
                prvData.splice(indx, 1);
            }
            newState.courses = (prvData.length) ? prvData : [...initAssignLicenseFields.courses];
            newState.labs = selectedLabs.length ? [...selectedLabs] : [...initAssignLicenseFields.labs];
            return newState;
        });
    }

    return (
        <>
            <div style={openCreateForm ? { display: 'none' } : null}>
                <Grid container style={{ paddingTop: 20 }}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4} style={{ display: "flex" }} justifyContent={'flex-end'} >
                        <Button
                            align={'right'}
                            variant={'outlined'}
                            style={{ padding: '15px 35px', borderRadius: 5 }}
                            color="primary"
                            type='button'
                            onClick={handleCreateNew}
                        >
                            Create Project
                        </Button>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                    <GenericTable
                        applyMinWidth={false}
                        columns={columns}
                        loading={loading}
                        handleItemClick={handleItemClick}
                        list={projects}
                    />
                </Grid>
            </div>
            <div style={openCreateForm ? null : { display: 'none' }}>
                <CreateProjectForm
                    formState={formState}
                    handleInputChange={handleInputChange}
                    customerList={customerList}
                    customerAdminList={customerAdminList}
                    projectAdminList={projectAdminList}
                    project_id={projectId}
                    handleCancelCreate={() => {
                        getProjectDetails();
                        setFormState({ ...initFormState });
                        setOpenCreateForm(false);
                    }}
                    formValidationCheck={formValidationCheck}
                    loading={submitLoading}
                    handleBack={handleBack}
                    labsList={labsList}
                    assignLicenseForm={assignLicenseForm}
                    handleChangeAsl={handleChangeAsl}
                    handleAddAssignLicence={handleAddAssignLicence}
                    handleRemoveAssignLicence={handleRemoveAssignLicence}
                    assessmentList={assessmentList}
                    apprenticeshipList={apprenticeshipList}
                    courseList={courseList}
                    projectDetails={projectDetails}
                />
            </div>
        </>
    );
}

export default Project;