import React from 'react';
import ReactEcharts from "echarts-for-react";

const BarHorizontalChartWrapper = ({
    data,
    dataKeyYAxis,
    title
}) => {

    return (
        <div>
            <ReactEcharts
                option={{
                    height: '80%',
                    title: {
                        text: title ? title : ''
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '1%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: dataKeyYAxis
                    },
                    series: data
                }} />
        </div>
    );
};

export default BarHorizontalChartWrapper;