import axios from 'axios';
import '../Sys/config.js';

var apiUrl = global.platformURI;

export class ProjectServices {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        let token = localStorage.getItem('ssoToken');
        config.headers.Authorization = `Token ${token}`;
        config.baseURL = apiUrl;
        return config;
      },
      error => {
        console.log('error.response.status', error);
        return error;
      }
    );
  }

  // get customer list
  getCustomerList() {
    return axios.get(`main/customerdropdown`).then(res => res.data);
  }

  getAllLicenseListProject(url) {
    return axios.get(url).then(res => res.data);
  }
  deleteLicences(id, data) {
    return axios
      .put(`project/licensedetail/${id}`, data)
      .then(res => res.data);
  }
  // get assessment list
  getAssessmentsList(id) {
    if (id) {
      return axios
        .get(`main/assessmentdropdown?customer_id=` + id)
        .then(res => res.data);
    } else {
      return axios
        .get(`main/assessmentdropdown`)
        .then(res => res.data);
    }
  }
  // get apprenticeship list
  getApprenticeshipsList(id) {
    if (id) {
      return axios
        .get(`main/apprenticeshipdropdown?customer_id=` + id)
        .then(res => res.data);
    }
    return axios
      .get(`main/apprenticeshipdropdown`)
      .then(res => res.data);
  }
  // get course list
  getCoursesList(id) {
    if (id) {
      return axios
      .get(`main/coursedropdown?customer_id=` + id)
      .then(res => res.data);
    } else {
      return axios.get(`main/coursedropdown`).then(res => res.data);
    }
  }
  getLabsList(id, query) {
    if (id) {
      return axios
      .get(`main/labsdropdown?customer_id=` + id)
      .then(res => res.data);
    } else {
      return axios.get(`main/lab/${query}`).then(res => res.data);
    }
  }
  // get customer admin list
  getCustomerAdminList(payload) {
    return axios
      .post(`main/customeradmindropdown`, payload)
      .then(res => res.data);
  }
  //   get getUserListForAllocate
  getUserListForAllocate(payload) {
    return axios
      .post(`project/customerusers`, payload)
      .then(res => res.data);
  }
  //   get getlicenseinformation
  getLicenseinformation(payload) {
    return axios
      .post(`project/licenseinformation`, payload)
      .then(res => res.data);
  }
  // Submit allocate user
  addAllocateUser(payload, id) {
    return axios
      .post(`project/userallocation/${id}`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  removeAllocateUser(payload, id) {
    return axios
      .put(`project/userallocation/${id}`, payload)
      .then(res => res.data);
  }
  getDetailLicenseCustomer(id) {
    return axios
      .get(`project/licensedetail/${id}`)
      .then(res => res.data);
  }

  addProject(payload, id) {
    if (id) {
      return axios.put(`project/projectdetail/${id}`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
    } else {
      return axios
        .post('project/createproject', payload)
        .then(res => res.data)
        .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }
  }
  getAllProject(url) {
    return axios.get(url).then(res => res.data);
  }
  getProjectDetail(id) {
    return axios
      .get(`project/projectdetail/${id}`)
      .then(res => res.data);
  }
  getProjectDetails(id) {
    return axios
      .get(`main/assign_customer_license?customer_id=${id}`)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  assignLicense(url, payload) {
    if (url !== '') {
      return axios.put(url, payload).then(res => res.data);
    } else {
      return axios
        .post('main/assign_customer_license', payload)
        .then(res => res.data);
    }
  }

  userByProject(id) {
    return axios
      .get(`main/userbyproject?project_id=${id}`)
      .then(res => res.data);
  }

  allProjectsByCustomer(id) {
    return axios
      .get(`project/all_projects_by_customer?customer_id=${id}`)
      .then(res => res.data);
  }

  getProjectSummary(id) {
    return axios
      .get(`main/project_summary?project_id=${id}`)
      .then(res => res.data);
  }
  getLabsByCourse(payload) {
    return axios
      .post('main/labs_mapped_with_course', payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }

  assignProjectToUsers(payload) {
    return axios
      .post('main/assign_project_to_users', payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
}
