import * as React from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const Calendar = ({ handleEventClick, 
    handleEvents, 
    handleClickCalendarEvent, 
    handleDateSelect, 
    weekendsVisible, 
    initialEvents, 
    renderEventContent,
    handleGetEventByMonth 
}) => {

    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            customButtons={{
                Events: {
                    text: 'Events',
                    click: handleClickCalendarEvent
                }
            }}
            headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth Events"
            }}
            initialView="dayGridMonth"
            editable={false}
            selectable={true}
            selectMirror={false}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            events={initialEvents} // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            datesSet={handleGetEventByMonth}
        />
    )
}

export default Calendar;