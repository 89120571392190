import axios from 'axios';
import "../Sys/config.js";

const apiUrl = global.platformURI;

export class AdministratorServices {
    constructor() {
        axios.interceptors.request.use(function (config) {
            const token = localStorage.getItem('ssoToken');
            config.baseURL = apiUrl;
            config.headers.Authorization = `Token ${token}`;
            return config;
        }, error => {
            console.log('error.response.status', error);
            return error;
        });
    }

    getAllAdministrator(url) {
        return axios.get(url)
            .then(res => res.data);
    }

    addAdministrator(payload, uuid) {
        if (uuid) {
            return axios.put(`main/superadmindetail/${uuid}`, payload).then(res => res.data);
        }
        return axios.post(apiUrl + "main/superadmincreate", payload)
            .then(res => res.data)
    }

    getAdminisratorDetail(id) {
        return axios.get(apiUrl + `main/superadmindetail/${id}`)
            .then(res => res.data)
    }
}