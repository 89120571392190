import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../CustamizedFilters/filterModule.css';

export default function DynamicAutoSearch({ open, options, handleOpen, handleSearch, handleChange, optLoading = '' }) {

  const loading = (optLoading === '') ? open && options.length === 0 : false;
  
  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        handleOpen(true);
      }}
      onClose={() => {
        handleOpen(false);
      }}
      onChange={handleChange}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Search'}
          variant="standard"
          onChange={ev => {
            handleSearch(ev.target.value);
          }}
          className={"customInputBox"}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}