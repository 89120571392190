import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutelinedInput from '../../../../Components/OutelinedInput';
import OutlinedSelect from '../../../../Components/OutlinedSelect';
import { Grid, Typography, Button, CircularProgress, Input } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { AddMoreButton, NumberInput, RemoveButton } from '../AssignLicence';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { IsNumeric, notify } from 'utils';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    labelStyle: { 
        marginBottom: '20px', 
        marginTop: '15px', 
        color: '#333333' 
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    },
    cardRoot: {
        border: '1px solid #D6D6D6',
        borderRadius: 5,
        minHeight: 240,
        backgroundColor: ' #F9F9F9'
    },
    title: {
        fontSize: 15,
        fontWeight: 700,
        lineHeight: "18px",
        color: '#4D5766'
    },
    pos: {
        marginBottom: 12,
    },
    buttonWrap: {
        width: '100%',
        textAlign: 'center',
        margin: '10px auto'
    },
    removeBtn: {
        background: '#E23A3A',
        color: '#fff',
        height: 32,
        width: 32,
        borderRadius: '50%',
        '&:hover': {
            background: '#c20b0b',
            color: '#fff'
        }
    },
    noHeading: {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        marginTop: 15
    },
    noOfItemWrap: {
        display: "flex",
        width: "100%",
        marginTop: 20
    },
    inputStyle: {
        minWidth: 130,
        width: 130,
        padding: 12,
        marginLeft: 6
    }
}));

const CreateProjectForm = ({
    handleInputChange,
    formState,
    customerList,
    customerAdminList,
    projectAdminList,
    project_id,
    handleCancelCreate,
    formValidationCheck,
    loading,
    handleBack,
    assignLicenseForm,
    handleChangeAsl,
    handleAddAssignLicence,
    handleRemoveAssignLicence,
    assessmentList,
    apprenticeshipList,
    courseList
}) => {

    const classes = useStyles();

    const getAssessments = () => {
        return (
            assignLicenseForm.assessments.map((item, key) => (
                <div key={`assisment-${key}`} className={classes.itemContain}>
                    <Card className={classes.cardRoot} style={key > 0 ? { marginTop: 30 } : null}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Assessments Allocated : { item?.user_count || 0 } / {item?.count || 0}
                            </Typography>
                            <OutlinedSelect
                                val={item.assessment_id}
                                name="assessment_id"
                                placeholder='Select Assessments'
                                variant="standard"
                                handleChange={(evt) => {
                                    let check = assignLicenseForm.assessments.find(cr => cr.assessment_id === evt.target.value && evt.target.value);
                                    if(check){
                                        notify('error', `This assessment is already selected!`);
                                        return null;
                                    }
                                    handleChangeAsl(evt, key, 'assessments')
                                }}
                                styleOverrides={{ marginTop: 36, marginBottom: 20 }}
                                options={assessmentList}
                                menuProps={{ style: { height: 400, zIndex: 9999 } }}
                                selectStyle={{ maxWidth: 226, minWidth: 228, padding: "4px 10px" }}
                            />
                            <div className={classes.noOfItemWrap}>
                                <Typography className={classes.noHeading} >Number of licenses</Typography>
                                <Input
                                    disableUnderline
                                    value={item.count}
                                    type="text"
                                    className={classes.inputStyle}
                                    placeholder={"Number"}
                                    name="count"
                                    disabled={!item.assessment_id}
                                    onChange={evt => {
                                        if(evt.target.value && !IsNumeric(evt.target.value)){
                                            return null;
                                        }
                                        if(evt.target.value && evt.target.value > item?.maxCount){
                                            notify("error", `You can't assign more then ${item?.maxCount}`);
                                            return null;
                                        }
                                        handleChangeAsl(evt, key, "assessments");
                                    }}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <div className={classes.buttonWrap}>
                        {(assignLicenseForm.assessments.filter(itm => itm.assessment_id).length > 0) && 
                            <>
                                <AddMoreButton action={'assessments'} handleAddNew={handleAddAssignLicence} />
                                <RemoveButton action={'assessments'} index={key} handleClick={handleRemoveAssignLicence} />
                            </>
                        }
                    </div>
                </div>
            ))
        )
    }

    const getCourses = () => {
        return (assignLicenseForm.courses.map((item, key) => (
                <div key={`course-${key}`} className={classes.itemContain}>
                    <Card className={classes.cardRoot} style={key > 0 ? { marginTop: 30 } : null}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Courses Allocated : { item?.user_count || 0 } / {item?.count || 0}
                            </Typography>
                            <OutlinedSelect
                                val={item.course_id}
                                name="course_id"
                                placeholder='Select Courses'
                                variant="standard"
                                handleChange={(evt) => {
                                    let check = assignLicenseForm.courses?.find(cr => cr?.course_id === evt.target.value && evt.target.value);
                                    if(check){
                                        notify('error', `This course is already selected!`);
                                        return null;
                                    }
                                    return handleChangeAsl(evt, key, 'courses');
                                }}
                                styleOverrides={{ marginTop: 36, marginBottom: 20 }}
                                options={courseList}
                                menuProps={{ style: { height: 400, zIndex: 9999 } }}
                                selectStyle={{ maxWidth: 226, minWidth: 226, padding: "4px 10px" }}
                            />
                            <div className={classes.noOfItemWrap}>
                                <Typography className={classes.noHeading} >Number of licenses</Typography>
                                <Input
                                    disableUnderline
                                    value={item.count}
                                    type="text"
                                    className={classes.inputStyle}
                                    placeholder={"Number"}
                                    name="count"
                                    disabled={!item.course_id}
                                    onChange={evt => {
                                        if(evt.target.value && !IsNumeric(evt.target.value)){
                                            return null;
                                        }
                                        if(evt.target.value && evt.target.value > item?.maxCount){
                                            notify("error", `You can't assign more then ${item?.maxCount}`);
                                            return null;
                                        }
                                        handleChangeAsl(evt, key, "courses");
                                    }}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <div className={classes.buttonWrap}>
                        {key === (assignLicenseForm.courses.length -1) && <AddMoreButton action={'courses'} handleAddNew={handleAddAssignLicence} />}
                        {<RemoveButton index={key} action={'courses'} handleClick={handleRemoveAssignLicence} />}
                    </div>
                </div>
            ))
        )
    }

    const getApprenticeships = () => {
        return (
            assignLicenseForm.apprenticeships.map((item, key) => (
                <div key={`assisment-${key}`} className={classes.itemContain}>
                    <Card className={classes.cardRoot} style={key > 0 ? { marginTop: 30 } : null}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Apprenticeships Allocated : { item?.user_count || 0 } / {item?.count || 0}
                            </Typography>
                            <OutlinedSelect
                                val={item.apprenticeship_id}
                                name="apprenticeship_id"
                                placeholder='Select Apprenticeships'
                                variant="standard"
                                handleChange={(evt) => {
                                    let check = assignLicenseForm.apprenticeships?.find(cr => cr?.apprenticeship_id === evt.target.value && evt.target.value);
                                    if(check){
                                        notify('error', `This apprenticeship is already selected!`);
                                        return null;
                                    }
                                    handleChangeAsl(evt, key, 'apprenticeships')
                                }}
                                styleOverrides={{ marginTop: 36, marginBottom: 20 }}
                                options={apprenticeshipList}
                                menuProps={{ style: { height: 400, zIndex: 9999 } }}
                                selectStyle={{ maxWidth: 226, minWidth: 226, padding: "4px 10px" }}
                            />
                            <div className={classes.noOfItemWrap}>
                                <Typography className={classes.noHeading} >Number of licenses</Typography>
                                <Input
                                    disableUnderline
                                    value={item.count}
                                    type="text"
                                    className={classes.inputStyle}
                                    placeholder={"Number"}
                                    disabled={!item.apprenticeship_id}
                                    name="count"
                                    onChange={evt => {
                                        if(evt.target.value && !IsNumeric(evt.target.value)){
                                            return null;
                                        }
                                        if(evt.target.value && evt.target.value > item?.maxCount){
                                            notify("error", `You can't assign more then ${item?.maxCount}`);
                                            return null;
                                        }
                                        handleChangeAsl(evt, key, "apprenticeships");
                                    }}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <div className={classes.buttonWrap}>
                        {(assignLicenseForm.apprenticeships.filter(itm => itm.apprenticeship_id).length > 0) &&  <>
                            <AddMoreButton action={'apprenticeships'} handleAddNew={handleAddAssignLicence} /> 
                            <RemoveButton index={key} action={'apprenticeships'} handleClick={handleRemoveAssignLicence} /></>
                        }
                    </div>
                </div>
            ))
        )
    }

    const getLabs = () => {
        return (assignLicenseForm.labs.map((item, key) => (
                <div key={`labs-${key}`} className={classes.itemContain}>
                    <Card className={classes.cardRoot} style={key > 0 ? { marginTop: 75 } : null}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Labs Allocated : { item?.user_count || 0 } / {item?.count || 0}
                            </Typography>
                            <Input
                                variant="standard"
                                placeholder="Select Labs"
                                value={item.name}
                                disabled
                                style={{ minWidth: 180, width: '100%', padding: 10, marginTop: 36 }}
                                disableUnderline
                            />
                            <NumberInput
                                value={item?.count || 0}
                                index={key}
                                maxNum={null}
                                action="labs"
                                disabled={true}
                            />
                            {/* <OutlinedSelect
                                val={item.lab_id}
                                name="lab_id"
                                placeholder='Select Labs'
                                variant="standard"
                                handleChange={(evt) => handleChangeAsl(evt, key, 'labs')}
                                styleOverrides={{ marginTop: 36, marginBottom: 20 }}
                                options={labsList}
                                menuProps={{ style: { height: 400, zIndex: 9999 } }}
                                selectStyle={{ maxWidth: 226, minWidth: 226, padding: "4px 10px" }}
                            />
                            <div className={classes.noOfItemWrap}>
                                <Typography className={classes.noHeading} >Number of licenses</Typography>
                                <Input
                                    disableUnderline
                                    value={item.count}
                                    type="text"
                                    className={classes.inputStyle}
                                    placeholder={"Number"}
                                    name="count"
                                    onChange={evt => {
                                        if(evt.target.value && !IsNumeric(evt.target.value)){
                                            return null;
                                        }
                                        if(evt.target.value && evt.target.value > item?.maxCount){
                                            notify("error", `You can't assign more then ${item?.maxCount}`);
                                            return null;
                                        }
                                        handleChangeAsl(evt, key, "labs");
                                    }}
                                />
                            </div> */}
                        </CardContent>
                    </Card>
                    {/* <div className={classes.buttonWrap}>
                        {key === 0 && <AddMoreButton action={'labs'} handleAddNew={handleAddAssignLicence} />}
                        {key > 0 && <RemoveButton index={key} action={'labs'} handleClick={handleRemoveAssignLicence} />}
                    </div> */}
                </div>
            ))
        )
    }

    return (
        <React.Fragment>
            <Grid container spacing={8}>
                <Grid item xs={6}>
                    <OutelinedInput 
                        label="Project Name"
                        placeholder="Project Name"
                        value={formState.project_name}
                        name="project_name"
                        handleChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        className={classes.labelStyle}>
                        Company Name
                    </Typography>
                    <OutlinedSelect
                        val={formState.company_id}
                        name="company_id"
                        placeholder='Company Name'
                        variant="standard"
                        handleChange={handleInputChange}
                        styleOverrides={{ width: '100%', marginTop: 0 }}
                        options={customerList}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        className={classes.labelStyle}>
                        Company Admin
                    </Typography>
                    <OutlinedSelect
                        val={formState.company_admin_id}
                        name="company_admin_id"
                        placeholder='Company Admin'
                        variant="standard"
                        handleChange={handleInputChange}
                        styleOverrides={{ width: '100%', marginTop: 0 }}
                        options={customerAdminList}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        className={classes.labelStyle}>
                        Project Admin
                    </Typography>
                    <OutlinedSelect
                        val={formState.project_admin}
                        name="project_admin"
                        placeholder='Project Admin'
                        variant="standard"
                        handleChange={handleInputChange}
                        styleOverrides={{ width: '100%', marginTop: 0 }}
                        options={projectAdminList}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        className={classes.labelStyle}>
                        Status
                    </Typography>
                    <OutlinedSelect
                        val={formState.status}
                        name="status"
                        placeholder='Select Status'
                        variant="standard"
                        disabled={project_id ? false : true}
                        handleChange={handleInputChange}
                        styleOverrides={{ width: '100%', marginTop: 0 }}
                        options={["Open", "Complete"]}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <OutelinedInput
                        label="Description"
                        placeholder="Description"
                        value={formState.description}
                        name="description"
                        disabled={project_id ? true : false}
                        handleChange={handleInputChange}
                        multiline={true}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4} style={{marginTop: 20}}>
                <Grid item xs={12}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>Assign licenses</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    {getAssessments()}
                </Grid>
                <Grid item xs={3}>
                    {getCourses()}
                </Grid>
                <Grid item xs={3}>
                    {getApprenticeships()}
                </Grid>
                <Grid item xs={3}>
                    {getLabs()}
                </Grid>
            </Grid>
            <div className={classes.placeBtn}>
                <Button onClick={handleCancelCreate} color="primary" className={classes.cancelBtn}>
                    Cancel
                </Button>
                <Button onClick={handleBack} color="primary" className={classes.cancelBtn}>
                    Back
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    className={classes.btn}
                    disabled={
                        !formState.project_name ||
                        !formState.company_id ||
                        !formState.company_admin_id
                    }
                    onClick={formValidationCheck}>
                    {loading ? <CircularProgress size={15} color="secondary" /> : 'Next'}
                </Button>
            </div>
        </React.Fragment>
    );
}
CreateProjectForm.prototypes = {
    handleInputChange: PropTypes.func.isRequired,
    formState: PropTypes.object.isRequired
}
export default CreateProjectForm;