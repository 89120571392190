import React, { createContext, useEffect } from 'react';
import { useAuthDispatch } from './context';
import { logout } from './actions';
import { useHistory } from 'react-router';

export const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const handleLogout = async () => {
    await logout(dispatch);
    history.push('/login');
  };

  useEffect(() => {
    const cookie = getCookie('enterprise_token');
    if (cookie) {
      if (localStorage.getItem('ssoToken') !== cookie) {
        // localStorage.setItem('ssoToken', cookie);
        localStorage.setItem('isLogin', getCookie('isLogin'));
        localStorage.setItem('uuid', getCookie('uuid'));
        localStorage.setItem('company_name', getCookie('company_name'));
        localStorage.setItem('company_id', getCookie('company_id'));
        localStorage.setItem('SSO', getCookie('SSO'));
        localStorage.setItem('session_data', getCookie('session_data'));
        localStorage.setItem('IDP:SSOToken', getCookie('IDP:SSOToken'));
        // localStorage.setItem('role', getCookie('role'));
        localStorage.setItem('sub_role', getCookie('sub_role'));
        localStorage.setItem('ssoToken', getCookie('enterprise_token'));
        console.log("getCookie('enterprise_role')",getCookie('enterprise_role'));
        localStorage.setItem('role', getCookie('enterprise_role'));

        const data = {
          token: cookie,
          SSO: getCookie('SSO'),
          'IDP:SSOToken':getCookie('SSO')
        };

        if (data) {
          localStorage.setItem('currentUser', JSON.stringify(data));
          dispatch({ type: 'LOGIN_SUCCESS', payload: data });
          let url = '/dashboard';
          window.location.href = url;
        }
      }
    } else {
      console.log('Cookies not found',"path",window.location.pathname);
      if(window.location.pathname !== '/login') {
        console.log('inside nested if :: logout');
        handleLogout();
      }
    }
  }, []);

  return <CookieContext.Provider>{children}</CookieContext.Provider>;
};
