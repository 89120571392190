import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 132
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    paddingLeft: '1px',
    paddingRight: '1px'
  },
  select: {
    padding: theme.spacing(2, 3)
  }
}));

const OutlinedSelect = ({
  val,
  name,
  variant,
  handleChange,
  handleBlur,
  options,
  placeholder,
  styleOverrides,
  disabled = false,
  selectStyle,
  multiple = false,
  menuProps = false
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant={variant}
      className={classes.formControl}
      disabled={disabled}
      size="small"
      style={styleOverrides}>
      <Select
        labelId={`label-${placeholder}`}
        id={`select-outlined-${placeholder}`}
        name={name}
        value={val}
        disableUnderline
        color="secondary"
        multiple={multiple}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        MenuProps={menuProps || menuProps}
        style={{
          borderColor: '#838383',
          borderRadius: '25px',
          padding: "6px 18px",
          ...(selectStyle && {...selectStyle})
        }}>
        <MenuItem value="" disabled={multiple}>
          <em>{placeholder || 'Select Value'}</em>
        </MenuItem>
        {options &&
          options.map((item, index) => (
            <MenuItem key={index} value={(item.id || item.uuid) || item}>
              {item.name || item}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

OutlinedSelect.prototypes = {
  val: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  styleOverrides: PropTypes.object,
  disabled: PropTypes.bool,
  IconComponent: PropTypes.node,
  selectStyle: PropTypes.bool,
  multiple: PropTypes.bool
};

export default OutlinedSelect;
