import axios from 'axios';
import '../Sys/config.js';

const apiUrl = global.platformURI;

export class Dashboard {
    constructor() {
        axios.interceptors.request.use(
          function (config) {
            let token = localStorage.getItem('ssoToken');
            config.baseURL = apiUrl;
            config.headers.Authorization = `Token ${token}`;
            return config;
          },
          error => {
            console.log('error.response.status', error);
            return error;
          }
        );
    }

    getDetailDashboardCustomer() {
        return axios.get('main/customeradmindashboard')
            .then(res => res.data);
    }
    getDetailDashboardSuperAdmin() {
        return axios.get('main/superadmindashboard')
            .then(res => res.data);
    }
    getDetailDashboardUser() {
        return axios.get('main/userdashboard')
            .then(res => res.data);
    }
    getPopularCourseGraph() {
      return axios.get('main/popularcoursegraph')
          .then(res => res.data);
    }
    getTopCustomerAndUsers() {
      return axios.get('main/customerlistwithlicensecount')
          .then(res => res.data);
    }
}
