import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { notify, resetThemeColors } from '../../utils';
import OutelinedInput from '../../Components/OutelinedInput';
import { ColorPicker } from 'material-ui-color';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CustomerServices } from '../../Services/CustomerServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useParams } from 'react-router';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Paths } from 'Routes/routePaths';
import commonStyles from 'theme/commonStyles';
import variables from '../../Sys/variable.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import primaryColorImg from '../../Assets/images/preview/pimaryColorImg.png';
import hoverButtonImg from '../../Assets/images/preview/hoverButtonImg.png';
import sideBarColorImg from '../../Assets/images/preview/sideBarColorImg.png';
import successBgColorImg from '../../Assets/images/preview/successBgColorImg.png';

const previewVarKey = {
    colors: primaryColorImg,
    buttonHoverColor: hoverButtonImg,
    sidebarColor: sideBarColorImg,
    bodyColor: '',
    contentColor: '',
    headerColor: '',
    secondryColor: '',
    successMessageBGColor: successBgColorImg,
    sidebarActive: '',
    lblcolor: '',
}

const customerServices = new CustomerServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    },
    imgIcon: {
        width: '150px',
        height: 'auto',
        borderRadius: '50%'
    },
    input: {
        display: 'none'
    },
    icon: {
        position: 'relative'
    },
    iconStyle: {
        borderRadius: '50%',
        color: '#000',
        background: '#ebebeb',
    },
    prevBtn: {
        padding: '12px 25px',
        background: variables.primaryColor,
        fontSize: 11,
        color: 'white',
        borderRadius: '5px',
        "&:hover": {
            background: variables.buttonHoverColor
        }
    }
}));

const initFormState = {
    colors: "#3D7DDD",
    buttonHoverColor: '#083F93',
    sidebarColor: "#ffffff",
    bodyColor: '#F5F5F5',
    contentColor: '#ffffff',
    headerColor: '#ffffff',
    lblcolor: '#4D5766',
    secondryColor: '#0082ca',
    successMessageBGColor: '#ffffff',
    sidebarActive: '#3D7DDD',
    txth1: '16px',
    txth2: '24px',
    txth3: '18px',
    txtp: '16px',
    bodysize: '16px',
    uuid: null,
    sidebarhove: '',
    darkTheme: false,
    darkThemeText: '#4D5766'
};

const colorVarKey = {
    colors: 'primaryColor',
    buttonHoverColor: 'buttonHoverColor',
    sidebarColor: 'sidebarColor',
    bodyColor: 'bodyColor',
    contentColor: 'contentColor',
    headerColor: 'headerColor',
    secondryColor: 'secondryColor',
    successMessageBGColor: 'successMessageBGColor',
    sidebarActive: 'sidebarActive',
    lblcolor: 'labelcolor',
    txth1: 'h1Size',
    txth2: 'h2Size',
    txth3: 'h3Size',
    txtp: 'pSize',
    darkThemeText: 'darkThemeText'
}

function ColorPickerButton({ handleChange, label, type, value, handlePreviewClick }) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography
                variant="subtitle2"
                color="textPrimary"
                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                {label}
            </Typography>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <div className='colorPkrInput'>
                        <CssBaseline />
                        <ColorPicker
                            value={value}
                            onChange={evt => handleChange(evt, type)}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        className={classes.prevBtn}
                        color="primary"
                        onClick={(evt) => handlePreviewClick(type)}
                    >
                        Preview
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const ThemeEditor = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState({ ...initFormState });
    const [openPreviewImg, setOpenPreviewImg] = useState(false);
    const [previewImg, setPreviewImg] = useState(null);

    useEffect(() => {
        getUserProfile();
        setFormState({ ...formState, uuid: id });
    }, []);

    async function getUserProfile() {
        try {
            const themeData = await customerServices.getCustomerTheme(id);
            resetThemeColors(themeData?.data?.config?.colors || null);
            if (themeData?.data?.config) {
                const colors = themeData?.data?.config?.colors;
                setFormState({
                    ...formState,
                    colors: colors.primary_color,
                    buttonHoverColor: colors.buttonHover_color,
                    sidebarColor: colors.sidebar_color,
                    bodyColor: colors.body_color,
                    contentColor: colors.contentColor,
                    headerColor: colors.headerColor,
                    lblcolor: colors.label_color,
                    secondryColor: colors.secondary_color,
                    successMessageBGColor: colors.successMessageBG_color,
                    sidebarActive: colors.sidebarActive,
                    txth1: colors.h1size,
                    txth2: colors.h2size,
                    txth3: colors.h3size,
                    txtp: colors.psize,
                    bodysize: colors.bodysize,
                    darkTheme: colors.darkTheme,
                    darkThemeText: colors.darkThemeText
                });
            }
        } catch (e) {
            resetThemeColors(null);
        }
    }

    const formValidationCheck = () => {
        return handleFormSubmit();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const userTheme = {
            config: {
                colors: {
                    primary_color: formState.colors,
                    buttonHover_color: formState.buttonHoverColor,
                    sidebar_color: formState.sidebarColor,
                    body_color: formState.bodyColor,
                    contentColor: formState.contentColor,
                    headerColor: formState.headerColor,
                    secondary_color: formState.secondryColor,
                    successMessageBG_color: formState.successMessageBGColor,
                    sidebarActive: formState.sidebarActive,
                    label_color: formState.lblcolor,
                    h1size: formState.txth1,
                    h2size: formState.txth2,
                    h3size: formState.txth3,
                    psize: formState.txtp,
                    bodysize: formState.bodysize,
                    darkTheme: formState.darkTheme,
                    darkThemeText: formState.darkThemeText,
                }
            }
        };
        let result = await customerServices.addCustomertheme(userTheme, id);
        if (result && result.status) {
            notify("success", result.message);
            history.push('/dashboard');
        } else {
            notify("error", result && result.message ? result.message : 'Something Went Wrong')
        }
        setLoading(false);
    }

    const handleChangeColor = (value, type) => {
        setFormState({
            ...formState,
            [type]: '#' + value.hex
        });
        document.documentElement.style.setProperty(
            `--${colorVarKey[type]}`,
            '#' + value.hex
        );
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value
        });
        document.documentElement.style.setProperty(
            `--${colorVarKey[name]}`,
            value
        );
    };

    const handleChangeDarkTheme = (evt) => {
        let newConfig = { ...formState };
        if (evt.target.checked) {
            let newClr = {
                bodyColor: '#272727',
                contentColor: '#171717',
                sidebarColor: '#474747',
                lblcolor: '#FFFFFF',
                sidebarActive: '#070707',
                headerColor: '#474747',
                darkThemeText: '#FFFFFF'
            };
            for (const key in newClr) {
                if (Object.hasOwnProperty.call(newClr, key)) {
                    const clr = newClr[key];
                    newConfig[key] = clr;
                    document.documentElement.style.setProperty(
                        `--${colorVarKey[key]}`,
                        clr
                    );
                }
            }
            setFormState({ ...newConfig, darkTheme: evt.target.checked });
        } else {
            resetThemeColors(null);
            setFormState({ ...initFormState });
        }
    }

    const handleClickOpen = (type) => {
        setOpenPreviewImg(true);
        setPreviewImg(previewVarKey[type] ? previewVarKey[type] : null);
    };

    const handleClose = () => {
        setOpenPreviewImg(false);
        setPreviewImg(null);
    };


    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <Typography
                                variant="h4"
                                className={classes.heading}
                                color="textPrimary">
                                Theme Editor
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch onChange={handleChangeDarkTheme} checked={formState.darkTheme}
                                    name="darkTheme" />}
                                label="Dark Theme"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.colors}
                                label="Select Primary Color"
                                type={"colors"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.buttonHoverColor}
                                label="Select Button Hover Color"
                                type={"buttonHoverColor"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.sidebarColor}
                                label="Select Side Bar Color"
                                type={"sidebarColor"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.bodyColor}
                                label="Select Body Color"
                                type={"bodyColor"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.contentColor}
                                label="Select Content Color"
                                type={"contentColor"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.headerColor}
                                label="Select Header Color"
                                type={"headerColor"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.lblcolor}
                                label="Label Color"
                                type={"lblcolor"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.successMessageBGColor}
                                label="Select Success Message BG Color"
                                type={"successMessageBGColor"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ColorPickerButton
                                handleChange={handleChangeColor}
                                handlePreviewClick={handleClickOpen}
                                value={formState.sidebarActive}
                                label="Select Sidebar Hover/Active Color"
                                type={"sidebarActive"}
                            />
                        </Grid>
                    </Grid>
                    <Typography
                        variant="h4"
                        style={{ marginTop: 34, marginBottom: 12, color: variables.labelcolor }}
                        color="textPrimary">
                        Typography
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <OutelinedInput
                                label={"Header Text (e.g.10px)"}
                                placeholder="Header Text (e.g.10px)"
                                value={formState.txth3}
                                name="txth3"
                                handleChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <OutelinedInput
                                label={"Paragraph (e.g.10px)"}
                                placeholder="Paragraph (e.g.10px)"
                                value={formState.txtp}
                                name="txtp"
                                handleChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.placeBtn}>
                        <Button onClick={() => history.push(Paths.Profile)} color="primary" className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            setFormState({...initFormState});
                            resetThemeColors();
                        }} color="primary" className={classes.cancelBtn}>
                            Reset
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            className={classes.btn}
                            disabled={loading}
                            onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={15} color="secondary" /> : 'Save'}
                        </Button>
                    </div>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth="lg"
                    open={openPreviewImg}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        {previewImg ?
                            <img
                                alt='Preview'
                                style={{ width: '100%' }}
                                width="900px"
                                height="600px"
                                src={previewImg}
                            /> : 
                            <Typography 
                                style={{color: 'red', textAlign: 'center', marginTop: 20, marginBottom: 20 }} 
                                variant="h4" 
                                color="textSecondary">
                                Preview image not available
                            </Typography>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </main>
        </Layout>
    );
}

export default ThemeEditor;