import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import variables from '../../Sys/variable.scss';

const ChartContainer = ({
  title,
  children,
  childStyleOveride,
  styleOveride
}) => {
  
  return (
    <Grid style={styleOveride ? styleOveride : { marginTop: 32 }}>
      <Grid container justifyContent={'space-between'}>
        {title &&
          <Grid item xs={12}>
            <Typography variant={'h4'} style={{ color: variables.labelcolor }}>{title}</Typography>
          </Grid>
        }
      </Grid>
      <Grid style={childStyleOveride ? childStyleOveride : null}>{children}</Grid>
    </Grid>
  );
};

ChartContainer.propTypes = {
  title: PropTypes.string.isRequired,
  filterList: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired
};

export default ChartContainer;