import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Gauge from 'react-svg-gauge';

const useStyles = makeStyles((theme) => ({
    rechartBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    }
}));

const GaugeComponent = ({ score }) => {
    const classes = useStyles();
    return (
        <div className={classes.rechartBox}>
            <Gauge
                value={parseInt(score)}
                max={5}
                color="#143177"
                label={''}
                width={410}
                height={400}
                topLabelStyle={{ display: 'none' }}
                valueLabelStyle={{ fontSize: 50 }}
                valueFormatter={number => `${number}`}
            />
        </div>
    )
}

const DynamicPage = ({ title, description, score, list, heading }) => {
    
    const listItems = list && list.map((item) =>
        <li key={item}>
            {item}
        </li>
    )

    return (
        <Grid item xs={12} md={6}>
            <h3 style={{ color: '#163277' }}>{title}</h3>
            <Grid container>
                <Grid item xs={12}>
                    <p>{description}</p>
                </Grid>
                <Grid item xs={12}>
                    <GaugeComponent score={score} />
                </Grid>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: 'center', color: "black" }}>{heading}</h2>
                    <h3 style={{ color: 'rgb(19 49 119)', fontWeight: 700 }}>Key factors include</h3>
                    <ol>{listItems}</ol>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DynamicPage;