import axios from 'axios';
import "../Sys/config.js";

var apiUrl = global.platformURI;

export class DeploymentServices {
    constructor() {
        axios.interceptors.request.use(function (config) {
            let token = localStorage.getItem('ssoToken');
            config.headers.Authorization = `Token ${token}`;
            return config;
        }, error => {
            console.log('error.response.status', error);
            return error;
        });
    }

    getAllDeployment(url) {
        return axios.get(url)
            .then(res => res.data);
    }

    addDeployment(payload, uuid) {
        if (uuid) {
            return axios.put(`services/deploymentdetail/${uuid}`, payload).then(res => res.data);
        }
        return axios.post(apiUrl + "services/createdeployment", payload)
            .then(res => res.data)
    }

    getDeploymentDetail(id) {
        return axios.get(apiUrl + `services/deploymentdetail/${id}`)
            .then(res => res.data)
    }


}
