import React from 'react';
import { makeStyles, hexToRgb } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    searchBox: {
        border: "1px solid #808080",
        borderRadius: 5,
        padding: '12px 18px',
        '&.Mui-focused': {
            backgroundColor: '#fff',
            // boxShadow: `${hexToRgb(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            // borderColor: theme.palette.primary.main,
        },
    }
}));

const SearchBox = ({handleSearch, handleChange, value, styleOverrides, placeholder = null}) => {

    const classes = useStyles();

    return (
        <form onSubmit={handleSearch}>
            <TextField
                id="input-with-icon-textfield"
                placeholder={placeholder || "Search"}
                variant="standard"
                size="small"
                autoComplete={false}
                value={value}
                style={styleOverrides || null}
                onChange={handleChange}
                InputProps={{
                    className: classes.searchBox,
                    disableUnderline: true,
                    autoComplete: false,
                    endAdornment: (<InputAdornment position="end"><SearchIcon style={{ cursor: "pointer", }} onClick={handleSearch} /></InputAdornment>)
                }}
            />
        </form>
    );
};

SearchBox.prototypes = {
    handleChange: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    styleOverrides: PropTypes.object,
};

export default SearchBox;