import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';
import { DateRangePicker } from "materialui-daterange-picker";

export default function DateRangePickerModal({ open, handleClose, handleChangeDate, dateRange }) {

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <DateRangePicker
                                open={true}
                                minDate={ ( d => new Date(d.setDate(d.getDate()-1)) )(new Date) }
                                initialDateRange={dateRange || null}
                                onChange={handleChangeDate}
                                wrapperClassName={"dateRangeWrapper"}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant="outlined" onClick={handleClose} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}