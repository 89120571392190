import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Button } from '@material-ui/core/';
import { useStyle as onboardingStyle  } from '../../../CustomerOnBoarding';
import { checkValidEmail, notify } from 'utils';
import { UserServices } from '../../../../Services/UserServices';
import CreateUserForm from '../../../../Pages/Users/components/CreateUserForm';
import { useAuthDispatch, useAuthState } from '../../../../Context';
import { ERROR_MESSAGE, REQUIRED_ERROR } from '../../../../utils/constants';
import { useParams } from 'react-router';
import variables from '../../../../Sys/variable.scss';

const userServices = new UserServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        background: '#3D7DDD',
        fontSize: '17px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#3d7ddd99"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0'
    },
    btnPwd: {
        background: '#3D7DDD',
        color: 'white',
        borderRadius: '25px',
        padding: '18px',
        marginTop: '-3px',
        marginLeft: '-42px',
        "&:hover": {
            background: "#3D7DDD"
        }
    },
}));

export const initFormState = { firstName: "", lastName: "", userName: "", email: "", location: "", role: 'customeradmin' };

const CustomerAdmin = ({
    activeStep, 
    handleBack, 
    handleNext,
    formBackupState,
    handleUpdateBackupFormData
}) => {

    const classes = useStyles();
    const onBorningClasses = onboardingStyle();
    const { id } = useParams();
    const dispatch = useAuthDispatch();
    const { userDetails } = useAuthState(dispatch);

    const [companyList, setCompanyList] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [formState, setFormState] = React.useState({ ...initFormState });
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [emailDisable, setEmailDisable] = React.useState(false);
    const [userNameDisable, setUserNameDisable] = React.useState(false);

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    React.useEffect(() => {
        getCompanyList();
        if(id){
            getCustomerAdminUser();
        }
    }, []);
    
    async function getCustomerAdminUser(){
        const customerRes = await userServices.customerAdminUser(id, 'customeradmin');
        if(customerRes && customerRes.data){
            const reponseData = customerRes.data;
            localStorage.setItem('created_user_id', reponseData ? reponseData.uuid : '');
            setFormState({
                email: reponseData?.company_email || '',
                location: reponseData?.location || '',
                firstName: reponseData?.firstName || '',
                lastName: reponseData?.lastName || '',
                userName: reponseData?.userName || '',
                role: reponseData?.role || '',
                is_active: true
            });
            setUserNameDisable(reponseData.userName ? true : false);
            setEmailDisable(reponseData.company_email ? true : false);
        }
    }
    
    React.useEffect(() => {
        if(formBackupState){
            setFormState({...formBackupState});
        }
    }, [formBackupState]);

    const getCompanyList = async () => {
        let uuid = id || localStorage.getItem('customerUUID');
        const compResult = await userServices.customerDropdown(uuid);
        if(compResult && compResult.data){
            localStorage.setItem('getAllCustomer', false);
            setCompanyList(compResult.data);
            if(compResult.data.length){
                setCustomers(compResult.data);
            }
        }
    }

    const handleChangeCustomer = (value) => {
        setCustomers(value);
    }

    const formValidationCheck = (evt) => {
        if (!formState.firstName || !formState.lastName || !formState.userName || !formState.email || !formState.location) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        if(!checkValidEmail(formState.email)){
            notify("error", ERROR_MESSAGE.VALID_EMAIL);
            return null;
        }
        return handleFormSubmit(evt);
    }

    const handleFormSubmit = async (evt) => {
        setSubmitLoading(true);
        let uuid = localStorage.getItem('created_user_id') || null;
        let customersIds = customers.map(cs => cs.uuid);
        let payload = { ...formState };
        if(id){
            if(customersIds.length){
                payload.customers = customersIds;
            }
            delete payload.is_active;
        }else{
            payload = {...payload, is_active: true, customers: customersIds};
        }
        payload.project_id = '';
        const result = await userServices.addUser(payload, uuid);
        if (result && result.status) {
            localStorage.setItem('created_user_id', result.data.uuid);
            notify("success", result.message);
            setSubmitLoading(false);
            handleUpdateBackupFormData({...formState}, 'customerAdmin');
            handleNext(evt);
        } else {
            setSubmitLoading(false)
            notify("error", (result && result.message) ? result.message : 'Something Went Wrong');
        }
        setSubmitLoading(false);
    }

    return (
        <>
            <div style={{marginBottom: 36}}>
                <CreateUserForm 
                    formState={formState} 
                    handleChangeCustomer={handleChangeCustomer} 
                    handleInputChange={handleInputChange} 
                    companyList={companyList}
                    emailDisable={emailDisable}
                    userNameDisable={userNameDisable}
                    userDetails={userDetails}
                    customers={customers}
                />
            </div>
            <div className={onBorningClasses.buttons}>
                <div className={onBorningClasses.wrapper}>
                    {activeStep !== 0 && (
                        <Button 
                        onClick={handleBack} 
                        variant="contained" 
                        color="primary" 
                        className={onBorningClasses.cancelBtn}>
                            Back
                        </Button>
                    )}
                    <Button
                        type={"button"}
                        onClick={formValidationCheck}
                        variant="contained"
                        color="primary"
                        disabled={submitLoading}
                        className={onBorningClasses.button}
                    >
                        {submitLoading ? (
                            <CircularProgress
                                size={18}
                                className={classes.buttonProgress}
                            />
                        ) : 'Next'}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CustomerAdmin;
