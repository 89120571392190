import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
const { v4: uuid } = require('uuid');

const useStyles = makeStyles({
    table: {
        width: '100%',
        textAlign: 'center'
    },
    MuiTableCell: {
        textAlign: 'center'
    },
    tableStle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    }
});
const DynamicReport = ({ allwords, heading }) => {
    const classes = useStyles();
    return (
        <div className={classes.tableStle}>
            <h4>{heading}</h4>
            <TableContainer style={{ width: '30%', minWidth: 250 }}>
                <Table
                    className={classes.table}
                    aria-label="simple table"
                >
                    <TableBody>
                        <TableRow key={uuid()}>
                            <TableCell
                                component="th"
                                scope="row"
                                className={classes.MuiTableCell}>
                                Initiating
                            </TableCell>
                            <TableCell align="right" className={classes.MuiTableCell}>
                                {allwords ? allwords.Initiating : 0}
                            </TableCell>
                        </TableRow>
                        <TableRow key={uuid()}>
                            <TableCell
                                component="th"
                                scope="row"
                                className={classes.MuiTableCell}>
                                Emerging
                            </TableCell>
                            <TableCell align="right" className={classes.MuiTableCell}>
                                {allwords ? allwords.Emerging : 0}
                            </TableCell>
                        </TableRow>
                        <TableRow key={uuid()}>
                            <TableCell
                                component="th"
                                scope="row"
                                className={classes.MuiTableCell}>
                                Performing
                            </TableCell>
                            <TableCell align="right" className={classes.MuiTableCell}>
                                {allwords ? allwords.Performing : 0}
                            </TableCell>
                        </TableRow>
                        <TableRow key={uuid()}>
                            <TableCell
                                component="th"
                                scope="row"
                                className={classes.MuiTableCell}>
                                Advancing
                            </TableCell>
                            <TableCell align="right" className={classes.MuiTableCell}>
                                {allwords ? allwords.Advancing : 0}
                            </TableCell>
                        </TableRow>
                        <TableRow key={uuid()}>
                            <TableCell
                                component="th"
                                scope="row"
                                className={classes.MuiTableCell}>
                                Leading
                            </TableCell>
                            <TableCell align="right" className={classes.MuiTableCell}>
                                {allwords ? allwords.Leading : 0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
};

export default DynamicReport;
