import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from 'Components/layouts';
import { notify } from 'utils';
import Input from '@material-ui/core/Input';
import { REQUIRED_ERROR } from 'utils/constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paths } from 'Routes/routePaths'
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExperimentServices } from 'Services/ExperimentServices';
import variables from '../../../Sys/variable.scss';
import commonStyles from 'theme/commonStyles';

const experimentService = new ExperimentServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    },
    btnPwd: {
        background: '#3D7DDD',
        color: 'white',
        borderRadius: '25px',
        padding: '18px',
        marginTop: '-3px',
        marginLeft: '-42px',
        "&:hover": {
            background: "#3D7DDD"
        }
    },
}));

const initFormState = { name: '', link: '', duration: null, description: '' };

const AddExperiment = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const history = useHistory();
    const { id } = useParams();

    const [loading, setLoading] = React.useState(false);
    const [formState, setFormState] = React.useState({ ...initFormState });
    const [update, setUpdate] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            getExperimentDetails()
        }
    }, []);

    async function getExperimentDetails(){
        let data = await experimentService.getExperimentDetail(id)
        if (data && data.data[0]) {
            let userData = data.data[0]
            setUpdate(true);
            setFormState({...userData});
        }
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const formValidationCheck = () => {
        if (!formState.name || !formState.link) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        return handleFormSubmit();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const payload = {...formState};
        let uuid = (update) ? id : null;
        await experimentService.addExperiment(payload, uuid).then(
            data => {
                notify('success', data.message)
                setLoading(false);
                history.push(Paths.Experiments);
            },
            error => {
                setLoading(false)
                notify("error", error.response.data.message ? error.response.data.message : 'Something Went Wrong');
                console.log('error.response.status', error);
            }
        );
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <div className={classes.pageHeader}>
                        <Typography
                            variant="subtitle2"
                            className={classes.heading}
                            color="textPrimary">
                            {update ? "Update" : "Create" } Experiment
                        </Typography>
                    </div>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Name *
                            </Typography>
                            <Input disableUnderline value={formState.name}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Name"}
                                style={{ width: '100%' }}
                                name="name"
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Link *
                            </Typography>
                            <Input disableUnderline value={formState.link}
                                type={'text'}
                                placeholder={"Link"}
                                className={classes.inputStyle}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="link"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Est. Duration in hours
                            </Typography>
                            <Input disableUnderline value={formState.duration}
                                type={'text'}
                                name="duration"
                                className={classes.inputStyle}
                                placeholder={"Duration"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Description
                            </Typography>
                            <Input disableUnderline value={formState.description}
                                multiline
                                rows={4}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Description"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="description"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.placeBtn}>
                        <Button onClick={() => history.push(Paths.Experiments)} color="primary" className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button color="primary" variant="outlined" className={classes.btn}
                            onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={15} color="secondary" /> : update ? "Update" : "Save"}
                        </Button>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default AddExperiment;