import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import GenericTable from '../../../../Components/GenericTable';
import { Pagination } from '@material-ui/lab';
import CustamizedFilters from '../../../../Components/CustamizedFilters';
import {
    GetApp as GetAppIcon,
    Print,
    Settings,
} from '@material-ui/icons';
import { CircularProgress, IconButton, Input } from '@material-ui/core';
import FilterModal from '../FilterModal';

const useStyles = makeStyles((theme) => ({
    title: {
        color: "#4D5766",
        wordSpacing: 2,
        fontSize: 18,
    }
}));


const DetailsAnalyticsTab = ({ 
    title, 
    filters, 
    allFilterState, 
    handleChange, 
    type, 
    pageName,
    columns, 
    loading, 
    handleItemClick, 
    list,
    label, 
    handlePageChange, 
    handleSubmitFilter,
    handleGoToPage,
    handleDownload,
    downloadReportLoading,
    handlePrint,
    printReportLoading
}) => {
 
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [goToPage, setGoToPage] = useState('');
    const classes = useStyles();

    useEffect(() => {
        setGoToPage('');
    }, [list]);

    const handleClose = value => {
        setOpen(false);
        setSelectedValue(value);
    };

    const componentRef = useRef();
    
    
    return (
        <>
            <Grid container ref={componentRef}>
                <Grid item sm="8" >
                    <h4 className={classes.title}>{title}</h4>
                </Grid>
                
                <Grid sm="4" item>
                    <h1 id="table_h_icon" style={{ float: "right" }}>
                        {/* <IconButton aria-label="Mail">
                            <Mail color="primary" />
                        </IconButton> */}
                        <IconButton onClick={handlePrint} aria-label="Print">
                            {printReportLoading[type] ? <CircularProgress color='primary' size={18}  /> : 
                            <Print color="primary" /> }
                        </IconButton>
                        <IconButton aria-label="ExportCSV">
                            {downloadReportLoading[type] ? <CircularProgress color='primary' size={18}  /> : 
                            <GetAppIcon color="primary" style={{ cursor: 'pointer' }} onClick={handleDownload} />}
                            {/* <ExportCSV csvData={list} fileName={"reportData"} /> */}
                        </IconButton>
                        {/* <IconButton aria-label="Settings">
                            <Settings color="primary" />
                        </IconButton> */}
                    </h1>
                </Grid>
            </Grid>
            <Grid container ref={componentRef}>
                <CustamizedFilters
                    type={type}
                    filters={filters}
                    pageName={pageName}
                    allFilterState={allFilterState}
                    handleChange={handleChange}
                    handleSubmitFilter={handleSubmitFilter}
                />
                <GenericTable
                    columns={columns}
                    loading={loading}
                    left={true}
                    handleItemClick={handleItemClick}
                    list={list}
                    applyMinWidth={false}
                    label={label}
                />
            </Grid>
            <Grid container style={{ marginTop: 22, marginBottom: 20 }} >
                <div style={{display: 'flex', margin: '0 auto'}}>
                    {filters?.data_count > 0 && <Pagination
                        onChange={handlePageChange}
                        showFirstButton 
                        showLastButton
                        count={parseInt(Math.ceil(filters.data_count / 10))}
                        variant="outlined"
                        page={(allFilterState?.pageno) ? parseInt(allFilterState.pageno) : 1}
                        shape="rounded"
                    />}
                    {(filters?.data_count > 5) && <Input
                        label={"Go to Page"}
                        disableUnderline
                        style={{ minWidth: 110, width: 110, marginLeft: 15, borderRadius: 8, padding: 8 }}
                        placeholder='Page'
                        onKeyDown={evt => {
                            if (evt.key === 'Enter') {
                                handleGoToPage(parseInt(goToPage), type);
                            }
                        }}
                        onChange={evt => {
                            if(evt.target.value && parseInt(evt.target.value) < parseInt(Math.ceil(filters.data_count / 10))){
                                setGoToPage(parseInt(evt.target.value));
                            }else{
                                setGoToPage('');
                            }
                            return null;
                        }}
                        type={'number'}
                        value={goToPage}
                    /> }
                </div>
            </Grid>
            <FilterModal
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                allColumns={columns}
            />
        </>
    );
}

export default DetailsAnalyticsTab;