export const timezone = [
    {
      id: '-12',
      name: '(GMT -12:00) Eniwetok, Kwajalein'
    },
    {
      id: '-11',
      name: '(GMT -11:00) Midway Island, Samoa'
    },
    {
      id: '-10',
      name: '(GMT -10:00) Hawaii'
    },
    {
      id: '-9',
      name: '(GMT -9:00) Alaska'
    },
    {
      id: '-8',
      name: '(GMT -8:00) Pacific Time (US & Canada)'
    },
    {
      id: '-7',
      name: '(GMT -7:00) Mountain Time (US & Canada)'
    },
    {
      id: '-6',
      name: '(GMT -6:00) Central Time (US & Canada), Mexico City'
    },
    {
      id: '-5',
      name: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima'
    },
    {
      id: '-4',
      name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'
    },
    {
      id: '-3.5',
      name: '(GMT -3:30) Newfoundland'
    },
    {
      id: '-3',
      name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown'
    },
    {
      id: '-2',
      name: '(GMT -2:00) Mid-Atlantic'
    },
    {
      id: '-1',
      name: '(GMT -1:00) Azores, Cape Verde Islands'
    },
    {
      id: '0',
      name: '(GMT) Western Europe Time, London, Lisbon, Casablanca'
    },
    {
      id: '1',
      name: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris'
    },
    {
      id: '2',
      name: '(GMT +2:00) Kaliningrad, South Africa'
    },
    {
      id: '3',
      name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg'
    },
    {
      id: '3.5',
      name: '(GMT +3:30) Tehran'
    },
    {
      id: '4',
      name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'
    },
    {
      id: '4.5',
      name: '(GMT +4:30) Kabul'
    },
    {
      id: '5',
      name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'
    },
    {
      id: '5.5',
      name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
    },
    {
      id: '5.75',
      name: '(GMT +5:45) Kathmandu'
    },
    {
      id: '6',
      name: '(GMT +6:00) Almaty, Dhaka, Colombo'
    },
    {
      id: '7',
      name: '(GMT +7:00) Bangkok, Hanoi, Jakarta'
    },
    {
      id: '8',
      name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'
    },
    {
      id: '9',
      name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'
    },
    {
      id: '9.5',
      name: '(GMT +9:30) Adelaide, Darwin'
    },
    {
      id: '10',
      name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok'
    },
    {
      id: '11',
      name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'
    },
    {
      id: '12',
      name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka'
    }
];