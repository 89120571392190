import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { notify } from '../../utils';
import Input from '@material-ui/core/Input';
import { REQUIRED_ERROR } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paths } from '../../Routes/routePaths'
import { useHistory, useParams } from "react-router-dom";
import { DeploymentServices } from '../../Services/DeploymentServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import variables from '../../Sys/variable.scss';

const deploymentServices = new DeploymentServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 20
    }
}));

const initFormState = { name: "", repository: "", description: "" };

const AddDeployment = () => {

    const classes = useStyles();
    const history = useHistory();

    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [formState, setFormState] = React.useState({ ...initFormState });

    React.useEffect(() => {
        if (id) {
            getDeploymentDetails();  
        }
    }, []);

    const getDeploymentDetails = async () => {
        let data = await deploymentServices.getDeploymentDetail(id)

        if (data && data.data[0]) {
            let formData = data.data[0]
            setUpdate(true)
            setFormState({
                name: formData.name, repository: formData.repository,
                description: formData.description
            });
        }
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const formValidationCheck = () => {
        if (!formState.name || !formState.repository) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        return handleFormSubmit();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const payload = {...formState};
        const result = update ? await deploymentServices.addDeployment(payload, id) : await deploymentServices.addDeployment(payload)
        if (result && result.status) {
            notify("success", result.message)
            history.push(Paths.Deployments);
        } else {
            setLoading(false)
            notify("error", (result && result.message) ? result.message : 'Something Went Wrong');
        }
    }

    return (
        <Layout>
            <main>
                <div style={{
                    background: "#FFFFFF",
                    boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
                    borderRadius: 5,
                    padding: 20,
                    minHeight: '80vh'
                }}>
                    <div className={classes.pageHeader}>
                        <Typography
                            variant="subtitle2"
                            className={classes.heading}
                            color="textPrimary">
                            {update ? 'Update Deployment' : 'Create Deployment'}
                        </Typography>
                    </div>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Name *
                            </Typography>
                            <Input disableUnderline value={formState.name}
                                type={'text'}
                                name="name"
                                className={classes.inputStyle}
                                placeholder={"Name"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Repository *
                            </Typography>
                            <Input disableUnderline value={formState.repository}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Repository"}
                                style={{ width: '100%' }}
                                name="repository"
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Description
                            </Typography>
                            <Input 
                                disableUnderline 
                                value={formState.description}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Description"}
                                style={{ width: '100%' }}
                                multiline={true}
                                rows={3}
                                color={'secondary'}
                                name="description"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.placeBtn}>
                        <Button 
                            onClick={() => history.push(Paths.Deployments)} 
                            color="primary" 
                            className={classes.cancelBtn}>
                                Cancel
                        </Button>
                        <Button color="primary" variant="outlined" className={classes.btn}
                            onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={15} color="secondary" /> : update ? 'Update' : 'Save'}
                        </Button>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default AddDeployment;