import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import AssignLicence from "./Components/AssignLicence";
import Customer from "./Components/Customer";
import CustomerAdmin from "./Components/CustomerAdmin";
import Project from "./Components/Project";
import Users from "./Components/Users";
import AllocateLicence from "./Components/AllocateLicence";
import ProjectSummary from "./Components/ProjectSummary";
import {
    Stepper,
    Step,
    Grid, 
    Typography,
} from '@material-ui/core';
import { CustomerServices } from '../../Services/CustomerServices';
import { ProjectServices } from '../../Services/ProjectServices';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory, useParams } from 'react-router';
import { Paths } from 'Routes/routePaths';
import { notify } from 'utils';
import commonStyles from 'theme/commonStyles';

const customerServices = new CustomerServices();
const projectServices = new ProjectServices();

export const useStyle = makeStyles((theme) => ({
    root: {
        border: `8px solid ${theme.palette.common.white}`,
        margin: 16,
        padding: "36px 0 0",
        background: `rgba(255,255,255,0.9)`,
        boxShadow: [
            `0px 16px 26px -10px ${theme.palette.primary.main}99`,
            theme.shadows[15]
        ]
    },
    stepper: {
        padding: theme.spacing(2, 0, 2),
        overflow: "auto",
        width: '100%'
    },
    navigation: {
        width: 110,
        fontSize: 12,
        [theme.breakpoints.down("xs")]: {
            fontSize: 10,
            width: 90
        }
    },
    prevBtn: {
        color: theme.palette.grey[700],
        background: theme.palette.common.white,
        boxShadow: theme.shadows[5]
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        borderRadius: '5px',
        minWidth: 120
    },
    cancelBtn: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        background: '#B7B7B7',
        color: 'white',
        borderRadius: '5px',
        "&:hover": {
            background: "#454343"
        },
        minWidth: 120
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    activeArrow: {
        position: 'absolute',
        top: 7,
        right: -12,
        zIndex: 9999
    }
}));

const RenderStepContent = ({
    step,
    handleNext,
    handleBack,
    countryList,
    apprenticeshipList,
    coursesList,
    handleUpdateBackupFormData,
    formBackupState,
    assessmentList,
    handleEditTab,
    labsList
}) => {
    
    switch (step) {
        case 0:
            return <Customer 
            handleNext={handleNext} 
            countryList={countryList} 
            activeStep={step} 
            handleBack={handleBack}
            formBackupState={formBackupState.customer}
            handleUpdateBackupFormData={handleUpdateBackupFormData}
            />;
        case 1:
            return <AssignLicence 
                handleNext={handleNext} 
                activeStep={step} 
                handleBack={handleBack} 
                apprenticeshipList={apprenticeshipList} 
                coursesList={coursesList} 
                labsList={labsList}
                assessmentList={assessmentList} 
                formBackupState={formBackupState.assignLicence}
                handleUpdateBackupFormData={handleUpdateBackupFormData}
            />;
        case 2:
            return <CustomerAdmin 
                handleNext={handleNext} 
                activeStep={step} 
                handleBack={handleBack}
                formBackupState={formBackupState.customerAdmin}
                handleUpdateBackupFormData={handleUpdateBackupFormData}
            />;
        case 3:
            return <Project 
                handleNext={handleNext} 
                activeStep={step} 
                handleBack={handleBack}
                formBackupState={formBackupState.project}
            />;
        case 4:
            return <Users 
                handleNext={handleNext} 
                activeStep={step} 
                handleBack={handleBack}
                formBackupState={formBackupState.user}
                handleUpdateBackupFormData={handleUpdateBackupFormData}
            />;
        case 5:
            return <AllocateLicence 
                handleNext={handleNext} 
                activeStep={step} 
                handleBack={handleBack}
                formBackupState={formBackupState.allocateLicenses} 
                handleUpdateBackupFormData={handleUpdateBackupFormData}
            />;
        case 6:
            return <ProjectSummary 
                handleNext={handleNext} 
                activeStep={step} 
                handleBack={handleBack}
                handleEditTab={handleEditTab}
            />;
        default:
            return null;
    }
}

const tabs = ["Company", "Assign Licence", "Customer Admin", "Projects", "Users", "Allocate Licenses", "Project Summary"];

const CustomerOnBoarding = () => {

    const classes = useStyle();
    const commonClasses = commonStyles();
    const { id: uuid } = useParams();
    const history = useHistory()

    const [countryList, setCountryList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [apprenticeshipList, setApprenticeshipList] = useState([]);
    const [assessmentList, setAssessmentList] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [labsList, setLabsList] = useState([]);
    const [formBackupState, setFormBackupState] = useState({
        customer: null,
        assignLicence: null,
        customerAdmin: null,
        project: null,
        user: null,
        allocateLicenses: null,
        projectSummery: null
    });

    React.useEffect(() => {
        getCounties();
        getAssignLicenceDropdown();
        clearLocalStorageItem();
        if(uuid){
            localStorage.setItem('editstep', 0);
            localStorage.setItem('getAllCustomer', true);
        }
    }, []);

    const handleEditTab = (action) => {
        if(action === 'project'){
            localStorage.setItem('editstep', 3);
            localStorage.setItem('project_edit', true);
            setActiveStep(3);
        }else{
            localStorage.setItem('editstep', 4);
            setActiveStep(4);
        }
    }
    
    const getCounties = async () => {
        const countries = await customerServices.getCounties();
        setCountryList(countries.country_list);
    }

    const clearLocalStorageItem = () => {
        localStorage.removeItem('editstep');
        localStorage.removeItem('assign_lecense');
        localStorage.removeItem('refreshlic');
        localStorage.removeItem('cstmsrSaveFlow');
        localStorage.removeItem('customerUUID');
        localStorage.removeItem('getAllCustomer');
        localStorage.removeItem('created_user_id');
    }

    const getAssignLicenceDropdown = async () => {
        const apprenticeshipResult = await projectServices.getApprenticeshipsList();
        if (apprenticeshipResult && apprenticeshipResult.data) {
            setApprenticeshipList(apprenticeshipResult.data);
        }
        const assesmentResult = await projectServices.getAssessmentsList();
        if (assesmentResult && assesmentResult.data) {
            const validAss = ["Digital Readiness Assessment", "GC Index", "Work Readiness"];
            setAssessmentList(assesmentResult.data ? assesmentResult.data.filter(ass => validAss.includes(ass.name)) : []);
        }
        const courseRes = await projectServices.getCoursesList();
        if (courseRes && courseRes.data) {
            setCoursesList(courseRes.data.map((course) => {
                let courseName =  (course.provider === "Xpert Skills") ? `${course.course} - ${course.provider}`  : course.course;
                return {id: course.uuid, name: courseName};
            }));
        }
        const labsRes = await projectServices.getLabsList(null, '?limit=10000');
        if (labsRes && labsRes.results) {
            setLabsList(labsRes.results.map((lab) => {
                return {id: lab.uuid, name: lab.name};
            }));
        }
    };

    const isLastStep = activeStep === tabs.length - 1;

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        localStorage.setItem('editstep', activeStep - 1);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isLastStep) {
            setActiveStep(activeStep + 1);
            return null;
        } else {
            notify("success", `Customer ${(uuid) ? 'updated' : 'created' } Successfully`)
            history.push(Paths.Customers);
        }
    };

    const handleUpdateBackupFormData = (formData, action) => {
        setFormBackupState({...formBackupState, [action]: formData});
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper} style={{padding: 0}}>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {tabs.map((label, index) => (
                            <Step onClick={() => ((index < 4 && uuid) ? setActiveStep(index) : null)} style={{ paddingLeft: 0 }} key={label}>
                                    <div className={`onBordingStepHeadDisabled${(activeStep === index) ? ' activeStepHead' : ''}`}>
                                        {(index !== tabs.length - 1) && <div style={(activeStep === index) ? { borderLeftColor: "#3D7DDD" } : null} class="arrow-right"></div>}
                                        {(index > 0) && <div class="arrow-left"></div>}
                                        {activeStep === index && <ArrowForwardIosIcon className={classes.activeArrow} />}
                                        <Typography className="customStepLabel" style={{ textAlign: "center", paddingTop: 10, ...(activeStep === index && { color: "#fff" }) }}>{label}</Typography>
                                    </div>
                            </Step>
                        ))}
                    </Stepper>
                    <Grid style={{ padding: 20 }}>
                        <form onSubmit={handleSubmit}>
                            <RenderStepContent 
                                step={activeStep}
                                handleNext={handleSubmit}
                                countryList={countryList} 
                                handleBack={handleBack}
                                apprenticeshipList={apprenticeshipList}
                                coursesList={coursesList}
                                assessmentList={assessmentList}
                                formBackupState={formBackupState}
                                handleUpdateBackupFormData={handleUpdateBackupFormData}
                                handleEditTab={handleEditTab}
                                labsList={labsList}
                            />
                        </form>
                    </Grid>
                </div>
            </main>
        </Layout>
    );
}

export default CustomerOnBoarding;