import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { notify } from '../../utils';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/constants';
import { loginUser, logout, useAuthDispatch, useAuthState } from '../../Context';
import { CircularProgress } from '@material-ui/core';
import { useHistory, withRouter } from 'react-router-dom';
import UpcommingUIBanner from 'Components/UpcommingUIBanner';
import logoDeviare from 'Assets/images/logo.jpg';

const useStyles = makeStyles(theme => ({
    loginBox: {
        marginTop: "5em",
        textAlign: "center",
        background: "#fff",
        padding: 50,
        minHeight: 595,
        borderRadius: 5
    },
    formBox: {
        width: "100%",
        marginTop: 30,
        textAlign: "left"
    },
    formLabel: {
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "26px",
        letterSpacing: "1.3px",
        textTransform: "uppercase",
        color: "#b1b1b1"
    },
    customTextField: {
        paddingTop: 15,
        paddingBottom: 15 
    },
    input: {
        border: "1px solid #F0F1F7",
        borderRadius: 5,
        backgroundColor: "#FAFCFF",
        padding: 12
    },
    loginBtn: {
        padding: 18,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 5
    },
    eyeIconBtn: {
        position: "absolute",
        right: 10,
        top: 22,
        width: 25,
        height: 25,
        borderRadius: 15
    }
}));

const Login = () => {

    const classes = useStyles();
    const dispatch = useAuthDispatch();
    const history = useHistory();
    const { loading } = useAuthState();
    // const [banerOpen, setBanerOpen] = useState(false);
    const [logo, setLogo] = useState((localStorage.getItem('company_logo') && localStorage.getItem('company_logo') !== 'null') ? localStorage.getItem('company_logo') : logoDeviare);
    
    // useEffect(() => {
    //     // setBanerOpen(true);
    //     logout(dispatch);
    // }, []);

    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const formData = {
            username: data.get('username'),
            password: data.get('password'),
        };
        if (!formData.username || !formData.password) {
            notify("error", ERROR_MESSAGE.REQUIRED_LOGIN);
            return null;
        }
        const res = await loginUser(dispatch, formData);
        if (res && res.status) {
            notify("success", SUCCESS_MESSAGE.LOGIN_SUCCESS);
            let url = '/dashboard';
            if (res.data.role === 'user'){
                url = '/user/dashboard';
            } 
            // window.location.href = url;
            history.push(url);
        } else {
            notify("error", ERROR_MESSAGE.INVALID_CRED);
        }
    };

const handleClick = () => {
    history.push('/forgot-password');
}

    return (
        <Container component="main" maxWidth="sm">
            <Box className={classes.loginBox}>
                {/* <div style={{width: '100%', textAlign: 'center'}}>
                    <img alt="Deviare" src={logo} style={{width: 150}} />
                </div> */}
                <Typography style={{ lineHeight: 2.5 }} variant="h1">
                    Welcome back
                </Typography>
                <Typography variant="subtitle1">
                    Accelerate your digital transformation ambitions.
                </Typography>
                <Box component="form" onSubmit={handleSubmit} className={classes.formBox} >
                    <FormLabel className={classes.formLabel}>Username</FormLabel>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="username"
                        placeholder="Enter your username"
                        name="username"
                        inputProps={{ className: classes.input }}
                        className={classes.customTextField}
                        style={{ marginBottom: 24 }}
                        autoComplete="username"
                        autoFocus
                    />
                    <FormLabel className={classes.formLabel}>Password</FormLabel>
                    <Link onClick={handleClick} variant="body2" style={{ textDecoration: "none", float: "right",cursor:'pointer' }}>
                        <Typography style={{ fontSize: 13 }} component="span" variant="subtitle1">Forgot password? </Typography>
                    </Link>
                    <div style={{ position: "relative" }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="password"
                            placeholder="Enter your password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            inputProps={{ className: classes.input }}
                            className={classes.customTextField}
                            autoComplete="current-password"
                        />
                        <IconButton
                            aria-label="toggle password visibility"
                            className={classes.eyeIconBtn}
                            onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                    <Grid container style={{ marginTop: 28 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.loginBtn}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={20} /> : "Login"}
                        </Button>
                        <Grid item xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                            {/* <Typography component="span" variant="subtitle1">Don't have an account? </Typography> */}
                            {/* <Link href="/forgot-password" variant="body2">Sign Up</Link> */}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* // <UpcommingUIBanner open={banerOpen} handleClose={evt => setBanerOpen(false)} /> */}
        </Container>
    );
}

export default withRouter(Login);