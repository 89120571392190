import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    button: {
        padding: 16,
        borderRadius: 5,
        minWidth: 220
    }
}));

const OutlinedButton = (props) => {
    const { title, styleOverrides, handleClick, ...others } = props;
    const classes = useStyles();

    return (
        <Button
            variant={'outlined'}
            className={classes.button}
            color="primary"
            style={styleOverrides || null}
            type='button'
            onClick={handleClick}
            {...others}
        >
            {title}
        </Button>
    );
};

OutlinedButton.prototypes = {
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    styleOverrides: PropTypes.object,
    disabled: PropTypes.bool,
};

export default OutlinedButton;