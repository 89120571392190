import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Gauge from 'react-svg-gauge';
import CustamizedFilters from '../../../../Components/CustamizedFilters';
import DynamicReport from './dynamicReport';

const useStyles = makeStyles((theme) => ({
    rechartBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    title: {
        color: "#4D5766",
        wordSpacing: 2,
        fontSize: 18,
    }
}));

const GaugeComponent = ({ score }) => {
    const classes = useStyles();
    return (
        <div className={classes.rechartBox}>
            <Gauge
                value={parseInt(score)}
                max={5}
                color="#143177"
                label={''}
                width={410}
                height={400}
                topLabelStyle={{ display: 'none' }}
                valueLabelStyle={{ fontSize: 50 }}
                valueFormatter={number => `${number}`}
            />
        </div>
    )
}

const DynamicPage = ({ title, description, score, allwords, heading, filters, allFilterState, handleChange,
    type, pageName, handleSubmitFilter }) => {
    const classes = useStyles();

    return (
        <>
            <h4 className={classes.title}>{title}</h4>
            <Grid container>
                <Grid item xs={12}>
                    <p>{description}</p>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <CustamizedFilters
                        type={type}
                        filters={filters}
                        pageName={pageName}
                        allFilterState={allFilterState}
                        handleChange={handleChange}
                        handleSubmitFilter={handleSubmitFilter}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GaugeComponent score={score} />
                </Grid>
                <Grid item xs={12}>
                    <DynamicReport allwords={allwords} heading={heading} />
                </Grid>
            </Grid>
        </>
    );
}

export default DynamicPage;