import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const PhoneInputField = ({ value, handleChange }) => {
    return (
        <PhoneInput
            className="phone-input-style"
            country={'us'}
            inputStyle={{width: '100%'}}
            value={value}
            onChange={handleChange}
            inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true
            }}
        />
    )
}

export default PhoneInputField;