import axios from 'axios';
import '../Sys/config.js';

const apiUrl = global.platformURI;

export class CustomerServices {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        let token = localStorage.getItem('ssoToken');
        config.baseURL = apiUrl;
        config.headers.Authorization = `Token ${token}`;
        return config;
      },
      error => {
        console.log('error.response.status', error);
        return error;
      }
    );
  }

  getAllCustomer(url) {
    return axios.get(url).then(res => res.data);
  }

  addCustomer(payload, id) {
    if (id) {
      return axios.put(`main/customerdetail/${id}`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }
    return axios
      .post('main/customercreate', payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  
  sendTestEmail(payload) {
    return axios
      .post('main/testemail', payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  
  getCustomerTheme(id) {
    return axios.get(`main/customertheme/${id}`)
    .then(res => res.data);
  }

  addCustomertheme(payload, uuid) {
    return axios.put(`main/customertheme/${uuid}`, payload)
    .then(res => res.data)
    .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }

  getCustomerDetail(id) {
    return axios
      .get(`main/customerdetail/${id}`)
      .then(res => res.data);
  }

  getCounties() {
    return axios.get(`main/countrylist`).then(res => res.data);
  }
  
  assignCustomerLicence(payload, id) {
    if (id) {
      return axios.put(`main/assign_customer_license?customer_id=${id}`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }
    return axios
      .post('main/assign_customer_license', payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
}
