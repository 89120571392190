/**
 * All route paths in this file
 */
const Paths = {
  Login: '/login',
  ForgotPassword: '/forgot-password',
  Dashboard: '/dashboard',
  Administrators: '/administrators',
  Customers: '/customers',
  createCustomer: '/customers/create',
  editCustomers: '/customers/edit/:id',
  Users: '/users',
  Courses: '/courses',
  AddCourses: '/courses/create',
  updateCourses: '/courses/create/:id',
  EditUsers: '/users/edit',
  UpdateUsers: '/users/edit/:id',
  AddUsers: '/users/create',
  AddAdministrator: '/administrators/create',
  UpdateAdministrator: '/administrators/create/:id',
  MyAssessments: '/myassessments',
  Profile: '/profile',
  Liceses: '/licenses',
  Experiments: '/experiments',
  AddExperiment: '/experiments/create',
  Reporting: '/reporting/overview',
  DigitalReadliness: '/reporting/digital-readliness',
  LearningService: '/reporting/learning-service/:type?',
  Calendar: '/calendar',
  ChangePassword: '/change-password',
  Deployments: '/deployments',
  createDeployment: '/deployments/create',
  updateDeployment: '/deployments/create/:id',
  CustomerOnBoarding: '/flowcustomer/:id?',
  ThemeEditor: '/themeeditor/customer/:id?',
  CourseRequests: '/course-requests',
  CourseListing: '/course-listing',
  CourseDetails: '/course/:id',
};

const UserPath = {
  UserDashboard: '/user/dashboard',
  Assessments: '/assessments',
  ExperimentAndTesting: '/experiments-and-testing',
  CourseCatalogue: '/skill-transformation/course-catalogue',
  RegisteredCourses: '/skill-transformation/registered-courses',
  WorkReadyness: '/skill-transformation/work-readiness',
  VirtualGlobalApprenticeship: '/skill-transformation/virtual-global-apprenticeship',
  Calendar: '/calendar',
  MainPage: '/main',
  HomePage: '/home',
}

export {
  Paths,
  UserPath
};
