import { makeReplacer, SafeJSON, SafeQS } from "utils";
import '../Sys/config.js';

const apiUrl = global.platformURI;

export const logout = () => {
    clearLocalStorage()
};
export const localStorageKeys = () => {
    var i = 0;
    const ret = [];
    while (i < localStorage.length) {
      ret.push(localStorage.key(i));
      i++;
    }
    return ret;
};  
export const clearLocalStorage = () =>
  localStorageKeys()
    .filter(key => !/persist/gi.test(key))
    .map(key => localStorage.removeItem(key));


const responseCode = {
    401: {
      message:
        'Your request can not be completed as you are no longer logged in!',
      complete: () => logout()
    }
};
  
class FetchWrap {
    constructor(url, fields = false, token=null) {
      this._url = url;
      this._token = token || localStorage.getItem('ssoToken');
      const replacer = makeReplacer(fields);
      this.stringify = data => SafeJSON.stringify(data, replacer);
    }
    get baseURL() {
      return apiUrl;
    }
    get token() {
      return this._token || localStorage.getItem('ssoToken');
    }
    static api(url, f, t) {
      return new FetchWrap(url, f, t);
    }
    url = args => {
      let ret = [this.baseURL, args].join('');
      if (ret.indexOf('?') !== -1) {
        return ret;
      }
      if (!ret.endsWith('/')) {
        ret = ret + '/';
      }
      console.log(this._url, ret)
      return ret;
    };
    doRequest = (url, config) =>
      fetch(this.url(url), config).then(this.handleResponse);
    post = (url, params) =>
      this.doRequest(url, {
        method: 'POST',
        headers: this.getAuth({ 'Content-Type': 'application/json' }),
        body: this.stringify(params)
      });
    put = (url, params) =>
      this.doRequest(url, {
        method: 'PUT',
        headers: this.getAuth({ 'Content-Type': 'application/json' }),
        body: this.stringify(params)
      });
    patch = (url, params) =>
      this.doRequest(url, {
        method: 'PATCH',
        headers: this.getAuth({ 'Content-Type': 'application/json' }),
        body: this.stringify(params)
      });
    delete = (url, params) =>
      this.doRequest(url, {
        method: 'DELETE',
        headers: this.getAuth({ 'Content-Type': 'application/json' }),
        body: this.stringify(params)
      });
    head = url =>
      this.doRequest(url, {
        method: 'HEAD',
        headers: this.getAuth({ 'Content-Type': 'application/json' })
      });
    fget = (url, params) =>
      this.doRequest([url, params && SafeQS.stringify(params)].join('?'), {
        method: 'GET',
        headers: this.getAuth({ 'Content-Type': 'application/json' })
      });
    getAuth = (headers) => this.token ? {Authorization: `Token ${this.token}`,...headers}: headers
    setToken = token => this.token = token
    load = (params) =>
      this.fget(this._url + '/', params);
    get = id => this.fget(`${this._url}/${id}/`);
    add = data => this.post(`${this._url}/`, data);
    set = (id, data) => this.patch(`${this._url}/${id}/`, data);
    update = (id, data) => this.put(`${this._url}/${id}/`, data);
    action = (id, action = 'accept', data = {}) =>
      this.post(`${[this._url, id, action].join('/')}/`, data);
    responseErrorHandler = (data = null, { status, statusText }) => {
      const errHandler = responseCode[`${status}`];
      if (errHandler) {
        errHandler.complete();
      }
      const error = errHandler || data || statusText;
      return Promise.reject(error);
    };
    handleResponse = response =>
      response.text().then(text => {
        var data = null;
        try {
          data = text && JSON.parse(text);
        } catch (e) {
          data = { message: e.toString() };
        }
        if (!response.ok) {
          return this.responseErrorHandler(data, response);
        }
        return data;
      });
}

export const makeFetcher = (url) =>{
    const fetcher = FetchWrap.api(url, false, localStorage.getItem('ssoToken'))
    return fetcher
}