import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { notify } from '../../utils';
import Input from '@material-ui/core/Input';
import { REQUIRED_ERROR } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UserServices } from '../../Services/UserServices';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { InputAdornment } from '@material-ui/core';
import variables from '../../Sys/variable.scss';
import commonStyles from 'theme/commonStyles';

const userServices = new UserServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0'
    },
}));

const initFormState = { old_password: "", new_password: "", confirm_password: "" };

const ChangePassword = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    
    const [loading, setLoading] = React.useState(false)
    const [showOldText, setShowOldText] = React.useState(false)
    const [showNewText, setShowNewText] = React.useState(false)
    const [showConfirmText, setShowConfirmText] = React.useState(false)
    const [formState, setFormState] = React.useState({ ...initFormState });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const formValidationCheck = () => {
        if (!formState.old_password || !formState.new_password || !formState.confirm_password) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        if (formState.new_password.length < 8 || formState.confirm_password.length < 8) {
            notify("error", 'New Password and Confirm Password must be 8 character long')
            return null
        }
        if (formState.new_password !== formState.confirm_password) {
            notify("error", 'New Password and Confirm Password must be same')
            return null
        }
        return handleFormSubmit(formState);
    }

    const handleFormSubmit = async (formState) => {
        setLoading(true)
        await userServices.changePassword(formState).then(
            data => {
                if (data && data.status) {
                    setLoading(false)
                    notify("success", data.message)
                    setFormState({ ...initFormState })
                }
            },

            error => {
                notify("error", error.response.data.message ? error.response.data.message : error.message)
                setLoading(false)
            }
        )
    }

    const handleIconClick = (type) => {
        if (type === "old") {
            setShowOldText(!showOldText)
        } else if (type === "new") {
            setShowNewText(!showNewText)
        } else {
            setShowConfirmText(!showConfirmText)
        }
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper} style={{
                    display: 'flex',
                    justifyContent: 'center'
                }} >
                    <div style={{ maxWidth: 500, justifyContent: 'center' }}>
                        <h1 style={{ textAlign: 'center' }}>Change Password</h1>
                        <Grid container style={{ marginBottom: '15px', marginTop: '15px' }}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    Old Password
                                </Typography>
                                <Input disableUnderline                                    value={formState.old_password}
                                    type={showOldText ? 'text' : 'password'}
                                    name="old_password"
                                    className={classes.inputStyle}
                                    placeholder={"Old Password"}
                                    style={{ minWidth: 220, width: '100%' }}
                                    color={'secondary'}
                                    onChange={handleInputChange}
                                    endAdornment={
                                        <InputAdornment onClick={e => handleIconClick("old")} position="start">
                                            {showOldText ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    New Password
                                </Typography>
                                <Input disableUnderline                                    value={formState.new_password}
                                    type={showNewText ? 'text' : 'password'}
                                    className={classes.inputStyle}
                                    placeholder={"New Password"}
                                    style={{ minWidth: 220, width: '100%' }}
                                    name="new_password"
                                    color={'secondary'}
                                    onChange={handleInputChange}
                                    endAdornment={
                                        <InputAdornment onClick={e => handleIconClick("new")} position="start">
                                            {showNewText ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    Confirm Password
                                </Typography>
                                <Input disableUnderline                                    value={formState.confirm_password}
                                    type={showConfirmText ? 'text' : 'password'}
                                    placeholder={"Confirm Password"}
                                    className={classes.inputStyle}
                                    style={{ minWidth: 220, width: '100%' }}
                                    color={'secondary'}
                                    name="confirm_password"
                                    onChange={handleInputChange}
                                    endAdornment={
                                        <InputAdornment onClick={e => handleIconClick("confirm")} position="start">
                                            {showConfirmText ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.placeBtn}>
                            <Button color="primary" variant="outlined" className={classes.btn}
                                onClick={formValidationCheck}>
                                {loading ? <CircularProgress size={20} color="secondary" /> : 'Update'}
                            </Button>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default ChangePassword;