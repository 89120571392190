import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { checkValidEmail, notify } from '../../utils';
import Input from '@material-ui/core/Input';
import { ERROR_MESSAGE, REQUIRED_ERROR } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paths } from '../../Routes/routePaths'
import { useParams } from "react-router-dom";
import { AdministratorServices } from '../../Services/AdministratorServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router';
import variables from '../../Sys/variable.scss';
import commonStyles from 'theme/commonStyles';

const administratorServices = new AdministratorServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0'
    },
    btnPwd: {
        background: '#3D7DDD',
        color: 'white',
        borderRadius: '25px',
        padding: '18px',
        marginTop: '-3px',
        marginLeft: '-42px',
        "&:hover": {
            background: "#3D7DDD"
        }
    },
}));

const initFormState = { firstName: "", lastName: "", userName: "", email: "", location: "", password: "", is_active: true };

const AddAdministrator = () => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const history = useHistory();

    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [formState, setFormState] = React.useState({ ...initFormState });
    const [update, setUpdate] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            getAdminisratorDetail();   
        }
    }, []);

    async function getAdminisratorDetail(){
        let data = await administratorServices.getAdminisratorDetail(id)
        if (data && data.data[0]) {
            let userData = data.data[0]
            setUpdate(true);
            setFormState({...userData});
        }
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const handleChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.checked });
    };

    const formValidationCheck = () => {
        if (!formState.firstName || !formState.lastName || !formState.userName || !formState.email) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        if (!checkValidEmail(formState.email)) {
            notify("error", ERROR_MESSAGE.VALID_EMAIL);
            return null;
        }
        return handleFormSubmit();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const payload = {...formState};
        let uuid = (update) ? id : null;
        await administratorServices.addAdministrator(payload, uuid).then(
            data => {
                notify('success', data.message);
                setLoading(false);
                history.push(Paths.Administrators);
            },
            error => {
                setLoading(false);
                notify("error", error.response.data.message ? error.response.data.message : 'Something Went Wrong');
                console.log('error.response.status', error);
            }
        );
    }

    const generatePassword = evt => {
        evt.preventDefault();
        let newPwd = '';
        let pwdChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890*/&%$#@!';
        for (let i = 0; i < 8; i++) {
            newPwd += pwdChars[Math.floor(Math.random() * pwdChars.length)];
        }
        if (newPwd) {
            setFormState({ ...formState, ['password']: newPwd })
        }
    };

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <div className={classes.pageHeader}>
                        <Typography
                            variant="subtitle2"
                            className={classes.heading}
                            color="textPrimary">
                            {update ? "Update Administrator" : "Create Administrator"}
                        </Typography>
                        {update ? (
                            <FormControlLabel
                                control={<Switch 
                                    checked={formState.is_active} 
                                    onChange={handleChange}
                                    name="is_active" 
                                />}
                                label="Account Status"
                            />
                        ) : null}
                    </div>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                First Name *
                            </Typography>
                            <Input disableUnderline value={formState.firstName}
                                type={'text'}
                                name="firstName"
                                className={classes.inputStyle}
                                placeholder={"First Name"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Surname *
                            </Typography>
                            <Input disableUnderline value={formState.lastName}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Last Name"}
                                style={{ width: '100%' }}
                                name="lastName"
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Username *
                            </Typography>
                            <Input disableUnderline value={formState.userName}
                                type={'text'}
                                disabled={update}
                                placeholder={"Username"}
                                className={classes.inputStyle}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="userName"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Email *
                            </Typography>
                            <Input 
                                disableUnderline 
                                value={formState.email}
                                disabled={update}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Email"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                name="email"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Location
                            </Typography>
                            <Input disableUnderline value={formState.location}
                                type={'text'}
                                placeholder={"Location"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                className={classes.inputStyle}
                                name="location"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    {update ? (
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                    Password
                                </Typography>
                                <div className={classes.pwd}>
                                    <Input disableUnderline                                        value={formState.password}
                                        type={'text'}
                                        placeholder={"Password"}
                                        style={{ maxWidth: 280 }}
                                        color={'secondary'}
                                        className={classes.inputStyle}
                                        name="password"
                                        onChange={handleInputChange}
                                    />
                                    <Button className={classes.btnPwd} onClick={generatePassword}>Generate Password</Button>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}
                    <div className={classes.placeBtn}>
                        <Button onClick={() => history.push(Paths.Administrators)} color="primary" className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button 
                            color="primary"
                            variant="outlined"
                            className={classes.btn}
                            onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={15} color="secondary" /> : update ? "Update" : "Save"}
                        </Button>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default AddAdministrator;