import axios from 'axios';
import "../Sys/config.js";

var apiUrl = global.platformURI;
export class SkillsAndTransformation {
    constructor() {
        axios.interceptors.request.use(function (config) {
            let token = localStorage.getItem('ssoToken');
            config.baseURL = apiUrl;
            config.headers.Authorization = `Token ${token}`;
            return config;
        }, error => {
            console.log('error.response.status', error);
            return error;
        });
    }

    // Course catalogue
    getCourseCatalogue() {
        return axios.get("project/coursecatalog")
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    // Register courses
    getRegisterCourse(filter) {
        return axios.get(`project/registeredcourses${filter ? '?category='+filter  : ''}`)
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    // Register apprenticeship
    getRegisteredApprenticeships() {
        return axios.get("project/registeredapprenticeships/?")
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    // Register Assessments
    getRegisteredassessments() {
        return axios.get("project/registeredassessments/?")
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    sendCourseRequest(payload) {
        return axios.post("main/courserequest/", payload)
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    getCourseCompletionGraph() {
        return axios.get("main/courselicenceuser/")
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

    getBestCourses() {
        return axios.get('main/popularcoursegraph')
            .then(res => res.data)
            .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }

}
