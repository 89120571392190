import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import OutelinedInput from '../OutelinedInput';

function ButtonComponent(props) {
    const { onClick, loading } = props;
    return (
        <Button
            color="primary"
            style={{
                borderRadius: 5
            }}
            variant={'contained'}
            onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && "Ok"}
        </Button>
    );
}

export default function SendTestEmailModal({ open, handleClose, handleSubmit, loading }) {

    const [email, setEmail] = React.useState("");

    return (
        <div>
            <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Test Email Settings</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{marginBottom: 24}}>
                        <Grid item xs={12}>
                            <OutelinedInput
                                label={"Enter email address to send test message to:"}
                                placeholder="Email address"
                                value={email}
                                name="email"
                                handleChange={evt => setEmail(evt.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button style={{
                        background: "#B7B7B7",
                        padding: 10,
                        color: "#fff",
                        marginRight: 20,
                        borderRadius: 5
                    }} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <ButtonComponent onClick={(evt) => handleSubmit(email)} loading={loading} />
                </DialogActions>
            </Dialog>
        </div>
    );
}