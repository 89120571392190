import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import { notify } from '../../utils';
import Input from '@material-ui/core/Input';
import { useHistory, useParams } from "react-router-dom";
import { REQUIRED_ERROR, USER_ROLES } from '../../utils/constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UserServices } from '../../Services/UserServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paths } from '../../Routes/routePaths';
import { ProjectServices } from '../../Services/ProjectServices';
import { Chip, FormControlLabel, Switch, TextField } from '@material-ui/core';
import OutlinedSelect from '../../Components/OutlinedSelect';
import { useAuthDispatch, useAuthState } from '../../Context';
import variables from '../../Sys/variable.scss';
import AutocompleteCs from 'Components/Autocomplete';
import { useEffect } from 'react';

const userServices = new UserServices();
const projectService = new ProjectServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        borderColor: '#838383',
        borderRadius: '25px',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px 0'
    },
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500'
    },
    btn: {
        padding: '13px 35px',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    },
    cancelBtn: {
        padding: '13px 35px',
        background: '#B7B7B7',
        fontSize: '13px',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
        "&:hover": {
            background: "#454343"
        }
    },
    placeBtn: {
        display: 'flex',
        justifyContent: 'end',
        padding: '20px 0',
        marginTop: 30
    },
    btnPwd: {
        borderRadius: '25px',
        padding: 16,
        top: 0,
        left: 177,
        position: 'absolute'
    },
}));

const initFormState = { 
    firstName: '', 
    lastName: '', 
    userName: '', 
    email: '', 
    location: '', 
    customers: [], 
    role: '', 
    is_active: false, 
    password: '', 
    sub_role: '',
    project: null 
};

const UpdateUsers = () => {
    
    const dispatch = useAuthDispatch();
    const { userDetails } = useAuthState(dispatch);
    const history = useHistory();
    
    const { id } = useParams();
    const classes = useStyles();
    const [companyList, setCompanyList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [formState, setFormState] = React.useState({ ...initFormState });
    const [roleList, setRoleList] = React.useState([]);
    const [projectList, setProjectList] = React.useState([]);
    const [editUserData, setEditUserData] = React.useState(null);
    
    React.useEffect(() => {
        if(id){
            getProjectList();
        }
    }, []);

    const getProjectList = async () => {
        const projectRes = await projectService.getAllLicenseListProject(`project/licenselist`);
        if (projectRes && projectRes.data && projectRes.data.length > 0) {
            const allProject = projectRes.data.map(item => {
                return {
                    name: item.project_name,
                    id: item.project_uuid
                }
            });
            setProjectList(allProject);
        }
        getUserDetails();
    };

    useEffect(() => {
        if(editUserData){
            const selectedProj = projectList.find(p => p.id === editUserData.project_id);
            setFormState({
                firstName: editUserData.firstName,
                lastName: editUserData.lastName,
                userName: editUserData.userName,
                email: editUserData.email,
                customers: editUserData.customers,
                location: editUserData.location,
                role: editUserData.role,
                project: selectedProj ? {name: selectedProj.name, id: selectedProj.id} : null,
                is_active: editUserData.is_active
            });
        }
    }, [projectList, editUserData]);

    async function getUserDetails(){
        const cusResult = await projectService.getCustomerList();
        if (cusResult && cusResult.data) {
            setCompanyList(cusResult.data);
        }
        let data = await userServices.getUserDetail(id);
        if (data && data.status) {
            const userData = data.data[0];
            setEditUserData(userData);
            if (userData?.customers?.length) {
                const selectedComp = cusResult.data ? cusResult.data.filter(cs => userData.customers.includes(cs.uuid)) : [];
                setCustomers(selectedComp);
            }
        }
    }

    React.useEffect(() => {
        const { role } = userDetails;
        let roles = [];
        if (role === USER_ROLES.SUPER_ADMIN) {
            roles = [{
                id: 'customeradmin',
                name: 'Customer Admin'
            },
            {
                id: 'csmadmin',
                name: 'CSM'
            }];
        }
        if (['customeradmin', 'superadmin'].includes(role)) {
            roles.push({
                id: 'projectadmin',
                name: 'Project Admin'
            });
            roles.push({ id: 'gcologist', name: 'GCologist' });
        }
        roles.push({
            id: 'user',
            name: 'User'
        });
        setRoleList(roles);
    }, [userDetails]);

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const handleChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.checked });
    };

    const formValidationCheck = () => {
        if (!formState.firstName || !formState.lastName || !formState.userName || !formState.email || !formState.location) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        return handleFormSubmit();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        let customersIds = customers.map(cs => cs.uuid);
        const payload = { ...formState, customers: customersIds, project_id: (formState.project) ? formState.project.id : null };
        delete payload.project;
        const result = await userServices.addUser(payload, id);
        if (result && result.status) {
            notify("success", result.message)
            setLoading(false);
            history.push(Paths.Users);
        } else {
            notify("error", (result && result.message) ? result.message : 'Something Went Wrong')
            setLoading(false)
        }
    }

    const handleChangeCustomer = (newValue, type) => {
        if(type === 'project'){
            setFormState({...formState, project: newValue});
        }else{
            setCustomers(newValue);
        }
    }

    const generatePassword = evt => {
        evt.preventDefault();
        let newPwd = ''
        let pwdChars =
            'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890*/&%$#@!';
        for (let i = 0; i < 8; i++) {
            newPwd += pwdChars[Math.floor(Math.random() * pwdChars.length)];
        }
        if (newPwd) {
            setFormState({ ...formState, ['password']: newPwd })
        }
    };

    return (
        <Layout>
            <main>
                <div style={{
                    background: "#FFFFFF",
                    boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
                    borderRadius: 5,
                    padding: 20,
                    minHeight: '80vh'
                }}>
                    <div className={classes.pageHeader}>
                        <Typography
                            variant="subtitle2"
                            className={classes.heading}
                            color="textPrimary">
                            Update User
                        </Typography>
                        <FormControlLabel
                            control={<Switch checked={formState.is_active} onChange={handleChange} name="is_active" />}
                            label="Account Status"
                        />
                    </div>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                First Name *
                            </Typography>
                            <Input disableUnderline value={formState.firstName}
                                type={'text'}
                                name="firstName"
                                className={classes.inputStyle}
                                placeholder={"First Name"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Surname *
                            </Typography>
                            <Input disableUnderline value={formState.lastName}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Last Name"}
                                style={{ width: '100%' }}
                                name="lastName"
                                color={'secondary'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Username *
                            </Typography>
                            <Input disableUnderline value={formState.userName}
                                type={'text'}
                                disabled={true}
                                placeholder={"Username"}
                                className={classes.inputStyle}
                                style={{ width: '100%', backgroundColor: '#bcbcbc' }}
                                color={'secondary'}
                                name="userName"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Email *
                            </Typography>
                            <Input disableUnderline value={formState.email}
                                disabled={true}
                                type={'text'}
                                className={classes.inputStyle}
                                placeholder={"Email"}
                                style={{ width: '100%', backgroundColor: '#bcbcbc' }}
                                color={'secondary'}
                                name="email"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Company Name *
                            </Typography>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                value={customers}
                                name="customers"
                                options={companyList ? companyList : []}
                                getOptionLabel={option => option.name}
                                onChange={(event, newValue) => {
                                    handleChangeCustomer(newValue, 'customers');
                                }}
                                limitTags={2}
                                style={{ width: '100%' }}
                                renderTags={(value, getTagProps) => value.map((option, index) => (
                                    <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                                ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Select Company"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Role *
                            </Typography>
                            <OutlinedSelect
                                val={formState.role}
                                name="role"
                                placeholder='Select Role'
                                variant="standard"
                                handleChange={(evt) => handleInputChange(evt)}
                                styleOverrides={{ width: '100%', marginTop: 0 }}
                                options={roleList}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <>
                            {['superadmin', 'customeradmin', 'projectadmin'].includes(formState.role) &&
                                ['superadmin', 'customeradmin'].includes(userDetails?.role) &&
                                <Grid item xs={4}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                        Secondary Role *
                                    </Typography>
                                    <OutlinedSelect
                                        val={formState.sub_role}
                                        name="sub_role"
                                        placeholder='Select Role'
                                        variant="standard"
                                        handleChange={(evt) => handleInputChange(evt)}
                                        styleOverrides={{ width: '100%', marginTop: 0 }}
                                        options={[{ id: 'gcologist', name: 'GCologist' }]}
                                    />
                                </Grid>}
                        </>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Location *
                            </Typography>
                            <Input disableUnderline value={formState.location}
                                type={'text'}
                                placeholder={"Location"}
                                style={{ width: '100%' }}
                                color={'secondary'}
                                className={classes.inputStyle}
                                name="location"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Project *
                            </Typography>
                            <AutocompleteCs
                                styleOverrides={{ width: "100%" }}
                                inputStyle={{ border: '1px solid rgba(34,36,38,.15)', borderRadius: '25px' }}
                                handleChange={(evt, newVal) => handleChangeCustomer(newVal, 'project')}
                                val={formState.project}
                                options={projectList}
                                loading={false}
                                placeholder={"Project"}
                                size="small"
                                multiple={false}
                                onInputChange={evt => { }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                Password
                            </Typography>
                            <div style={{position: "relative"}}>
                                <Input disableUnderline                                    value={formState.password}
                                    type={'text'}
                                    placeholder={"Password"}
                                    style={{ maxWidth: 220 }}
                                    color={'secondary'}
                                    className={classes.inputStyle}
                                    name="password"
                                    onChange={handleInputChange}
                                />
                                <Button variant="outlined" color="primary" className={classes.btnPwd} onClick={generatePassword}>Generate Password</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.placeBtn}>
                        <Button onClick={() => history.push(Paths.Users)} className={classes.cancelBtn}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className={classes.btn}
                            variant="outlined"
                            disabled={
                                !formState.firstName ||
                                !formState.lastName ||
                                !formState.userName ||
                                !formState.email ||
                                !formState.role ||
                                !formState.location
                            }
                            onClick={formValidationCheck}>
                            {loading ? <CircularProgress size={15} color="secondary" /> : 'Save'}
                        </Button>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default UpdateUsers;