import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from "./Sidebar";
import Header from "./Header";
import variables from "../../Sys/variable.scss";

const useStyles = makeStyles(() => ({
    content: {
      width: 'calc(100% - 260px)',
      padding: 25,
      backgroundColor: variables.bodyColor
    },
    mainSec: {
      display: 'flex'
    },
    childContent: {
      padding: '25px',
      paddingLeft: '35px'
    }
}));  
  
const Layout = ({sidebarView = true ,children}) => {
    const classes = useStyles();
    return (
        <>
            <Header/>
            <div className={classes.mainSec}>
                {sidebarView ? <> 
                <Sidebar />
                  <Container maxWidth="xlg" className={classes.content} >
                      {children}
                  </Container>
                </> : 
                <Container style={{width: '100%', maxWidth: '100%', padding: 0}} >
                  {children}
                </Container>}
            </div>
        </>
    );
}

export default Layout;