import axios from 'axios';
import "../Sys/config.js";

const apiUrl = global.platformURI;

export class ExperimentServices {
    constructor() {
        axios.interceptors.request.use(
          function (config) {
            let token = localStorage.getItem('ssoToken');
            config.baseURL = apiUrl;
            config.headers.Authorization = `Token ${token}`;
            return config;
          },
          error => {
            console.log('error.response.status', error);
            return error;
          }
        );
    }

    getAllExperiment(query) {
        return axios.get('services/experimentlist' + query)
            .then(res => res.data);
    }

    addExperiment(payload, id) {
        if (id) {
            return axios.put(`services/createexperiment/${id}`, payload)
                .then(res => res.data)
        }
        return axios.post("services/createexperiment", payload)
            .then(res => res.data)
    }

    getExperimentDetail(id) {
        return axios.get(apiUrl + `services/experimentdetail/${id}`)
            .then(res => res.data)
    }
}