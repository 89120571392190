import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListItemText, Typography } from '@material-ui/core';
import DropDownMenu from './DropDownMenu'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import variables from '../../../Sys/variable.scss';

const useStyles = makeStyles(() => ({
    menuItemText: {
        color: "#fff",
        '&.MuiTypography-body1':{
            color: "#fff"
        }
    },
    darkThemeColor: {
        color: variables.darkThemeText,
        '&.MuiTypography-body1':{
            color: variables.darkThemeText
        }
    }
}));

const MenuItems = ({ menuItems, index, handleItemClick, location }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const isSelected = location.pathname.startsWith(menuItems.active) || false;

    const handleClick = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        if(isSelected){
            setOpen(true);
        }
    }, [location]);

    return (
        <>
            {menuItems && menuItems.submenus ? (
                <>
                    <ListItem selected={isSelected} style={{ marginBottom: 10, ...(isSelected && { backgroundColor: variables.sidebarActive }) }} onClick={handleClick} button key={index} >
                        <ListItemIcon className={isSelected ? classes.menuItemText : classes.darkThemeColor }>{menuItems.icon}</ListItemIcon>
                        <ListItemText primary={<Typography className={isSelected ? classes.menuItemText : classes.darkThemeColor}>{menuItems.title}</Typography>} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <DropDownMenu submenus={menuItems.submenus} handleItemClick={handleItemClick} location={location} />
                        </List>
                    </Collapse>
                </>
            ) : (
                <ListItem onClick={() => handleItemClick(menuItems.active)} style={{ marginBottom: 10, ...(isSelected && {backgroundColor: variables.sidebarActive}) }}
                    selected={location.pathname.startsWith(menuItems.active)} button key={index} >
                    <ListItemIcon className={isSelected ? classes.menuItemText : classes.darkThemeColor }>{menuItems.icon}</ListItemIcon>
                    <ListItemText primary={<Typography className={isSelected ? classes.menuItemText : classes.darkThemeColor }>{menuItems.title}</Typography>} />
                </ListItem>
            )}
        </>
    );
}

export default MenuItems;