import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import analyticImg from '../../Assets/images/analytic.png';
import progressImg from '../../Assets/images/progress.png';
import overviewImg from '../../Assets/images/overview.png';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { analyticsColumns, overViewColumn, progressColumn } from '../../utils/constants'
import LineChartWrapper from '../../Components/Charts/LineChartWrapper';
import PieChartWrapper from '../../Components/Charts/PieChartWrapper';
import GenericTable from 'Components/GenericTable';
import BarHorizontalChartWrapper from './component/BarHorizontalChartWrapper';
import { useHistory } from 'react-router';
import commonStyles from 'theme/commonStyles';
import variables from '../../Sys/variable.scss';
import ChartContainer from 'Components/Charts/ChartContainer';
import { ReportServices } from '../../Services/ReportServices';
import { USER_ROLES } from 'utils/constants';
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import { Dashboard } from 'Services/DashboardService';
import { useJsonToCsv } from 'react-json-csv';
import { notify } from 'utils';
import { CircularProgress } from '@material-ui/core';
  
const reportService = new ReportServices();
const skillsAndTransformation = new SkillsAndTransformation();
const dashboardService = new Dashboard();

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: variables.headerColor,
        boxShadow: '0px 3.29662px 24.7247px rgba(12, 41, 84, 0.1)',
        borderRadius: 5
    },
    content: {
    },
    icon: {
        fontSize: 50,
        color: '#3d7ddd'
    },
    cover: {
        width: 50,
        height: 50
    },
    subTitle: {
        color: variables.darkThemeText,
        fontSize: 13
    },
    title: {
        fontSize: 17,
        paddingTop: 10
    },
    btn: {
        fontSize: 11,
        padding: '8px 15px',
        borderRadius: 5,
    }
}));

const ReportCard = ({ title, value }) => {
    const classes = useStyles();

    return (
        <Grid key={value} container>
            <Card className={classes.root} style={{minHeight: 160}}>
                <CardContent className={classes.content}>
                    <Typography align='center' style={{ marginBottom: 15 }} variant="h1" component="h1" color="primary">
                        {title}
                    </Typography>
                    <Typography align='center' variant="h1" component="h1" color="textPrimary">
                        {value}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

const Report = ({ title, subTitle, img, handleDownload, handleView, type, downloadReportLoading }) => {
    const classes = useStyles();
    const handleDownloadItem = () => {
        handleDownload(type)
    }
    const handleViewItem = () => {
        handleView(type)
    }

    return (

        <Grid key={title} container>
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <Grid container>
                        <Grid item xs={3}>
                            <CardMedia
                                className={classes.cover}
                                image={img}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography className={classes.subTitle}>
                                {title}
                            </Typography>
                            <Typography className={classes.title} color="primary">
                                {subTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                            {/* <Button disabled={downloadReportLoading[type]} style={{ marginRight: 10 }} onClick={handleDownloadItem} variant="outlined" className={classes.btn} size="small" color="primary">
                                {downloadReportLoading[type] ? <CircularProgress style={{color: 'white'}} size={14} />  : 'Download Data'}
                            </Button> */}
                            <Button size="small" onClick={handleViewItem} variant="outlined"
                                className={classes.btn} color="primary">
                                View Data
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    )
}

const Reporting = () => {

    let userRole = localStorage.getItem("role");
    const history = useHistory();
    const commonClasses = commonStyles();
    const [progressOnCoursesList, setProgressOnCoursesList] = React.useState( {
        name: 'Course Progress',
        type: 'bar',
        data: []
    });
    const [dataKeyYAxis, setDataKeyYAxis] = React.useState([]);
    const [lineChartData, setLineChartData] = React.useState({ data: [], xAxis: [] });
    const [recommendedCourseslist, setRecommendedCourseslist] = React.useState([]);
    const [bestCourses, setBestCourses] = React.useState([]);
    const [userReportCounts, setUserReportCounts] = React.useState({
        totalCourseCount: 0, 
        activeCourseCount: 0, 
        completedCourseCount: 0, 
        selfLearing: 0,
        inactiveUserCount: 0,
        activeUsersCount: 0,
        assignedLiceCount: 0
    });
    const [downloadReportLoading, setDownloadReportLoading] = React.useState({
        analytics: false,
        progress: false,
        overview: false
    });
    const { saveAsCsv } = useJsonToCsv();

    React.useEffect(() => {
        if(userRole === USER_ROLES.USER){
            getUserOverviewReportCount();
        }else{
            getReportsGraphData();
            getOverviewReportCount();
        }
        getCourseCatalogue();
        getCorseCompletionGraph();
    }, []);
    
    const getCourseCatalogue = async () => {
        const graphData = await dashboardService.getPopularCourseGraph();
        if(graphData?.data && graphData?.data.length){
            let courseData = [...graphData.data];
            courseData = (courseData.length > 10) ? courseData.slice(0, 10) : courseData;
            setRecommendedCourseslist(courseData);
        }
    }

    const getUserOverviewReportCount = async () => {
        const countRes = await reportService.getUserOverviewCount();
        if(countRes && countRes.data){
            const {active_courses, completed_courses, total_license_count, courses, learning_completion} = countRes.data;
            setUserReportCounts({
                assignedLiceCount: total_license_count, 
                activeCourseCount: active_courses, 
                totalCourseCount: courses, 
                completedCourseCount: completed_courses, 
                selfLearing: (learning_completion?.course_completion__avg && !isNaN(learning_completion?.course_completion__avg)) ? `${parseInt(learning_completion.course_completion__avg)}%` : '0%'
            });
        }
    }

    const getOverviewReportCount = async () => {
        const countRes = await dashboardService.getDetailDashboardCustomer();
        if(countRes && countRes.data){
            const { active_users, licenses_assigned, inactive_users, active_courses } = countRes?.data || {};
            setUserReportCounts({
                ...userReportCounts, 
                activeUsersCount: active_users, 
                assignedLiceCount: licenses_assigned, 
                inactiveUserCount: inactive_users, 
                activeCourseCount: active_courses 
            });
        }
    }

    const getReportsGraphData = async () => {
        const graphData = await dashboardService.getPopularCourseGraph();
        if(graphData?.data && graphData?.data.length){
            const courseData = [...graphData.data];
            setBestCourses((courseData.length > 5) ? courseData.slice(0, 5).map((crs) => {
                return { name: crs.course_name, count: crs.count };
            }) : courseData.map((crs) => {
                return { name: crs.course_name, count: crs.count };
            }));
            const newData = [...graphData?.data];
            const arrayUniqueByKey = [...new Set(newData.map(item =>
                item.course_name))];
            const graphArr = [];
            const dataArr = [];
            const dataKeyXAxis = [];
            arrayUniqueByKey.map(name => {
                const count = newData.filter(itm => itm.course_name === name).reduce((accumulator, object) => {
                    return accumulator + object.count;
                }, 0);
                dataArr.push(count);
                dataKeyXAxis.push(name);
                graphArr.push({ count, name });
            });
            setLineChartData({
                data: [{
                    type: 'line',
                    smooth: true,
                    data: dataArr,
                    areaStyle: {}
                }],
                xAxis: dataKeyXAxis
            });
        }
    };

    const handleDownload = async (type) => {
        let fields = {}; 
        let columns = analyticsColumns;
        let reportType = 'detailedanalytics';
        if(type === 'progress'){
            reportType = 'progressreport';
            columns = progressColumn;
        }else if(type === 'overview'){
            reportType = 'detailedoverview';
            columns = overViewColumn;
        }
        columns.map((itm) => {
            fields[itm.key] = itm.label;
        });
        setDownloadReportLoading({...downloadReportLoading, [type]: true});
        const filename = reportType;
        const filterData = {
            active: [],
            certificate_status: [],
            course: [],
            customer: [],
            pageno: 0,
            perpage: 9999999,
            project: [],
            report_type: reportType
        };
        let result = await reportService.getProjectReportData(`main/${reportType}`, filterData);

        if (result && result.status) {
            const { filter } = result?.data;
            filterData.customer = filter?.customer || [];
            filterData.project = filter?.project || [];
            filterData.course = filter?.course || [];
            let reportData = await reportService.getProjectReportData(`main/${reportType}`, filterData);
            if(reportData?.data && reportData?.data?.result){
                let data = reportData.data.result;
                saveAsCsv({ data, fields, filename });
            }else{
                notify('error', "Report data not found!")
            }
        }
        setDownloadReportLoading({...downloadReportLoading, [type]: false});
    }

    const handleView = (type) => {
        history.push('/reporting/learning-service' + '/' + type);
    }

    let cardArr = [
        {
            title: "Total Active Courses",
            value: userReportCounts.activeCourseCount
        },
        {
            title: "Total Active Users",
            value: userReportCounts.activeUsersCount
        },
        {
            title: "Total Licenses Assigned",
            value: userReportCounts?.assignedLiceCount
        },
        {
            title: "Total Inactive Users",
            value: userReportCounts.inactiveUserCount
        }
    ];

    let reportCardArr = [
        {
            title: "Total Active Courses",
            subTitle: "Detailed analytics report",
            img: analyticImg,
            type: "analytic"
        },
        {
            title: "Total Active Courses",
            subTitle: "Overview report",
            img: overviewImg,
            type: "overview"
        },
        {
            title: "Total Active Courses",
            subTitle: "Progress report",
            img: progressImg,
            type: "progress"
        }
    ];

    if(userRole === 'user'){
        cardArr = [
            {
                title: "Total Courses",
                value: userReportCounts.totalCourseCount
            },
            {
                title: "Total Active Courses",
                value: userReportCounts.activeCourseCount
            },
            {
                title: "Total Completed Courses",
                value: userReportCounts.completedCourseCount
            },
            {
                title: "Self Learning Completion",
                value: userReportCounts.selfLearing
            }
        ];
        
        reportCardArr = [
            {
                title: "Total Open Projects",
                subTitle: "Detailed analytics report",
                img: analyticImg,
                type: "analytic"
            },
            {
                title: "Total Open Projects",
                subTitle: "Overview report",
                img: overviewImg,
                type: "overview"
            },
            {
                title: "Total Open Projects",
                subTitle: "Progress report",
                img: progressImg,
                type: "progress"
            }
        ];
    }

    const columns = [
        { label: "Course Name", key: 'course_name' },
        { label: "Category", key: 'category' },
        // { label: "Action", key: "action", actions: ["enroll"] }
    ];

    const handleItemClick = (data) => { };

    const getCorseCompletionGraph = async () => {
        const graphData = await skillsAndTransformation.getCourseCompletionGraph()
        if (graphData && graphData.results) {
            setProgressOnCoursesList({...progressOnCoursesList , data: graphData.results.map(itm => {
                return itm.course_completion
            })});
            setDataKeyYAxis(graphData.results.map((itm, index) => {
                // return itm.course_name
                return "Course " + index
            }));
        }
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Grid container spacing={5}>
                        {reportCardArr && reportCardArr.length > 0 ? (
                            reportCardArr.map(item => (
                                <Grid item xs={4}>
                                    <Report 
                                        title={item.title}
                                        subTitle={item.subTitle}
                                        img={item.img}
                                        downloadReportLoading={downloadReportLoading}
                                        handleDownload={handleDownload}
                                        handleView={handleView}
                                        type={item.type}
                                    />
                                </Grid>
                            ))
                        ) : null}
                    </Grid>
                </div>
            </main>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Grid container spacing={2}>
                        {cardArr && cardArr.length > 0 ? (
                            cardArr.map(item => (
                                <>
                                    <Grid item xs={3}>
                                        <ReportCard title={item.title} value={item.value} />
                                    </Grid>
                                </>
                            ))
                        ) : null}
                    </Grid>
                </div>
            </main>
            <main>
                {userRole === USER_ROLES.USER ? (
                    <div className={commonClasses.contentWrapper}>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <div>
                                    <h2>Progress on Courses</h2>
                                </div>
                                <BarHorizontalChartWrapper 
                                    data={progressOnCoursesList}
                                    dataKeyYAxis={dataKeyYAxis} 
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                    <h2>Popular Courses</h2>
                                    <a href="#" onClick={evt => history.push('/skill-transformation/course-catalogue')} style={{
                                        paddingTop: '1rem', fontSize: 17, textDecoration: 'none'
                                    }}>View All</a>
                                </div>
                                <GenericTable
                                    columns={columns}
                                    loading={false}
                                    handleItemClick={handleItemClick}
                                    list={recommendedCourseslist}
                                    left={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <div className={commonClasses.contentWrapper}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <ChartContainer  styleOveride={{ background: variables.sidebarColor, borderRadius: 12, marginTop: 32, padding: 10 }}
                                    title={"Total Users By Course"}
                                >
                                    <LineChartWrapper data={lineChartData.data} dataKeyXAxis={lineChartData.xAxis}  />
                                    <p style={{margin: 0, textAlign: 'center'}}>Course Name</p>
                                </ChartContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <ChartContainer  styleOveride={{ background: variables.sidebarColor, borderRadius: 12, marginTop: 32, padding: 10 }}
                                    title={"Top Courses By User/Progress"}
                                >
                                    <PieChartWrapper data={bestCourses.map(itm => ({ name: itm.name, value: itm.count }))} />
                                </ChartContainer>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </main>
        </Layout >
    );
}

export default Reporting;