import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Button, Chip, Input } from '@material-ui/core';
import './filterModule.css';

const AutoCompleteFilter = ({ multiple, option, handleChange, label, value, onInputChange }) => {
  return (
    <Autocomplete
      id="combo-box-demo4"
      multiple={multiple}
      value={value}
      options={option}
      limitTags={1}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={option => option && option.name ? option.name : option}
      onChange={handleChange}
      style={{ width: "100%" }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          onChange={ev => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              onInputChange(ev.target.value);
            }
          }}
          className={"customInputBox"}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const CustomAutocomplete = ({ option, handleChange, label, value, onInputChange }) => {
  return (
    <Autocomplete
      id="combo-box-demo4"
      multiple
      value={value}
      options={option}
      limitTags={1}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={option => option && option.name ? option.name : option}
      onChange={handleChange}
      style={{ width: "100%" }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.name}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || null}
          variant="standard"
          onChange={ev => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              onInputChange(ev.target.value);
            }
          }}
          className={"customInputBox"}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

const CustamizedFilters = ({ filters, handleChange, allFilterState, type, handleSubmitFilter, pageName }) => {
  const handleFilter = () => {
    handleSubmitFilter(type)
  }

  return (
    <>
      {pageName && pageName === 'learning' && (
        <Grid container spacing={1} style={{ marginBottom: 26 }}>
          <Grid item xs={3}>
            <AutoCompleteFilter multiple={true} value={allFilterState ? allFilterState.customer : []} label="Select Customer" handleChange={(evt, newVal) => handleChange(newVal, "customer", type)} option={filters ? filters.customer : []} />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteFilter multiple={true} value={allFilterState ? allFilterState.certificate_status : []} label="Select Certificate" handleChange={(evt, newVal) => handleChange(newVal, "certificate_status", type)} option={filters ? filters.certificate_status : []} />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteFilter multiple={true} value={allFilterState ? allFilterState.project : []} label="Select Project" handleChange={(evt, newVal) => handleChange(newVal, "project", type)} option={filters ? filters.project : []} />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteFilter multiple={true} value={allFilterState ? allFilterState.course : []} label="Select Course" handleChange={(evt, newVal) => handleChange(newVal, "course", type)} option={filters ? filters.course : []} />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteFilter multiple={false} value={allFilterState?.email || ""} label="Search by email" handleChange={(evt, newVal) => handleChange(newVal, "email", type)} option={filters ? filters.email : []} />
            {/* <Input 
              disableUnderline
              value={allFilterState.email}
              type={'text'}
              onChange={evt => handleChange(evt.target.value, 'email', type)}
              placeholder='Search by email'
              style={{ minWidth: 110, width: '100%', borderRadius: 8 }}
              color={'secondary'}
              name="email"
            /> */}
          </Grid>
          <Grid item xs={1}>
            <Button style={{ 
              padding: '17px 11px',
              borderRadius: '5px',
              fontSize: '13px',
             }} onClick={handleFilter} variant="contained" color="primary">
              Filter Data
            </Button>
          </Grid>
        </Grid>
      )}
      {pageName && pageName === 'readline' && (
        <Grid container spacing={1} style={{ marginBottom: 26 }}>
          <Grid item xs={3}>
            <AutoCompleteFilter multiple={true} value={allFilterState ? allFilterState.customer : []} label="Select Customer" handleChange={(evt, newVal) => handleChange(newVal, "customer", type)} option={filters ? filters.customer : []} />
          </Grid>
          <Grid item xs={3}>
            <AutoCompleteFilter multiple={true} value={allFilterState ? allFilterState.project : []} label="Select Project" handleChange={(evt, newVal) => handleChange(newVal, "project", type)} option={filters ? filters.project : []} />
          </Grid>
          <Grid item xs={3}>
            <AutoCompleteFilter multiple={true} value={allFilterState ? allFilterState.users : []} label="Select Users" handleChange={(evt, newVal) => handleChange(newVal, "users", type)} option={filters ? filters.users : []} />
          </Grid>
          <Grid item xs={3}>
            <Button style={{ float: "right", minWidth: 120, paddingBottom: 16, paddingTop: 16, borderRadius: '5px' }} onClick={handleFilter} variant="contained" color="primary">
              Filter Data
            </Button>
          </Grid>
        </Grid>
      )}
      {pageName && pageName === 'userFilter' && (
        <Grid container spacing={1} style={{ marginBottom: 36 }}>
          <Grid item xs={4}>
            <CustomAutocomplete 
              value={allFilterState ? allFilterState.users : []} 
              label="Select Users"
              handleChange={(evt, newVal) => handleChange(newVal, "user", type)} 
              option={filters ? filters : []} 
            />
            {/* <AutoCompleteFilter value={allFilterState ? allFilterState : []} label="Select User" handleChange={(evt, newVal) => handleChange(newVal, "user", type)} 
            option={filters ? filters : []} /> */}
          </Grid>
          <Grid item xs={2}>
            <Button style={{ minWidth: 120, paddingBottom: 16, paddingTop: 16, borderRadius: '5px' }} onClick={handleFilter} variant="contained" color="primary">
              Filter Data
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CustamizedFilters;