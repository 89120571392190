import axios from 'axios';
import '../Sys/config.js';

var apiUrl = global.platformURI;

export class UserServices {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        let token = localStorage.getItem('ssoToken');
        config.headers.Authorization = `Token ${token}`;
        config.baseURL = apiUrl;
        return config;
      },
      error => {
        console.log('error.response.status', error);
        return error;
      }
    );
  }

  getAllUser(url) {
    return axios.get(url).then(res => res.data);
  }
  getAllProducts(url) {
    return axios.get(url).then(res => res.data);
  }
  addUser(payload, uuid) {
    if (uuid) {
      return axios.put(`main/userdetail/${uuid}`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }
    return axios
      .post('main/usercreate', payload)
      .then(res => res.data).catch(res => res.response.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  addMultipleUser(payload, uuid) {
    if (uuid) {
      return axios.put(`main/userdetail/${uuid}`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
    }
    return axios
      .post('main/usercreatemultiple', payload)
      .then(res => res.data).catch(res => res.response.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  getUserDetail(id) {
    return axios.get(`main/userdetail/${id}`).then(res => res.data);
  }

  // my profile get detail
  getDetailMyProfile() {
    let token = localStorage.getItem('ssoToken');
    const body = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`
      }
    };
    return axios.get(`main/myprofile`, body).then(res => res.data);
  }
  updateProfile(payload) {
    return axios
      .put(`main/editprofile`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  changeCompanyLogo(id) {
    return axios.get(`main/customerlogo/${id}`).then(res => res.data);
  }

  // Bulk upload
  getBulkTemplate(uuid) {
    let comp_ids = uuid || localStorage.getItem('comp_ids') || localStorage.getItem('uuid');
    return axios
      .get(`main/bulkuploadtemplate?customer_id=` + comp_ids)
      .then(res => res.data);
  }
  bulkUpload(payload, uuid) {
    let comp_ids = uuid || localStorage.getItem('comp_ids') || localStorage.getItem('uuid');
    return axios
      .post('main/bulkstorage?customer_id=' + comp_ids, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }

  customerAdminUser(id, type) {
    return axios
      .get(
        `main/customer_admin_user?customer_id=` + id + `&type=` + type
      )
      .then(res => res.data);
  }

  customerDropdown(id) {
    return axios
      .get(`main/customerdropdown?customer_id=${id}`)
      .then(res => res.data);
  }

  changePassword(payload) {
    return axios.post(`main/changepassword`, payload)
      .then(res => res.data)
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }

  getProjectAdminUsers(id) {
    return axios.get(`main/project_admin_users/${id}`).then(res => res.data);
  }
}
