import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import Layout from "../../../Components/layouts";
import aiImg from '../../../Assets/images/ai.png';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
    profileImg: {
        width: 50,
        height: 50,
        margin: "0 auto",
        overflow: "hidden",
        border: "1px solid",
        borderRadius: "50%",
        backgroundColor: "#262626"
    },
    column: {
        display: 'flex',
        alignItems: 'center'
    },
    table: {
        padding: 10,
        paddingLeft: 20
    },
    hour: {
        background: "#EC7A12",
        padding: '5px 35px',
        color: "white",
        borderRadius: 15,
    }
}));

const OrganisationPanel = () => {
    const classes = useStyles();

    const orgList = [
        {
            "lab": "Cloud Migration",
            "img": aiImg,
            "description": "Migration of mainframe to AWS",
            "hours": "25",
            "status": true
        },
        {
            "lab": "SDWAN",
            "img": aiImg,
            "description": "Simulation of head office network with Viptela",
            "hours": "25",
            "status": false
        },
        {
            "lab": "Cloud Migration",
            "img": aiImg,
            "description": "Migration of mainframe to AWS",
            "hours": "25",
            "status": true
        },
        {
            "lab": "SDWAN",
            "img": aiImg,
            "description": "Simulation of head office network with Viptela",
            "hours": "25",
            "status": false
        },
        {
            "lab": "Cloud Migration",
            "img": aiImg,
            "description": "Migration of mainframe to AWS",
            "hours": "25",
            "status": true
        },
        {
            "lab": "SDWAN",
            "img": aiImg,
            "description": "Simulation of head office network with Viptela",
            "hours": "25",
            "status": false
        },
        {
            "lab": "Cloud Migration",
            "img": aiImg,
            "description": "Migration of mainframe to AWS",
            "hours": "25",
            "status": true
        },
        {
            "lab": "SDWAN",
            "img": aiImg,
            "description": "Simulation of head office network with Viptela",
            "hours": "25",
            "status": false
        },
        {
            "lab": "Cloud Migration",
            "img": aiImg,
            "description": "Migration of mainframe to AWS",
            "hours": "25",
            "status": true
        },
        {
            "lab": "SDWAN",
            "img": aiImg,
            "description": "Simulation of head office network with Viptela",
            "hours": "25",
            "status": false
        },
        {
            "lab": "Cloud Migration",
            "img": aiImg,
            "description": "Migration of mainframe to AWS",
            "hours": "25",
            "status": true
        },
        {
            "lab": "SDWAN",
            "img": aiImg,
            "description": "Simulation of head office network with Viptela",
            "hours": "25",
            "status": false
        },
        {
            "lab": "Cloud Migration",
            "img": aiImg,
            "description": "Migration of mainframe to AWS",
            "hours": "25",
            "status": true
        },
        {
            "lab": "SDWAN",
            "img": aiImg,
            "description": "Simulation of head office network with Viptela",
            "hours": "25",
            "status": false
        }
    ]

    const columns = [
        {
            name: "lab",
            label: "Lab",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Grid className={classes.column}>
                            <Grid item style={{ marginRight: 15 }}>
                                <div className={classes.profileImg}>
                                    <img width={'50px'} alt="icon-img" src={aiImg} className={classes.largeImg} />
                                </div>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant={'subtitle1'}>
                                    {value}
                                </Typography>
                            </Grid>

                        </Grid>
                    )
                }
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Grid className={classes.column}>
                            <Grid item>
                                <Typography
                                    variant={'subtitle1'}>
                                    {value}
                                </Typography>
                            </Grid>

                        </Grid>
                    )
                }
            }
        },
        {
            name: "hours",
            label: "Hours",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Grid className={classes.column}>
                            <Grid item>
                                <Typography
                                    className={classes.hour}
                                    variant={'subtitle1'}>
                                    {value}
                                </Typography>
                            </Grid>

                        </Grid>
                    )
                }
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return value ? (
                        <Grid className={classes.column}>
                            <Grid item>
                                <Icon
                                    component={() => (
                                        <LockIcon
                                            style={{ marginTop: '2px', marginRight: '6px' }}
                                        />
                                    )}
                                    alt={'icon'}
                                />
                            </Grid>

                        </ Grid>
                    ) : (
                        <Grid className={classes.column}>
                            <Grid item>
                                <Icon
                                    component={() => (
                                        <LockOpenIcon
                                            style={{ marginTop: '2px', marginRight: '6px', color: "green" }}
                                        />
                                    )}
                                    alt={'icon'}
                                />
                            </Grid>

                        </ Grid>
                    )
                }
            }
        },
    ];

    const options = {
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive: 'standard',
        rowsPerPage: 10,
        download: false,
        print: false,
        viewColumns: false,
        selectableRowsHeader: false
    };

    return (
        <Layout>
            <main>
                <MUIDataTable
                    className={classes.table}
                    title={
                        "My Experiment"
                    }
                    data={orgList}
                    t
                    columns={columns}
                    options={options}
                    too
                />
            </main>
        </Layout>
    );
};

export default OrganisationPanel;
