import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from '../Context'

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {

    const userDetails = useAuthState();
    return (
        <Route
            path={path}
            render={props => {
                if (path === '/login' && Boolean(userDetails.token)) {
                    return <Redirect to={{ pathname: "/dashboard" }} />;
                }
                if (isPrivate && !Boolean(userDetails.token)) {
                    return <Redirect to={{ pathname: "/forgot-password" }} />;
                }
                return <Component {...props} />;
            }}
            {...rest}
        />
    )
}

export default AppRoutes;