import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, Grid } from '@material-ui/core';
import { useStyle as onboardingStyle } from '../..';
import { DataGrid } from '@material-ui/data-grid';
import SearchBoxInput from 'Components/SearchBoxInput';
import { USER_ROLES } from 'utils/constants';

export default function AssignUserModal({ open, loading, handleClose, list, handleSubmit, submitLoading, handleSearch, handleChange, queryData }) {

    const onBorningClasses = onboardingStyle();
    const [selectedUsers, setSelectedUsers] = useState([]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            hide: true 
        },
        {
            field: 'userName',
            headerName: 'Username',
            sortable: false,
            width: 250
        },
        {
            field: 'firstName',
            headerName: 'First name',
            sortable: false,
            width: 150
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            sortable: false,
            width: 150
        },
        {
            field: 'role',
            headerName: 'Role',
            sortable: false,
            width: 150
        },
    ];

    const rows = []; 
    list.map(itm => {
        if(itm.userName && itm.role === USER_ROLES.USER){
            rows.push({
                id: itm.uuid,
                firstName: itm.firstName,
                lastName: itm.lastName,
                role: itm.role,
                userName: itm.userName
            });
        }
    });

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
                style={{ zIndex: 9999 }}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Assign existing users to project</DialogTitle>
                <DialogContent>
                    <div style={{ height: 400, width: '100%' }}>
                        <Grid style={{marginBottom: 15}} >
                            <SearchBoxInput
                                handleSearch={handleSearch} 
                                handleChange={handleChange}
                                value={queryData?.search || ""}
                            />
                        </Grid>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={20}
                            loading={loading}
                            checkboxSelection
                            disableSelectionOnClick
                            onSelectionModelChange={(ids) => setSelectedUsers(ids)}
                            // hideFooter
                            // pagination={false}
                            // hideFooterPagination
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={onBorningClasses.buttons}>
                        <div className={onBorningClasses.wrapper}>
                            <Button
                                onClick={handleClose}
                                style={{ marginRight: 10 }}
                                variant="contained"
                                color="primary"
                                className={onBorningClasses.cancelBtn}>
                                Cancel
                            </Button>
                            <Button
                                type={"button"}
                                onClick={(evt) => handleSubmit(selectedUsers)}
                                variant="contained"
                                color="primary"
                                className={onBorningClasses.button}
                            >
                                {submitLoading ? (
                                    <CircularProgress
                                        size={18}
                                    />
                                ) : 'Submit'}
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
