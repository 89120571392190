import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import LineChartDuo from '../../../../Components/Charts/LineChartDuo';

const useStyles = makeStyles((theme) => ({
    rechartBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    title: {
        color: "#4D5766",
        wordSpacing: 2,
        fontSize: 18,
    }
}));

const ClosingTheGap = ({ title, description, data }) => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item>
                <h4 className={classes.title}>{title}</h4>
                <Grid container>
                    <Grid item xs={12}>
                        <p>{description}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <LineChartDuo
                            data={data}
                            firstKey='aspiration'
                            secondKey='sq'
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ClosingTheGap;