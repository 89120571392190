import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ProjectServices } from '../../../../Services/ProjectServices';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Button, CircularProgress } from '@material-ui/core';
import { notify } from '../../../../utils';
import { useStyle as onboardingStyle  } from '../../../CustomerOnBoarding';
import commonStyles from 'theme/commonStyles';

const projectServices = new ProjectServices();

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginBottom: 32,
    },
    mainStyle: {
        margin: '20px 0'
    },
    custmersStyle: {
        padding: '10px',
        backgroundColor: '#F1F1F1',
        width: '100%',
        height: '250px',
        overflowX: 'hidden',
    },
    list: {
        backgroundColor: '#F1F1F1',
        width: '100%',
        height: '250px',
        overflowX: 'hidden',
    },
    btnAllign: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: '20px'
    },
    btn: {
        marginRight: '20px',
        borderRadius: 5,
        padding: '17px 53px'
    },
    rightCount: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    header: {
        fontSize: '18px'
    }
}));

const AllocateLicence = ({
    activeStep,
    handleBack,
    handleNext,
    formBackupState,
    handleUpdateBackupFormData
}) => {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const onBorningClasses = onboardingStyle();
    
    const [projectId, setProjectId] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [customerAssUserSearch, setCustomerAssUserSearch] = useState(false)
    const [customerCourseUserSearch, setCustomerCourseUserSearch] = useState(false)
    const [customerAppUserSearch, setCustomerAppUserSearch] = useState(false)
    const [assessmentList, setAssessmentList] = useState([])
    const [coursesList, setCoursesList] = useState([])
    const [apprenticeshipList, setApprenticeshipList] = useState([])
    const [assessmentUserList, setAssessmentUserList] = useState([])
    const [coursesUserList, setCoursesUserList] = useState([])
    const [apprenticeshipUserList, setApprenticeshipUserList] = useState([])
    const [customerUsersList, setCustomerUsersList] = useState([])
    const [updatedCustomerUsersList, setUpdatedCustomerUsersList] = useState([])
    const [updatedAssessmentList, setUpdatedAssessmentList] = useState([])
    const [updatedCoursesList, setUpdatedCoursesList] = useState([])
    const [updatedApprenticeList, setUpdatedApprenticeList] = useState([])
    const [updatedCustomerAppList, setUpdatedCustomerAppList] = useState([])
    const [updatedCustomerCourseList, setUpdatedCustomerCourseList] = useState([])
    const [selectedCourse, setSelectedCourse] = useState({})
    const [selectedAssessment, setSelectedAssessment] = useState({})
    const [selectedapprenticeship, setSelectedApprenticeship] = useState({})
    const [assessmentSearch, setAssessmentSearch] = useState(false);
    const [coursesSearch, setCoursesSearch] = useState(false);
    const [apprenticeshipSearch, setApprenticeshipSearch] = useState(false);
    const [courseAllocated, setCourseAllocated] = useState({});
    const [assesmentAllocated, setAssesmentAllocated] = useState({});
    const [apprenticeshipAllocated, setApprenticeshipAllocated] = useState({});
    
    React.useEffect(() => {
        setProjectId(localStorage.getItem('project_id'));
        getUserListForAllocate();
    }, []);

    const getUserListForAllocate = async () => {
        let projectId = localStorage.getItem('project_id');
        const userRes = await projectServices.userByProject(projectId);
        const allocUserList = [];
        userRes?.data && userRes.data.forEach((usr) => {
            if(usr?.role === 'user'){
                allocUserList.push({
                    role: usr?.role || "-",
                    uuid: usr?.uuid || '',
                    customer_users: usr?.userName || '-',
                });
            }
        });
        setCustomerUsersList(allocUserList);
        return getLicensesList();
    };

    const getLicensesList = async () => {
        const params = { project_id: localStorage.getItem('project_id') };
        if (!params.project_id) {
            return null;
        }
        const projectDetails = await projectServices.getDetailLicenseCustomer(params.project_id);
        if (projectDetails.status && projectDetails.data) {
            const data = projectDetails.data
            const assessMtList = data && data.assessments && data.assessments.map(item => {
                return {
                    id: item.assessment_id,
                    name: item.assessment_name,
                    cl_uuid: item.cl_uuid,
                    count: item?.left_count - item.user_count,
                    user_count: item.user_count,
                    left_count: item?.left_count || 0,
                    uuid: item.uuid,
                    users: item.users
                }
            })
            const apprrList = data && data.apprenticeships && data.apprenticeships.map(item => {
                return {
                    id: item.apprenticeship_id,
                    name: item.apprenticeship_name,
                    cl_uuid: item.cl_uuid,
                    count: item?.left_count - item.user_count,
                    user_count: item.user_count,
                    left_count: item?.left_count || 0,
                    uuid: item.uuid,
                    users: item.users
                }
            })
            const coursList = data && data.courses && data.courses.map(item => {
                return {
                    id: item.course_id,
                    name: item.course_name,
                    cl_uuid: item.cl_uuid,
                    count: item?.left_count - item.user_count,
                    user_count: item.user_count,
                    left_count: item?.left_count || 0,
                    uuid: item.uuid,
                    users: item.users
                }
            });
            setAssessmentList(assessMtList);
            setApprenticeshipList(apprrList);
            setCoursesList(coursList);
            setAssessmentUserList(assessMtList);
            setApprenticeshipUserList(apprrList);
            setCoursesUserList(coursList);
        }
    };

    const handleChange = (value, type) => {
        if (type === 'assesment') {
            if (value && value.id) {
                const usersArray = [];
                let assesmentUser = assessmentUserList && assessmentUserList.filter(item => item.id === value.id);
                assesmentUser && assesmentUser.length > 0 && assesmentUser[0].users.length > 0 && assesmentUser[0].users.map(itm => {
                    usersArray.push(itm.uuid)
                });
                setSelectedAssessment(value);
                setAssesmentAllocated({...assesmentAllocated, [value.id]: (assesmentAllocated[value.id]?.length) ? assesmentAllocated[value.id] : usersArray});
                return handleChange(usersArray,'assesmentsarr');
            } else {
                setSelectedAssessment({});
            }
        }
        if (type === 'course') {
            if (value && value.id) {
                const usersArray = [];
                let courseUser = coursesUserList && coursesUserList.filter(item => item.id === value.id);
                courseUser && courseUser.length > 0 && courseUser[0].users.length > 0 && courseUser[0].users.map(itm => {
                    usersArray.push(itm.uuid)
                });
                setSelectedCourse(value);
                setCourseAllocated({...courseAllocated, [value.id]: (courseAllocated.hasOwnProperty(value.id)) ? courseAllocated[value.id] : usersArray});
            } else {
                setSelectedCourse({})
            }
        }
        if (type === 'Apprenticeships') {
            if (value && value.id) {
                const usersArray = []
                let apprenticeUser = apprenticeshipUserList && apprenticeshipUserList.filter(item => item.id === value.id)
                apprenticeUser && apprenticeUser.length > 0 && apprenticeUser[0].users.length > 0 && apprenticeUser[0].users.map(itm => {
                    usersArray.push(itm.uuid)
                });
                setSelectedApprenticeship(value);
                setApprenticeshipAllocated({...apprenticeshipAllocated, [value.id]: (apprenticeshipAllocated[value.id]?.length) ? apprenticeshipAllocated[value.id] : usersArray});
            } else {
                setSelectedApprenticeship({})
            }
        }
        if (type === 'coursearr') {
            value.persist();
            if(value.target.checked && selectedCourse){
                let checkLength = courseAllocated?.[selectedCourse.id]?.length+1;
                if(selectedCourse?.left_count < checkLength ){
                    notify("error", `You can't select more then ${selectedCourse?.left_count} user for ${selectedCourse?.name} course!`);
                    return null;
                }
            }
            setCourseAllocated((prevState) => {
                let newState = {...prevState};
                let selectedItem = [...newState[selectedCourse.id]];
                if (value.target.checked) {
                    selectedItem.push(value.target.value);
                } else {
                    let index = selectedItem.indexOf(value.target.value);
                    if (index !== -1) {
                        selectedItem.splice(index, 1);
                    }
                }
                newState = {...newState, [selectedCourse.id]: selectedItem };
                return newState;
            });
        }
        if (type === 'assesmentsarr') {
            value.persist();
            if(value.target.checked && selectedAssessment){
                let checkLength = assesmentAllocated?.[selectedAssessment.id]?.length+1;
                if(selectedAssessment?.left_count < checkLength ){
                    notify("error", `You can't select more then ${selectedAssessment?.left_count} user for ${selectedAssessment?.name}!`);
                    return null;
                }
            }
            setAssesmentAllocated((prevState) => {
                let newState = {...prevState};
                let selectedItem = [...newState[selectedAssessment.id]];
                if (value.target.checked) {
                    selectedItem.push(value.target.value);
                } else {
                    let index = selectedItem.indexOf(value.target.value);
                    if (index !== -1) {
                        selectedItem.splice(index, 1);
                    }
                }
                return {...assesmentAllocated, [selectedAssessment.id]: selectedItem };
            });
        }
        if (type === 'apprenticearr') {
            value.persist();
            if(value.target.checked && selectedapprenticeship){
                let checkLength = apprenticeshipAllocated?.[selectedapprenticeship.id]?.length+1;
                if(selectedapprenticeship?.left_count < checkLength ){
                    notify("error", `You can't select more then ${selectedapprenticeship?.left_count} user for ${selectedapprenticeship?.name}!`);
                    return null;
                }
            }
            setApprenticeshipAllocated((prevState) => {
                let newState = {...prevState};
                let selectedItem = [...newState[selectedapprenticeship.id]];
                if (value.target.checked) {
                    selectedItem.push(value.target.value);
                } else {
                    let index = selectedItem.indexOf(value.target.value);
                    if (index !== -1) {
                        selectedItem.splice(index, 1);
                    }
                }
                return {...apprenticeshipAllocated, [selectedapprenticeship.id]: selectedItem };
            });
        }
    }

    const handleSearchCoursesUsers = (event) => {
        let query = event.target.value
        if (query !== "") {
            setCustomerCourseUserSearch(true)
            const updatedList = customerUsersList && customerUsersList.length > 0 &&
                customerUsersList.filter(
                    (item, index) =>
                        item.customer_users && item.customer_users.toLowerCase().includes(query.toLowerCase())
                )
            setUpdatedCustomerCourseList(updatedList)
        } else {
            setCustomerCourseUserSearch(false)
        }
    }

    const handleSearchAppUsers = (event) => {
        let query = event.target.value
        if (query !== "") {
            setCustomerAppUserSearch(true)
            const updatedList = customerUsersList && customerUsersList.length > 0 &&
                customerUsersList.filter(
                    (item, index) =>
                        item.customer_users && item.customer_users.toLowerCase().includes(query.toLowerCase())
                )
            setUpdatedCustomerAppList(updatedList)
        } else {
            setCustomerAppUserSearch(false)
        }
    }

    const handleSearchAssessmentUsers = (event) => {
        let query = event.target.value
        if (query !== "") {
            setCustomerAssUserSearch(true)
            const updatedList = customerUsersList && customerUsersList.length > 0 &&
                customerUsersList.filter(
                    (item, index) =>
                        item.customer_users && item.customer_users.toLowerCase().includes(query.toLowerCase())
                )
            setUpdatedCustomerUsersList(updatedList)
        } else {
            setCustomerAssUserSearch(false)
        }
    }

    const handleSearchItemList = (event, list, setList, setSearch) => {
        let query = event.target.value
        if (query !== '') {
            setSearch(true)
            const updatedList = list && list.length > 0 &&
                list.filter(
                    (item, index) =>
                        item.name && item.name.toLowerCase().includes(query.toLowerCase())
                )
            setList(updatedList)
        } else {
            setSearch(false)
        }
    }

    const assessmentCustomerList = customerAssUserSearch ? updatedCustomerUsersList : customerUsersList
    const coursesCustomerList = customerCourseUserSearch ? updatedCustomerCourseList : customerUsersList
    const appCustomerList = customerAppUserSearch ? updatedCustomerAppList : customerUsersList
    
    const finalAssessmentList = assessmentSearch ? updatedAssessmentList : assessmentList
    const finalCoursesList = coursesSearch ? updatedCoursesList : coursesList
    const finalApprenticeshipList = apprenticeshipSearch ? updatedApprenticeList : apprenticeshipList
    
    const allocateUsers = async (data, id) => {
        return await projectServices.addAllocateUser(data, id)
    }

    const removeUsers = async (data, id) => {
        return await projectServices.removeAllocateUser(data, id)
    }

    const handleAllocate = async (evt) => {
        setSubmitLoading(true);
        handleUpdateBackupFormData({
            assessment: selectedAssessment,
            course: selectedCourse,
            apprenticeship: selectedapprenticeship,
        }, 'allocateLicenses');
        let courseError = '';
        let assesError = '';
        let apprError = '';

        if (selectedAssessment.id && assessmentUserList.length && assesmentAllocated) {
            let assesmentsIds = Object.keys(assesmentAllocated);
            let assesAllocData = [];
            let unallocateAssUsers = [];
            assesmentsIds.forEach((assId) => {
                let unallocateIds = [];
                let unallocateAss = [];
                const assesmentList = assessmentUserList && assessmentUserList.filter(item => item.id.includes(assId));
                let existingUsers = assesmentList.length && assesmentList[0].users.map(item => {
                    if(assesmentAllocated[assId] && !assesmentAllocated[assId].includes(item.uuid)){
                        unallocateIds.push(item.uuid);
                    }
                    return item.uuid;
                });
                let alloCourseId = assesmentAllocated[assId].length ? assesmentAllocated[assId].filter(id => !existingUsers.includes(id)) : [];
                const users = customerUsersList && customerUsersList.map(item => {
                    if(unallocateIds.includes(item.uuid)){
                        unallocateAss.push(item);
                    }
                    if (alloCourseId.includes(item.uuid)) return item
                });
                if (alloCourseId && alloCourseId.length) {
                    const allUsers = users && users.filter(item => item !== undefined);
                    if (allUsers && allUsers.length) {
                        assesAllocData.push({
                            assessment_id: assId,
                            user: allUsers
                        });
                    }
                }
                const allUnUsers = unallocateAss.length && unallocateAss.filter(item => item !== undefined);
                if (allUnUsers && allUnUsers.length) {
                    unallocateAssUsers.push({
                        assessment_id: assId,
                        user: allUnUsers
                    });
                }
            });

            if (assesAllocData && assesAllocData.length) {
                const assessments = {
                    assessments: assesAllocData
                }
                await allocateUsers(assessments, projectId).then((data) => {
                    if(data?.message && data?.message.includes('Cannot Assign the licence remaining counts')){
                        assesError = true;
                    }else{
                        notify('success', "Assessment Allocated");
                    }
                },
                error => {
                    notify("error", (error && error.message) ? error.message : 'Something Went Wrong');
                    setSubmitLoading(false);
                });
            }

            if (unallocateAssUsers?.length) {
                const assessments = {
                    assessments: [...unallocateAssUsers]
                }
                await removeUsers(assessments, projectId).then(
                    data => {
                        notify('success', "Assessment Unallocated");
                    },
                    error => {
                        setSubmitLoading(false);
                        notify('error', 'Something went wrong')
                        console.log('error.response.status', error);
                    }
                );
            }
        }

        if (courseAllocated && selectedCourse.id && coursesUserList.length) {
            let course_ids = Object.keys(courseAllocated);
            const xperSkillsLabs = await projectServices.getLabsByCourse({ course_ids });
            let courseAllocData = [];
            let labAllocData = [];
            let unallocateCourseUsers = [];
            let unallocateLabUsers = [];
            course_ids.forEach((crsId) => {
                let unallocateIds = [];
                let unallocateAss = [];
                const coursesList = coursesUserList && coursesUserList.filter(item => item.id.includes(crsId));
                let existingUsers = coursesList.length && coursesList[0].users.map(item => {
                    if(courseAllocated[crsId] && !courseAllocated[crsId].includes(item.uuid)){
                        unallocateIds.push(item.uuid);
                    }
                    return item.uuid;
                });
                let alloCourseId = (courseAllocated[crsId]?.length) ? courseAllocated[crsId].filter(id => !existingUsers.includes(id)) : [];
                const users = customerUsersList && customerUsersList.map(item => {
                    if(unallocateIds.includes(item.uuid)){
                        unallocateAss.push(item);
                    }
                    if (alloCourseId.includes(item.uuid)) return item
                });
                const allUnUsers = unallocateAss.length && unallocateAss.filter(item => item !== undefined);
                if (allUnUsers && allUnUsers.length) {
                    unallocateCourseUsers.push({
                        course_id: crsId,
                        user: allUnUsers
                    });
                    if(xperSkillsLabs?.data?.length){
                        let courseLabs = xperSkillsLabs?.data?.filter((lab) => lab.course_id === crsId);
                        if(courseLabs?.length){
                            courseLabs.forEach((itm) => {
                                unallocateLabUsers.push({
                                    lab_id: itm.uuid,
                                    user: allUnUsers
                                });
                            });
                        }
                    }
                }
                if (alloCourseId && alloCourseId.length) {
                    const allUsers = users && users.filter(item => item !== undefined);
                    if (allUsers?.length) {
                        courseAllocData.push({
                            course_id: crsId,
                            user: allUsers
                        });
                        if(xperSkillsLabs?.data?.length){
                            let courseLabs = xperSkillsLabs?.data?.filter((lab) => lab.course_id === crsId);
                            if(courseLabs?.length){
                                courseLabs.forEach((itm) => {
                                    labAllocData.push({
                                        lab_id: itm.uuid,
                                        user: allUsers
                                    });
                                });
                            }
                        }
                    }
                }
            });
            
            if(courseAllocData.length){
                const courses = {
                    courses: courseAllocData
                };
                await allocateUsers(courses, projectId).then(
                    data => {
                        if(data?.message && data?.message?.includes('Cannot Assign the licence remaining counts')){
                            console.log("data.message: ", data.message);
                            courseError = data.message;
                        }else{
                            notify('success', "Course Allocated");
                        }
                    },
                    error => {
                        notify('error', 'Something went wrong')
                        console.log('error.response.status', error);
                    }
                );
            }

            if(labAllocData?.length){
                const labs = {
                    labs: labAllocData
                };
                allocateUsers(labs, projectId).then(
                    data => {
                        notify('success', "Labs Allocated")
                    },
                    error => {
                        notify('error', 'Something went wrong')
                        console.log('error.response.status', error);
                    }
                );
            }

            if(unallocateCourseUsers.length){
                const courses = {
                    courses: [...unallocateCourseUsers]
                }
                await removeUsers(courses, projectId).then(
                    data => {
                        notify('success', "Course Unallocated")
                    },
                    error => {
                        notify('error', 'Something went wrong')
                        console.log('error.response.status', error);
                    }
                );
            }

            if(unallocateLabUsers.length){
                const labs = {
                    labs: [...unallocateLabUsers]
                }
                await removeUsers(labs, projectId).then(
                    data => {
                        notify('success', "Labs Unallocated")
                    },
                    error => {
                        notify('error', 'Something went wrong')
                        console.log('error.response.status', error);
                    }
                );
            }
        }

        if (selectedapprenticeship.id && apprenticeshipUserList.length && apprenticeshipAllocated) {
            let appr_ids = Object.keys(apprenticeshipAllocated);
            let apprAllocData = [];
            let unallocateApprUsers = [];
            appr_ids.forEach((apprId) => {
                let unallocateIds = [];
                let unallocateAppr = [];
                const apprenticeshipList = apprenticeshipUserList && apprenticeshipUserList.filter(item => item.id.includes(apprId));
                let existingUsers = apprenticeshipList.length && apprenticeshipList[0].users.map(item => {
                    if(apprenticeshipAllocated[apprId] && !apprenticeshipAllocated[apprId].includes(item.uuid)){
                        unallocateIds.push(item.uuid);
                    }
                    return item.uuid
                });
                let alloCourseId = (apprenticeshipAllocated[apprId]?.length) ? apprenticeshipAllocated[apprId].filter(id => !existingUsers.includes(id)) : [];
                const users = customerUsersList && customerUsersList.map(item => {
                    if(unallocateIds.includes(item.uuid)){
                        unallocateAppr.push(item);
                    }
                    if (alloCourseId.includes(item.uuid)) return item
                });
                if (alloCourseId && alloCourseId.length) {
                    const allUsers = users && users.filter(item => item !== undefined);
                    if (allUsers && allUsers.length) {
                        apprAllocData.push({
                            apprenticeship_id: apprId,
                            user: allUsers
                        });
                    }
                }
                const allUnUsers = unallocateAppr.length && unallocateAppr.filter(item => item !== undefined);
                if(allUnUsers && allUnUsers.length) {
                    unallocateApprUsers.push({
                        apprenticeship_id: apprId,
                        user: allUnUsers
                    });
                }
            });

            if (apprAllocData && apprAllocData.length) {
                const apprenticeships = {
                    apprenticeships: apprAllocData
                };
                await allocateUsers(apprenticeships, projectId).then((data) => {
                    if(data?.message && data?.message.includes('Cannot Assign the licence remaining counts')){
                        courseError = true;
                    }else{
                        notify('success', "Apprenticeship Allocated");
                    }
                },
                error => {
                    setSubmitLoading(false);
                    notify("error", (error && error.message) ? error.message : 'Something Went Wrong');
                });
            }
            if (unallocateApprUsers.length) {
                const apprenticeships = {
                    apprenticeships: [...unallocateApprUsers]
                }
                await removeUsers(apprenticeships, projectId).then(
                    data => {
                        setSubmitLoading(false);
                        notify('success', "Apprenticeship Unallocated");
                    },
                    error => {
                        setSubmitLoading(false);
                        notify('error', 'Something went wrong')
                        console.log('error.response.status', error);
                    }
                );
            }
        }
        
        setSubmitLoading(false);
        if(assesError){
            notify("error", assesError);
            return null;
        }
        if(courseError){
            notify("error", courseError);
            return null;
        }
        if(apprError){
            notify("error", apprError);
            return null;
        }
        handleNext(evt);
    }

    return (
        <>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Grid container spacing={6}>
                        <Grid item xs={6} style={{ paddingBottom: '10px' }}>
                            <div className={classes.rightCount}>
                                <Typography variant="h6" className={classes.header}>
                                    Assessments
                                </Typography>
                                <Typography variant="h6" className={classes.header}>
                                    {(typeof selectedAssessment?.count !== 'undefined') && selectedAssessment?.count + ' Left'}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Assessments (Search)"}
                                color={'secondary'}
                                onChange={evt => handleSearchItemList(evt, assessmentList, setUpdatedAssessmentList, setAssessmentSearch)}
                            />

                            <List className={classes.list}>
                                {finalAssessmentList && finalAssessmentList.length > 0 ? (
                                    <>
                                        {finalAssessmentList && finalAssessmentList.map(item => (
                                            <ListItem onClick={evt => handleChange(item, 'assesment')} key={item.id} value={item.id}
                                                style={selectedAssessment.id === item.id ? { background: '#E3E3E3' } : null}>
                                                <ListItemText style={{ cursor: 'pointer' }} value={item.id} primary={item.name} />
                                            </ListItem>
                                        ))}
                                    </>
                                ) : null}
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Allocated (Search User)"}
                                color={'secondary'}
                                disabled={!selectedAssessment.name ? true : false}
                                onChange={evt => handleSearchAssessmentUsers(evt)}
                            />
                            <div className={classes.custmersStyle}>
                                <FormGroup row>
                                    {assessmentCustomerList.length > 0 && assessmentCustomerList.map((itm) => (
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                style={{}}
                                                value={itm.uuid}
                                                checked={(assesmentAllocated && assesmentAllocated[selectedAssessment?.id]) ? assesmentAllocated[selectedAssessment.id].includes(itm.uuid) : false}
                                                disabled={!selectedAssessment.name ? true : false}
                                                control={<Checkbox style={{ border: '0px', background: '#F1F1F1' }} />}
                                                labelPlacement="end"
                                                onChange={(evt) => {
                                                    handleChange(evt, "assesmentsarr");
                                                }}
                                                label={itm.customer_users}
                                            />
                                        </Grid>

                                    ))}
                                </FormGroup>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </main>
            <main className={classes.mainStyle}>
                <div className={commonClasses.contentWrapper}>
                    <Grid container spacing={6}>
                        <Grid item xs={6} style={{ paddingBottom: '10px' }}>
                            <div className={classes.rightCount}>
                                <Typography variant="h6" className={classes.header}>
                                    Courses
                                </Typography>
                                <Typography variant="h6" className={classes.header}>
                                    {(typeof selectedCourse?.count !== 'undefined') && selectedCourse?.count + ' Left'}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Courses (Search)"}
                                color={'secondary'}
                                onChange={evt => handleSearchItemList(evt, coursesList, setUpdatedCoursesList, setCoursesSearch)}
                            />
                            <List className={classes.list}>
                                {finalCoursesList && finalCoursesList.length > 0 ? (
                                    <>
                                        {finalCoursesList && finalCoursesList.map(item => (
                                            <ListItem onClick={evt => handleChange(item, 'course')} key={item.id}
                                                style={selectedCourse.id === item.id ? { background: '#E3E3E3' } : null}>
                                                <ListItemText style={{ cursor: 'pointer' }} primary={item.name} />
                                            </ListItem>
                                        ))}
                                    </>
                                ) : null}
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Allocated (Search User)"}
                                color={'secondary'}
                                disabled={!selectedCourse.name ? true : false}
                                onChange={evt => handleSearchCoursesUsers(evt)}
                            />
                            <div className={classes.custmersStyle}>
                                <FormGroup row>
                                    {coursesCustomerList.length > 0 && coursesCustomerList.map((itm) => (
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                style={{}}
                                                value={itm.uuid}
                                                checked={(courseAllocated && courseAllocated[selectedCourse?.id]) ? courseAllocated[selectedCourse.id].includes(itm.uuid) : false}
                                                disabled={!selectedCourse.name ? true : false}
                                                control={<Checkbox style={{ border: '0px', background: '#F1F1F1' }} />}
                                                labelPlacement="end"
                                                onChange={(evt) => {
                                                    handleChange(evt, "coursearr");
                                                }}
                                                label={itm.customer_users}
                                            />
                                        </Grid>

                                    ))}
                                </FormGroup>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </main>
            <main className={classes.mainStyle}>
                <div className={commonClasses.contentWrapper}>
                    <Grid container spacing={6}>
                        <Grid item xs={6} style={{ paddingBottom: '10px' }}>
                            <div className={classes.rightCount}>
                                <Typography variant="h6" className={classes.header}>
                                    Apprenticeship
                                </Typography>
                                <Typography variant="h6" className={classes.header}>
                                    {(typeof selectedapprenticeship?.count !== 'undefined') && selectedapprenticeship?.count + ' Left'}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Apprenticeship (Search)"}
                                color={'secondary'}
                                onChange={evt => handleSearchItemList(evt, apprenticeshipList, setUpdatedApprenticeList, setApprenticeshipSearch)}
                            />
                            <List className={classes.list}>
                                {finalApprenticeshipList && finalApprenticeshipList.length > 0 ? (
                                    <>
                                        {finalApprenticeshipList && finalApprenticeshipList.map(item => (
                                            <ListItem onClick={evt => handleChange(item, 'Apprenticeships')} key={item.id}
                                                style={selectedapprenticeship.id === item.id ? { background: '#E3E3E3' } : null}>
                                                <ListItemText style={{ cursor: 'pointer' }} primary={item.name} />
                                            </ListItem>
                                        ))}
                                    </>
                                ) : null}
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Allocated (Search User)"}
                                color={'secondary'}
                                disabled={!selectedapprenticeship.name ? true : false}
                                onChange={evt => handleSearchAppUsers(evt)}
                            />
                            <div className={classes.custmersStyle}>
                                <FormGroup row>
                                    {appCustomerList.length > 0 && appCustomerList.map((itm) => (
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                style={{}}
                                                value={itm.uuid}
                                                disabled={!selectedapprenticeship.name ? true : false}
                                                checked={(apprenticeshipAllocated && apprenticeshipAllocated[selectedapprenticeship?.id]) ? apprenticeshipAllocated[selectedapprenticeship.id].includes(itm.uuid) : false}
                                                control={<Checkbox style={{ border: '0px', background: '#F1F1F1' }} />}
                                                labelPlacement="end"
                                                onChange={(evt) => {
                                                    handleChange(evt, "apprenticearr");
                                                }}
                                                label={itm.customer_users}
                                            />
                                        </Grid>

                                    ))}
                                </FormGroup>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={onBorningClasses.buttons}>
                            <div className={onBorningClasses.wrapper}>
                                {activeStep !== 0 && (
                                    <Button
                                        onClick={handleBack}
                                        variant="contained"
                                        color="primary"
                                        className={onBorningClasses.cancelBtn}>
                                        Back
                                    </Button>
                                )}
                                <Button
                                    type={"button"}
                                    onClick={handleAllocate}
                                    variant="contained"
                                    color="primary"
                                    disabled={submitLoading}
                                    className={onBorningClasses.button}
                                >
                                    {submitLoading ? (
                                        <CircularProgress
                                            size={18}
                                            className={classes.buttonProgress}
                                        />
                                    ) : 'Next'}
                                </Button>
                            </div>
                    </div>
                </div>
            </main>
            {/* <main className={classes.mainStyle}>
                <div className={commonClasses.contentWrapper}>
                    <Grid container spacing={6}>
                        <Grid item xs={6} style={{ paddingBottom: '10px' }}>
                            <div className={classes.rightCount}>
                                <Typography variant="h6" className={classes.header}>
                                    Labs
                                </Typography>
                                <Typography variant="h6" className={classes.header}>
                                    {selectedLabs?.count ? selectedLabs.count + ' Left' : ''}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Labs (Search)"}
                                color={'secondary'}
                                onChange={evt => handleSearchItemList(evt, labsList, setUpdatedLabList, setLabsSearch)}
                            />
                            <List className={classes.list}>
                                {finalLabsList && finalLabsList.length > 0 ? (
                                    <>
                                        {finalLabsList && finalLabsList.map(item => (
                                            <ListItem onClick={evt => handleChange(item, 'Labs')} key={item.id}
                                                style={selectedLabs.id === item.id ? { background: '#E3E3E3' } : null}>
                                                <ListItemText style={{ cursor: 'pointer' }} primary={item.name} />
                                            </ListItem>
                                        ))}
                                    </>
                                ) : null}
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <Input disableUnderline type={'text'}
                                style={{ width: '100%', border: '1px solid rgba(34,36,38,.15)', borderRadius: '5px' }}
                                className={classes.inputStyle}
                                placeholder={"Allocated (Search User)"}
                                color={'secondary'}
                                disabled={!selectedLabs.name ? true : false}
                                onChange={evt => handleSearchLabsUsers(evt)}
                            />
                            <div className={classes.custmersStyle}>
                                <FormGroup row>
                                    {labsCustomerList.length > 0 && labsCustomerList.map((itm) => (
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                style={{}}
                                                value={itm.uuid}
                                                disabled={!selectedLabs.name ? true : false}
                                                checked={labsCustomerUsers ? labsCustomerUsers.includes(itm.uuid) : false}
                                                control={<Checkbox style={{ border: '0px', background: '#F1F1F1' }} />}
                                                labelPlacement="end"
                                                onChange={(evt) => {
                                                    handleChange(evt, "labarr");
                                                }}
                                                label={itm.customer_users}
                                            />
                                        </Grid>

                                    ))}
                                </FormGroup>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </main> */}
        </>
    );
}

export default AllocateLicence;