
/*
  Color specifications
 */

export const colors = {
  common: {
    black: '#4D5766',
    white: '#fff',
    green: "#06AD17",
    red: "#F51919",
    orange: "#ff9800",
    gray: "#B1B1B1",
  },
  background: {
    default: '#f5f5f5'
  },
  primary: {
    light: '#0082CA',
    main: '#3D7DDD',
    dark: '#083D8B',
    lighter: '#ad9095'
  },
  secondary: {
    light: '#EC7A12',
    main: '#EC7A12',
    lighter: '#f4a5ad',
    lightest: 'rgba(233, 75, 91, 0.2)'
  },
  grey: {
    100: '#f0f0f0',
    200: '#ebebeb',
    300: '#ababab',
    400: '#969696',
    500: '#6c6c6c',
    600: '#484848'
  },
  error: {
    main: '#e53935'
  },
  warning: {
    main: '#ff9a01' // Change expected
  },
  success: {
    main: '#43a047'
  },
  info: {
    main: '#0183eb'
  },
  text: {
    primary: '#4D5766',
    secondary: '#808080'
  },
  chartColors: {
    graphGreen: '#6abc6d',
    graphCoolBlue: '#3b8ccf',
    graphDustyOrange: '#f5753b',
    graphTopaz: '#1aa5b5',
    graphCarolinaBlue: '#80afff',
    graphAquaBlue: '#0ec6dc',
    graphSeafoamBlue: '#7dcbc4',
    graphBlush: '#f19a99',
    graphLavenderPink: '#cf91da',
    graphMaize: '#ffd63f',
    graphBlue: '#1976d2',

    graphGrad400: '#9fa7dc',
    graphGrad500: '#c5c9ea',
    graphGrad300: '#7884cd',
    graphGrad200: '#5b69c3',
    graphGrad100: '#3e4eb8',
    graphGrad600: '#e8eaf6'
  }
};
