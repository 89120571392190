import React from 'react';
import ReactEcharts from 'echarts-for-react';

const PieChartWrapper = ({ data }) => {
  return (
    <div>
      <ReactEcharts
        option={{
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }}

      />
    </div>
  );
};

export default PieChartWrapper;