import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Input, Typography, Box, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { notify } from '../../../utils';
import { REQUIRED_ERROR, RepeatMode } from '../../../utils/constants';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';
import './event.css'
import { ProjectServices } from '../../../Services/ProjectServices';
import variables from '../../../Sys/variable.scss';
import OutlinedSelect from 'Components/OutlinedSelect';
import DateRangePickerModal from './DateRangePickerModal';
import moment from 'moment';

const projectServices = new ProjectServices();

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        width: '100%',
        borderColor: '#838383',
        borderRadius: '25px',
    },
}));

const typeList = ['Event', 'Meeting', 'Public Holiday']
const eventTypeList = ['Assessment', 'Course', 'Apprenticeship']
const courseActivityList = ['Self Pased Learning', 'Virtual Class Project', 'Work Readiness Examination']

const initFormState = { expiryDate: null, title: "", from: null, to: null, project: {}, type: "", eventType: "", url: "", repeat_mode: "", course: {}, courseActivity: "", note: '', typeMode: [] };

export default function EventModal({ open, handleClose, handleSaveEvents, btnLoading }) {
    
    const classes = useStyles();
    const [dateRPOpen, setDateRPOpen] = useState(false);
    const [dateRange, setDateRange] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [formState, setFormState] = useState({ ...initFormState });
    const [projectList, setProjectList] = useState([]);
    const [assessmentList, setAssessmentList] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [apprenticeshipList, setApprenticeshipList] = useState([]);

    useEffect(() => {
        getLicensesList();
    }, []);

    useEffect(() => {
        setFormState({...initFormState});
    }, [open]);

    const getLicensesList = async () => {
        const projectList = await projectServices.getAllLicenseListProject(`project/licenselist`);
        if (projectList && projectList.data) {
            const allProject = projectList && projectList.data.length > 0 ? projectList.data.map(item => {
                return {
                    name: item.project_name,
                    id: item.project_uuid
                }
            }) : [];
            setProjectList(allProject);
        }
    };

    const getAssessmentsList = async (id) => {
        const payload = {
            project_id: id
        }
        const licenseDetails = await projectServices.getLicenseinformation(payload)
        if (licenseDetails.status && licenseDetails.data) {
            const data = licenseDetails.data
            setAssessmentList(data.assessments)
            setApprenticeshipList(data.apprenticeships)
            setCoursesList(data.courses)
        }
    }

    const handleChange = (value, name) => {
        if (name === 'typeMode') {
            setFormState((prevState) => {
                const newState = { ...prevState };
                if (value.target.checked) {
                    newState.typeMode.push(value.target.value);
                } else {
                    let index = newState.typeMode.indexOf(value.target.value);
                    if (index !== -1) {
                        newState.typeMode.splice(index, 1);
                    }
                }
                return newState;
            });
        } else if (name === 'project') {
            if (value === null) {
                setFormState({ ...formState, [name]: {}, eventType: "" });
            } else {
                getAssessmentsList(value.id)
                setFormState({ ...formState, [name]: value });
            }
        } else if (name === "eventType") {
            setFormState({ ...formState, courseActivity: "", course: {}, [name]: value });
        } else {
            setFormState({ ...formState, [name]: value });
        }
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setFormState({ ...formState, [name]: value });
    }

    const handleValidate = () => {
        if (!formState.title || !formState.from || !formState.project.id || !formState.to || !formState.type || !formState.repeat_mode) {
            notify("error", REQUIRED_ERROR);
            return null;
        } else {
            if ((formState.type === 'Meeting' || formState.type === 'Public Holiday') && formState.url === "") {
                notify("error", REQUIRED_ERROR);
                return null;
            } else if (formState.courseActivity === "Virtual Class Project" && formState.url === "") {
                notify("error", REQUIRED_ERROR);
                return null;
            } else if (formState.type === 'Event' && (formState.courseActivity === null || formState.course === null || formState.eventType === "" || Object.keys(formState.course).length === 0)) {
                notify("error", REQUIRED_ERROR);
                return null;
            } else {
                handleSaveEvents({...formState, startDate, endDate}, dateRange);
            }
        }
    }

    let listTitle = formState.eventType === 'Assessment' ? 'Assessment' : formState.eventType === 'Apprenticeship' ? 'Apprenticeship' : 'Course'
    let dynamicList = formState.eventType === 'Assessment' ? assessmentList : formState.eventType === 'Apprenticeship' ? apprenticeshipList : coursesList

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="id">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography variant='h4'>Create Event</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Start Date *
                            </Typography>
                            <Input
                                disableUnderline
                                value={startDate}
                                type={'text'}
                                placeholder={"Start Date"}
                                color={'secondary'}
                                style={{minWidth: 220}}
                                className={classes.inputStyle}
                                onClick={(evt) => {
                                    if (!dateRPOpen) setDateRPOpen(true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                End Date *
                            </Typography>
                            <Input
                                disableUnderline
                                value={endDate}
                                type={'text'}
                                style={{minWidth: 220}}
                                placeholder={"End Date"}
                                color={'secondary'}
                                className={classes.inputStyle}
                                onClick={(evt) => {
                                    if (!dateRPOpen) setDateRPOpen(true)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                From *
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    variant="inline"
                                    placeholder={"HH:MM"}
                                    className={"timePickerStyle"}
                                    inputProps={{ style:{padding: 6} }}
                                    value={formState.from}
                                    onChange={date => setFormState({ ...formState, from: date })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                To *
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    disableUnderline
                                    variant="inline"
                                    value={formState.to}
                                    className={"timePickerStyle"}
                                    placeholder={"HH:MM"}
                                    inputProps={{ style:{padding: 6} }}
                                    onChange={date => setFormState({ ...formState, to: date })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Title *
                            </Typography>
                            <Input
                                disableUnderline
                                value={formState.title}
                                type={'text'}
                                placeholder={"Title"}
                                color={'secondary'}
                                className={classes.inputStyle}
                                name="title"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Repeat Mode *
                            </Typography>
                            <OutlinedSelect
                                options={RepeatMode}
                                name="repeat_mode"
                                val={formState.repeat_mode}
                                placeholder='Repeat Mode'
                                variant="standard"
                                handleChange={(evt) => handleInputChange(evt)}
                                styleOverrides={{ width: '100%', marginTop: 0 }}
                            />
                        </Grid>
                    </Grid>
                    {["Weekly", "Monthly"].includes(formState.repeat_mode) &&
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                    Event Expiry date *
                                </Typography>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        variant="inline"
                                        className={"timePickerStyle"}
                                        inputProps={{ style:{ padding: 6 } }}
                                        value={formState.expiryDate}
                                        style={{width: '100%'}}
                                        minDate={dateRange.endDate || new Date()}
                                        onChange={date => setFormState({ ...formState, expiryDate: date })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Project *
                            </Typography>
                            <Autocomplete
                                id="combo-box-demo4"
                                value={formState.project}
                                name="project"
                                options={projectList ? projectList : []}
                                getOptionLabel={option => option.name}
                                onChange={(event, newValue) => {
                                    handleChange(newValue, 'project');
                                }}
                                style={{ width: "100%" }}
                                renderInput={params => (
                                    <div ref={params.InputProps.ref}>
                                        <input
                                            placeholder='Select Project'
                                            style={{
                                                width: '100%',
                                                padding: '16px 24px',
                                                border: '1px solid #ebebeb',
                                                borderRadius: '25px',
                                            }}
                                            type="text"
                                            {...params.inputProps}
                                        />
                                    </div>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Type *
                            </Typography>
                            <OutlinedSelect
                                options={typeList}
                                name="type"
                                val={formState.type}
                                placeholder='Type'
                                variant="standard"
                                handleChange={(evt) => handleInputChange(evt)}
                                styleOverrides={{ width: '100%', marginTop: 0 }}
                            />
                        </Grid>
                    </Grid>
                    {formState.type && formState.type === 'Event' ? (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                        Event Type *
                                    </Typography>
                                    <OutlinedSelect
                                        options={eventTypeList}
                                        name="eventType"
                                        val={formState.eventType}
                                        placeholder='Event Type'
                                        variant="standard"
                                        handleChange={(evt) => handleInputChange(evt)}
                                        styleOverrides={{ width: '100%', marginTop: 0 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                        {listTitle} *
                                    </Typography>
                                    <Autocomplete
                                        id="combo-box-demo4"
                                        value={formState.course}
                                        name="course"
                                        disabled={!formState.project.name}
                                        options={dynamicList ? dynamicList : []}
                                        getOptionLabel={option => {
                                            if (formState.eventType === 'Assessment') {
                                                return option.assessment_id__name
                                            } else if (formState.eventType === 'Apprenticeship') {
                                                return option.apprenticeship_id__name
                                            } else {
                                                return option.course_id__name
                                            }
                                        }}
                                        onChange={(event, newValue) => {
                                            handleChange(newValue, 'course');
                                        }}
                                        style={{ width: "100%" }}
                                        renderInput={params => (
                                            <div ref={params.InputProps.ref}>
                                                <input
                                                    placeholder={`select ${listTitle}`}
                                                    style={{
                                                        width: '100%',
                                                        padding: '16px 24px',
                                                        border: '1px solid #ebebeb',
                                                        borderRadius: '25px',
                                                    }}
                                                    type="text"
                                                    {...params.inputProps}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                        {listTitle} Activity *
                                    </Typography>
                                    <Autocomplete
                                        id="combo-box-demo4"
                                        value={formState.courseActivity}
                                        name="courseActivity"
                                        options={courseActivityList ? courseActivityList : []}
                                        getOptionLabel={option => option}
                                        onChange={(event, newValue) => {
                                            handleChange(newValue, 'courseActivity');
                                        }}
                                        style={{ width: "100%" }}
                                        renderInput={params => (
                                            <div ref={params.InputProps.ref}>
                                                <input
                                                    placeholder={`Select ${listTitle} activity`}
                                                    style={{
                                                        width: '100%',
                                                        padding: '16px 24px',
                                                        border: '1px solid #ebebeb',
                                                        borderRadius: '25px',
                                                    }}
                                                    type="text"
                                                    {...params.inputProps}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    {(formState.type === 'Meeting' || formState.type === 'Public Holiday' || formState.courseActivity === 'Virtual Class Project') ? (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                    URL *
                                </Typography>
                                <Input
                                    disableUnderline
                                    value={formState.url}
                                    type={'text'}
                                    placeholder={"Meeting URL"}
                                    color={'secondary'}
                                    className={classes.inputStyle}
                                    name="url"
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                style={{ marginBottom: '5px', marginTop: '15px', color: variables.labelcolor }}>
                                Comments
                            </Typography>
                            <Input
                                disableUnderline
                                value={formState.note}
                                multiline
                                rows={3}
                                type={'text'}
                                placeholder={"Add note here..."}
                                color={'secondary'}
                                className={classes.inputStyle}
                                name="note"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleValidate} color="primary" variant="outlined">
                        {btnLoading ? <CircularProgress color='white' size={16} /> : "Add Event"}
                    </Button>
                </DialogActions>
            </Dialog>
            <DateRangePickerModal 
                open={dateRPOpen} 
                dateRange={dateRange}
                handleClose={() => {
                    setDateRPOpen(false);
                }}
                handleChangeDate={(newDate) => {
                    setStartDate(newDate.startDate ? moment(new Date(newDate.startDate)).format("DD-MM-YYYY") : '');
                    setEndDate(newDate.endDate ? moment(new Date(newDate.endDate)).format("DD-MM-YYYY") : '');
                    setDateRange(newDate);
                }}
            />
        </div >
    );
}